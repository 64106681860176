/* eslint-disable no-param-reassign */
import { isFunction, isArray, isNil, isPlainObject, isEmpty } from 'lodash-es';
import { isStrNum } from '../../utils';

export const DEFAULT_DISPLAY_KEY = 'label';
export const DEFAULT_UNIQUE_KEY = 'value';

export const execute = (value, action) => (isArray(value) ? value.map(action) : action(value));

export const getValue = (value, displayKey, uniqueKey) => {
  if (isNil(value) || isStrNum(value)) return value;
  if (isNil(value[uniqueKey]) && value._simpleOption) return value[displayKey];

  return uniqueKey ? value[uniqueKey] : value;
};

export const prepareOptions = (options, displayKey) =>
  isNil(options)
    ? options
    : isArray(options)
    ? options.map((el) => (isStrNum(el) ? { [displayKey]: el, _simpleOption: true } : el))
    : [];

export const prepareValue = (value, options, multiSelect, displayKey, uniqueKey) => {
  const setValue = (val) => {
    if (isStrNum(val)) {
      const match = options?.find((el) => el[uniqueKey] === val);
      const stringValue = { [displayKey]: val, _simpleOption: true };
      return match ?? stringValue;
    }
    return val;
  };

  if (multiSelect && !isArray(value)) {
    const mappedValue = setValue(value);
    return mappedValue ? [mappedValue] : [];
  }

  return execute(value, setValue) ?? (multiSelect ? [] : null);
};

export const outputValue = (value, displayKey, mappingFunction) => {
  const val = execute(value, (el) => getValue(el, displayKey));

  return isFunction(mappingFunction) ? mappingFunction(val) : val;
};

export const areEquals = (source, value, displayKey, uniqueKey) => {
  const compare = (val1, val2) => getValue(val1, displayKey, uniqueKey) === getValue(val2, displayKey, uniqueKey);

  if (isEmpty(source) && isEmpty(value)) return true;
  if (isPlainObject(source) && isPlainObject(value)) return compare(source, value);
  if (isArray(source) && isArray(value))
    return source.length === value.length && source.every((sEl) => value.find((vEl) => compare(sEl, vEl)));

  return false;
};

export const minimumWidth = {
  name: 'minimumWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
  },
};
