import queryString from 'query-string';
import { validUntil } from 'utils';
import { pick, isArray } from 'lodash-es';

export const getAllActivePackagesModel = (data) =>
  data?.catalogueItems && {
    catalogueItems: data.catalogueItems.filter((el) => el.active && el.visible),
    total: data.total,
  };

export const getAllSubscriptionsParams = (options) => {
  const {
    active,
    catItemActive,
    catItemVisible,
    name,
    vendor,
    sort = {
      sortField: 'createdOn',
      sortOrder: 'desc',
    },
  } = options;

  const filterField = [];
  const filterValue = [];

  const mapSort = (newSort) => ({
    sortField: newSort.sortField,
    sortOrder: newSort.sortOrder,
  });

  if (active) {
    filterField.push('active');
    filterValue.push(active);
  }

  if (catItemActive) {
    filterField.push('catItemActive');
    filterValue.push(catItemActive);
  }

  if (catItemVisible) {
    filterField.push('catItemVisible');
    filterValue.push(catItemVisible);
  }

  if (name) {
    filterField.push('name');
    filterValue.push(name);
  }

  if (vendor) {
    filterField.push('vendor');
    filterValue.push(vendor);
  }

  const queryParams = queryString.stringify(
    {
      ...(sort && mapSort(sort)),
      ...(filterField.length && filterValue.length && { filterField, filterValue }),
    },
    { arrayFormat: 'index' },
  );

  return queryParams;
};

export const getAllActiveSubscriptionsModel = (response) =>
  response?.catalogueItems && {
    ...response,
    catalogueItems: response.catalogueItems
      .filter((el) => el.active && el.visible)
      .map((el) => ({
        ...el,
        subscriptionPlans: el.subscriptionPlans
          .filter((plan) => plan.active)
          .map((plan) => ({ ...plan, catalogueItemId: el.id })),
      })),
  };

const mapAccountSubscriptionToGroupedItem = (subscription) => {
  if (!subscription) return {};

  return {
    ...pick(subscription, ['subscriptionCatalogueItemId', 'vendor', 'picThumbnailUrl', 'pictureUrl']),
    totalValidUntil: null,
    validFrom: null,
    totalDuration: null,
    activeSubscriptionPlans: [],
  };
};

const addTotalsToAccountSubscription = (subscription) => {
  const mappedSubscription = subscription;

  const totalPrice =
    subscription.activeSubscriptionPlans.length === 1
      ? subscription.activeSubscriptionPlans[0].price
      : subscription.activeSubscriptionPlans.map((p) => p.price).reduce((acc, curr) => acc + curr, 0);

  const totalDuration =
    subscription.activeSubscriptionPlans.length === 1
      ? subscription.activeSubscriptionPlans[0].duration
      : subscription.activeSubscriptionPlans.map((p) => p.duration).reduce((prev, curr) => prev + curr, 0);

  mappedSubscription.totalPrice = totalPrice;
  mappedSubscription.totalDuration = totalDuration;

  const lastPlan = subscription.activeSubscriptionPlans[subscription.activeSubscriptionPlans.length - 1];

  mappedSubscription.totalValidUntil = validUntil(lastPlan.validFrom, lastPlan.duration);
  mappedSubscription.validFrom = subscription.activeSubscriptionPlans[0].validFrom;

  return mappedSubscription;
};

export const getAccountGroupedSubscriptions = (accountSubscriptions) => {
  const groupedByCatItem = [];
  const processedVendorNames = [];
  const processedSubscriptionCatItemsIds = [];

  const addToProcessedSubscriptions = (subscription) => {
    processedVendorNames.push(subscription.vendor);
    processedSubscriptionCatItemsIds.push(subscription.subscriptionCatalogueItemId);
  };

  const getMappedSubscription = (subscription) => {
    const mappedSubscription = mapAccountSubscriptionToGroupedItem(subscription);

    mappedSubscription.totalPrice = subscription.price;
    mappedSubscription.activeSubscriptionPlans.push(subscription);

    return mappedSubscription;
  };

  accountSubscriptions.forEach((el) => {
    const isFirsVendor = processedVendorNames.indexOf(el.vendor) === -1;
    const isFirstCatItem = processedSubscriptionCatItemsIds.indexOf(el.subscriptionCatalogueItemId) === -1;

    const mappedSubscription = getMappedSubscription(el);

    if (isFirsVendor) {
      addToProcessedSubscriptions(el);
      groupedByCatItem.push(mappedSubscription);
    } else if (isFirstCatItem) {
      processedSubscriptionCatItemsIds.push(el.subscriptionCatalogueItemId);
      groupedByCatItem.push(mappedSubscription);
    } else {
      const processedSubscription = groupedByCatItem.find(
        (processed) => processed.subscriptionCatalogueItemId === el.subscriptionCatalogueItemId,
      );

      processedSubscription && processedSubscription.activeSubscriptionPlans.push(el);
    }
  });

  return groupedByCatItem.map(addTotalsToAccountSubscription);
};

export const getPaymentMethodsByIdsParams = (ids, itemType) =>
  queryString.stringify(
    {
      catalogueItemIds: isArray(ids) ? ids : [ids],
      itemType: itemType.toLowerCase(),
    },
    { arrayFormat: 'index' },
  );
