import { KYCType, KYCSubType, STATUS_TYPES } from 'enums';
import { ReactComponent as ApprovedIcn } from 'assets/images/icn-alert-success.svg';
import { ReactComponent as ReviewIcn } from 'assets/images/icn-alert-notice-2.svg';
import { ReactComponent as ErrorIcn } from 'assets/images/icn-alert-warning.svg';
import { ReactComponent as IdPassIcn } from 'assets/images/icn-id-wt-bg.svg';
import { ReactComponent as AddressProofIcn } from 'assets/images/icn-proof-addr-wt-bg.svg';
import { ReactComponent as SucceededIcon } from 'assets/images/icn-succeeded.svg';
import { ReactComponent as FailedIcon } from 'assets/images/icn-failed.svg';
import { ReactComponent as WaitingIcon } from 'assets/images/icn-waiting.svg';

const pendingNewStatus = {
  label: 'Not Verified',
  icon: <ErrorIcn />,
  className: false,
};

export const documentStatusMap = {
  pending: { ...pendingNewStatus },
  new: { ...pendingNewStatus },
  active: {
    label: 'Verified',
    icon: <ApprovedIcn />,
    className: true,
  },
};

export const kycDocumentsMap = {
  approved: {
    icon: <ApprovedIcn />,
    label: 'Approved',
  },
  pending: {
    icon: <ReviewIcn />,
    label: 'In Review',
  },
  rejected: {
    icon: <ErrorIcn />,
    label: 'Rejected',
  },
};

export const DOCUMENT_STEPS = {
  WARNING_MESSAGE: 'warningMessage',
  CHOSE_METHOD: 'method',
  PAYMENT: 'payment',
  UPLOAD_DOCS: 'upload',
};

export const uploadDocsMap = {
  [KYCType.idProof]: {
    title: 'Upload ID / Passport Document ',
    icon: <IdPassIcn />,
  },
  [KYCType.addressProof]: {
    title: 'Upload Proof of Address Document ',
    icon: <AddressProofIcn />,
  },
  [DOCUMENT_STEPS.CHOSE_METHOD]: {
    title: 'Please choose an option to continue your verification',
  },
  [DOCUMENT_STEPS.PAYMENT]: {
    title: 'Pay with Credit/Debit Card',
  },
};

export const kycIdOptions = [
  { label: 'ID Card', value: KYCSubType.idProof.IDCard },
  { label: 'Passport', value: KYCSubType.idProof.Passport },
  { label: 'Driving license', value: KYCSubType.idProof.DrivingLicense },
];

export const kycAddressOptions = [
  {
    label: 'Gas bill',
    value: KYCSubType.addressProof.GasBill,
  },
  {
    label: 'Water bill',
    value: KYCSubType.addressProof.WaterBill,
  },
  {
    label: 'Electricity bill',
    value: KYCSubType.addressProof.ElectricityBill,
  },
  {
    label: 'Document issued by a Bank',
    value: KYCSubType.addressProof.BankStatement,
  },
  {
    label: 'Document issued by the Municipality/Government',
    value: KYCSubType.addressProof.MunicipalityGovStatement,
  },
  {
    label: 'Document issued from а Residential Agency',
    value: KYCSubType.addressProof.ResidentialAgencyStatement,
  },
  {
    label: 'Both sides of non-expired National ID documents, driving license if the address is shown in the document',
    value: KYCSubType.addressProof.NationalIdDocument,
  },
];

export const statusIndicatorMap = {
  [STATUS_TYPES.SUCCESS]: {
    icon: <SucceededIcon />,
    massage: 'Success',
  },
  [STATUS_TYPES.FAIL]: {
    icon: <FailedIcon />,
    massage: 'Something went wrong.',
  },
  [STATUS_TYPES.WAIT]: {
    icon: <WaitingIcon />,
    massage: 'Waiting for confirmation.',
  },
};
