import {
  Button,
  // useUser
} from 'components';
import { UserProfile, UserInformation, ProfileMembersPlacement } from 'components/screens/ProfileOverview';
import { useCountries, useUserStore } from 'stores';
// import WelcomeModal from '../WelcomeModal';
import { buttonContainer, container } from './styles';

const ProfileOverview = () => {
  // const { isFirstLogin } = useUser();
  const countries = useCountries();
  const user = useUserStore();
  // console.log(user);

  const countryFlag = countries.all?.find((el) => el.name === user.country)?.flag;

  return (
    <>
      <div css={buttonContainer}>
        <Button small linkTo="/edit-profile">
          Edit Profile
        </Button>
      </div>
      <div css={container}>
        <UserProfile userData={user} countryFlag={countryFlag} />
        <div>
          <UserInformation userData={user} />
          <ProfileMembersPlacement userData={user} />
        </div>
      </div>

      {/* <WelcomeModal show={isFirstLogin} /> */}
    </>
  );
};

export default ProfileOverview;
