import { ReactComponent as DealShakerLogo } from 'assets/images/DealShakerLogo.svg';
import { ReactComponent as OneAcademyLogo } from 'assets/images/OneAcademyLogo.svg';
import { ReactComponent as OneForexLogo } from 'assets/images/OneForexLogo.svg';
import { ReactComponent as OneEcosystemLogo } from 'assets/images/OneEcosystemLogo.svg';

export const appOptions = [
  { icon: <OneEcosystemLogo />, url: 'https://portal.oneecosystem.eu', text: 'One Life' },
  { icon: <OneForexLogo />, url: 'https://forex.oneecosystem.eu', text: 'One Forex' },
  { icon: <OneAcademyLogo />, url: 'https://academy.oneecosystem.eu', text: 'One Academy' },
  { icon: <DealShakerLogo />, url: 'https://dealshaker.oneecosystem.eu', text: 'Deal Shaker' },
];
