import { useUserStore } from 'stores';
import { documentStatusMap } from './config';
import { statusVerified } from './styles';

const ProfileStatusLabel = () => {
  const user = useUserStore();
  const userStatus = user?.status?.toLowerCase();

  return (
    <div css={statusVerified(documentStatusMap[userStatus]?.className)}>
      {documentStatusMap[userStatus]?.icon}
      <span>{documentStatusMap[userStatus]?.label}</span>
    </div>
  );
};

export default ProfileStatusLabel;
