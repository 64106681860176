import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { mapValues, isPlainObject, omitBy, isNil } from 'lodash-es';
import moment from 'moment';
import { isJsonString, parseJson } from '../utils';

const dateTimeFormat = 'DD-MM-YYYY';

export const useUrlParams = () => {
  const history = useHistory();
  const {
    location: { pathname, search, state },
  } = history;

  const urlQueryParams = mapValues(
    qs.parse(search, {
      arrayFormat: 'index',
    }),
    (el) => {
      switch (true) {
        case isJsonString(el):
          return parseJson(el);
        case (/\d-\d-\d/g.test(el) || /\d\/\d\/\d/g.test(el)) && moment(el, dateTimeFormat).isValid():
          return moment(el, dateTimeFormat);
        default:
          return el;
      }
    },
  );

  const setUrlQueryParams = (params) => {
    const mappedParams = mapValues({ ...urlQueryParams, ...params }, (el) => {
      switch (true) {
        case isPlainObject(el):
          return JSON.stringify(el);
        case moment.isMoment(el):
          return el.format(dateTimeFormat);
        case moment.isDate(el):
          return moment(el).format(dateTimeFormat);
        default:
          return el;
      }
    });
    const filteredUrlParams = qs.stringify(omitBy(mappedParams, isNil), {
      arrayFormat: 'index',
    });
    history.replace({
      to: pathname,
      state,
      search: filteredUrlParams ? `?${filteredUrlParams}` : search,
    });
  };

  return {
    urlQueryParams,
    setUrlQueryParams,
  };
};
