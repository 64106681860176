export const passwordContainer = (theme) => ({
  padding: '32px 48px',

  '.horizontal-line': {
    marginBottom: 32,
    backgroundColor: theme.gray200,
  },

  '.paperContainer': {
    maxWidth: 500,

    '.password': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& > p': {
        fontSize: 16,
      },

      '.icon': {
        width: 100,
        height: 100,
        marginRight: 32,
        marginBottom: 24,
      },
    },

    '.passwordButton': {
      width: '100%',
    },
  },
});
