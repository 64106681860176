import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction, omit } from 'lodash-es';
import { Input, Button } from 'components/UI';
import { actionInputContainer } from './styles';

const ActionInput = ({ inputProps, buttonProps, onClick, className }) => {
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    setInputValue(inputProps?.value);
  }, [inputProps?.value]);

  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
    isFunction(inputProps?.handleChange) && inputProps.handleChange(value);
  };

  return (
    <div css={actionInputContainer} {...(className && { className })}>
      <Input
        className="actionInput"
        value={inputValue}
        handleChange={handleInputChange}
        {...omit(inputProps, ['value', 'handleChange'])}
      />

      <Button onClick={() => onClick(inputValue)} {...buttonProps}>
        {buttonProps.children}
      </Button>
    </div>
  );
};

ActionInput.propTypes = {
  inputProps: PropTypes.object,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ActionInput;
