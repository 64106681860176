// Docs can be found here https://animate.style/
import { keyframes } from '@emotion/react';

export const animationDuration = (duration = 1) => ({
  animationDuration: `${duration}s`,
  transitionDuration: `${duration}s`,
});

export const animationDelay = (delay = 0) => ({
  animationDelay: `${delay}s`,
});

export const animationIterationCount = (count = 1) => ({
  animationIterationCount: count,
});

const baseAnimationStyle = (name = 'none', params = {}) => ({
  animationName: name,
  animationFillMode: 'both',
  ...animationDelay(params.delay),
  ...animationIterationCount(params.iterationCount),
  ...animationDuration(params.duration),
});

const bounce = ({
  translateStart = '0, 0, 0',
  translate40 = '0, -30px, 0',
  translate70 = '0, -15px, 0',
  translate80 = '0, 0, 0',
  translateEnd = '0, -4px, 0',
  scaleStart = '1, 1.1',
  scale70 = '1, 1.05',
  scale80 = '1, 0.95',
  scaleEnd = '1, 1.02',
}) => keyframes`
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(${translateStart});
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(${translate40})  scale(${scaleStart});
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(${translate70}) scale(${scale70});
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(${translate80}) scale(${scale80});
  }
  90% {
    transform: translate3d(${translateEnd}) scale(${scaleEnd});
  }
`;

export const bounceAnimation = (params = {}) => ({
  ...baseAnimationStyle(bounce(params), params),
  transformOrigin: 'center bottom',
});

const flash = ({ opacityStart = 0, opacityEnd = 1 }) => keyframes`
  0%,
  50%,
  to {
    opacity: ${opacityEnd};
  }
  25%,
  75% {
    opacity: ${opacityStart};
  }
`;

export const flashAnimation = (params = {}) => baseAnimationStyle(flash(params), params);

const pulse = ({ scale = '1.05, 1.05, 1.05' }) => keyframes`
  0%,
  to {
    transform: scaleX(1);
  }
  50% {
    transform: scale3d(${scale});
  }
`;

export const pulseAnimation = (params = {}) => baseAnimationStyle(pulse(params), params);

const rubberBand = ({
  scale30 = '1.25, 0.75, 1',
  scale40 = '0.75, 1.25, 1',
  scale50 = '1.15, 0.85, 1',
  scale65 = '0.95, 1.05, 1',
  scale75 = '1.05, 0.95, 1',
}) => keyframes`
  0%,
  to {
    transform: scaleX(1);
  }
  30% {
    transform: scale3d(${scale30});
  }
  40% {
    transform: scale3d(${scale40});
  }
  50% {
    transform: scale3d(${scale50});
  }
  65% {
    transform: scale3d(${scale65});
  }
  75% {
    transform: scale3d(${scale75});
  }
`;

export const rubberBandAnimation = (params = {}) => baseAnimationStyle(rubberBand(params), params);

const shakeX = ({ translateStart = '-10px', translateEnd = '10px' }) => keyframes`
  0%,
  to {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(${translateStart});
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(${translateEnd});
  }
`;

export const shakeXAnimation = (params = {}) => baseAnimationStyle(shakeX(params), params);

const shakeY = ({ translateStart = '-10px', translateEnd = '10px' }) => keyframes`
  0%,
  to {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(${translateStart});
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateY(${translateEnd});
  }
`;

export const shakeYAnimation = (params = {}) => baseAnimationStyle(shakeY(params), params);

const handShake = keyframes`
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    transform: translateX(0);
  }
`;

export const handShakeAnimation = (params = {}) => baseAnimationStyle(handShake, params);

const swing = ({ rotate20 = '15deg', rotate40 = '-10deg', rotate60 = '5deg', rotate80 = '-5deg' }) => keyframes`
  20% {
    transform: rotate(${rotate20});
  }
  40% {
    transform: rotate(${rotate40});
  }
  60% {
    transform: rotate(${rotate60});
  }
  80% {
    transform: rotate(${rotate80});
  }
  to {
    transform: rotate(0deg);
  }
`;

export const swingAnimation = (params = {}) => baseAnimationStyle(swing(params), params);

const tada = ({
  scale20 = '0.9, 0.9, 0.9',
  rotate20 = '-3deg',
  scale30 = '1.1, 1.1, 1.1',
  rotate30 = '3deg',
  scale40 = '1.1, 1.1, 1.1',
  rotate40 = '-3deg',
}) => keyframes`
  0%,
  to {
    transform: scaleX(1);
  }
  10%,
  20% {
    transform: scale3d(${scale20}) rotate(${rotate20});
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(${scale30}) rotate(${rotate30});
  }
  40%,
  60%,
  80% {
    transform: scale3d(${scale40}) rotate(${rotate40});
  }
`;

export const tadaAnimation = (params = {}) => baseAnimationStyle(tada(params), params);

const wobble = ({
  translate15 = '-25%, 0, 0',
  rotate15 = '-5deg',
  translate30 = '20%, 0, 0',
  rotate30 = '3deg',
  translate45 = '-15%, 0, 0',
  rotate45 = '-3deg',
  translate60 = '10%, 0, 0',
  rotate60 = '2deg',
  translate75 = '-5%, 0, 0',
  rotate75 = '-1deg',
}) => keyframes`
  0%,
  to {
    transform: translateZ(0);
  }
  15% {
    transform: translate3d(${translate15}) rotate(${rotate15});
  }
  30% {
    transform: translate3d(${translate30}) rotate(${rotate30});
  }
  45% {
    transform: translate3d(${translate45}) rotate(${rotate45});
  }
  60% {
    transform: translate3d(${translate60}) rotate(${rotate60});
  }
  75% {
    transform: translate3d(${translate75}) rotate(${rotate75});
  }
`;

export const wobbleAnimation = (params = {}) => baseAnimationStyle(wobble(params), params);

const jello = keyframes`
  0%,
  11.1%,
  to {
    transform: translateZ(0);
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
`;

export const jelloAnimation = (params = {}) => ({
  ...baseAnimationStyle(jello, params),
  transformOrigin: 'center',
});

const heartBeat = ({ scale14 = 1.3, scale42 = 1.3 }) => keyframes`
  0%,
  28%,
  70% {
    transform: scale(1);
  }
  14% {
    transform: scale(${scale14});
  }
  42% {
    transform: scale(${scale42});
  }
`;

export const heartBeatAnimation = (params = {}) => ({
  ...baseAnimationStyle(heartBeat(params), { duration: 1.3, ...params }),
  animationTimingFunction: 'ease-in-out',
});

const backInSide = ({
  translateStart,
  translateEnd = '0, 0, 0',
  scaleStart = '0.7',
  scale80 = '0.7',
  scaleEnd = '1',
  opacityStart = '0.7',
  opacity80 = '0.7',
  opacityEnd = '1',
}) => keyframes`
  0% {
    transform: translate3d(${translateStart}) scale(${scaleStart});
    opacity: ${opacityStart};
  }
  80% {
    transform: translate3d(${translateEnd}) scale(${scale80});
    opacity: ${opacity80};
  }
  to {
    transform: scale(${scaleEnd});
    opacity: ${opacityEnd};
  }
`;

export const backInDownAnimation = (params = {}) =>
  baseAnimationStyle(backInSide({ translateStart: '0, -1200px, 0', ...params }), params);

export const backInLeftAnimation = (params = {}) =>
  baseAnimationStyle(backInSide({ translateStart: '-2000px, 0, 0', ...params }), params);

export const backInRightAnimation = (params = {}) =>
  baseAnimationStyle(backInSide({ translateStart: '2000px, 0, 0', ...params }), params);

export const backInUpAnimation = (params = {}) =>
  baseAnimationStyle(backInSide({ translateStart: '0, 1200px, 0', ...params }), params);

const backOutSide = ({
  translateStart = '0, 0, 0',
  translateEnd,
  scaleStart = '1',
  scale20 = '0.7',
  scaleEnd = '0.7',
  opacityStart = '1',
  opacity20 = '0.7',
  opacityEnd = '0.7',
}) => keyframes`
  0% {
    transform: scale(${scaleStart});
    opacity: ${opacityStart};
  }
  20% {
    transform: translate3d(${translateStart}) scale(${scale20});
    opacity: ${opacity20};
  }
  to {
    transform: translate3d(${translateEnd}) scale(${scaleEnd});
    opacity: ${opacityEnd};
  }
`;

export const backOutDownAnimation = (params = {}) =>
  baseAnimationStyle(backOutSide({ translateEnd: '0, 700px, 0', ...params }), params);

export const backOutLeftAnimation = (params = {}) =>
  baseAnimationStyle(backOutSide({ translateEnd: '-2000px, 0, 0', ...params }), params);

export const backOutRightAnimation = (params = {}) =>
  baseAnimationStyle(backOutSide({ translateEnd: '2000px, 0, 0', ...params }), params);

export const backOutUpAnimation = (params = {}) =>
  baseAnimationStyle(backOutSide({ translateEnd: '0, -700px, 0', ...params }), params);

const bounceIn = ({
  opacityStart = '0',
  opacity60 = '1',
  opacityEnd = '1',
  scaleStart = '0.3, 0.3, 0.3',
  scale20 = '1.1, 1.1, 1.1',
  scale40 = '0.9, 0.9, 0.9',
  scale60 = '1.03, 1.03, 1.03',
  scale80 = '0.97, 0.97, 0.97',
  scaleEnd = '1, 1, 1',
}) => keyframes`
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: ${opacityStart};
    transform: scale3d(${scaleStart});
  }
  20% {
    transform: scale3d(${scale20});
  }
  40% {
    transform: scale3d(${scale40});
  }
  60% {
    opacity: ${opacity60};
    transform: scale3d(${scale60});
  }
  80% {
    transform: scale3d(${scale80});
  }
  to {
    opacity: ${opacityEnd};
    transform: scale3d(${scaleEnd});
  }
`;

export const bounceInAnimation = (params = {}) => baseAnimationStyle(bounceIn(params), { duration: 0.75, ...params });

const bounceInSide = ({
  opacityStart = 0,
  opacityEnd = 1,
  translateStart,
  translate60,
  translate75,
  translate90,
  translateEnd = '0, 0, 0',
  scaleStart,
  scale60,
  scale75,
  scaleEnd,
}) => keyframes`
  0%,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: ${opacityStart};
    transform: translate3d(${translateStart}) scale(${scaleStart});
  }
  60% {
    opacity: ${opacityEnd};
    transform: translate3d(${translate60}) scale(${scale60});
  }
  75% {
    transform: translate3d(${translate75}) scale(${scale75});
  }
  90% {
    transform: translate3d(${translate90}) scale(${scaleEnd});
  }
  to {
    transform: translate3d(${translateEnd});
  }
`;

export const bounceInDownAnimation = (params = {}) =>
  baseAnimationStyle(
    bounceInSide({
      translateStart: '0, -3000px, 0',
      translate60: '0, 25px, 0',
      translate75: '0, -10px, 0',
      translate90: '0, 5px, 0',
      scaleStart: '1, 3',
      scale60: '1, 0.9',
      scale75: '1, 0.95',
      scaleEnd: '1, 0.985',
      ...params,
    }),
    params,
  );

export const bounceInLeftAnimation = (params = {}) =>
  baseAnimationStyle(
    bounceInSide({
      translateStart: '-3000px, 0, 0',
      translate60: '25px, 0, 0',
      translate75: '-10px, 0, 0',
      translate90: '5px, 0, 0',
      scaleStart: '3, 1',
      scale60: '1, 1',
      scale75: '0.98, 1',
      scaleEnd: '0.995, 1',
      ...params,
    }),
    params,
  );

export const bounceInRightAnimation = (params = {}) =>
  baseAnimationStyle(
    bounceInSide({
      translateStart: '3000px, 0, 0',
      translate60: '-25px, 0, 0',
      translate75: '10px, 0, 0',
      translate90: '-5px, 0, 0',
      scaleStart: '3, 1',
      scale60: '1, 1',
      scale75: '0.98, 1',
      scaleEnd: '0.995, 1',
      ...params,
    }),
    params,
  );

export const bounceInUpAnimation = (params = {}) =>
  baseAnimationStyle(
    bounceInSide({
      translateStart: '0, 3000px, 0',
      translate60: '0, -20px, 0',
      translate75: '0, 10px, 0',
      translate90: '0, -5px, 0',
      scaleStart: '1, 5',
      scale60: '1, 0.9',
      scale75: '1, 0.95',
      scaleEnd: '1, 0.985',
      ...params,
    }),
    params,
  );

const bounceOut = ({
  opacityStart = '1',
  opacityEnd = '0',
  scaleStart = '0.9, 0.9, 0.9',
  scale50 = '1.1, 1.1, 1.1',
  scaleEnd = '0.3, 0.3, 0.3',
}) => keyframes`
  20% {
    transform: scale3d(${scaleStart});
  }
  50%,
  55% {
    opacity: ${opacityStart};
    transform: scale3d(${scale50});
  }
  to {
    opacity: ${opacityEnd};
    transform: scale3d(${scaleEnd});
  }
`;

export const bounceOutAnimation = (params = {}) => baseAnimationStyle(bounceOut(params), { duration: 0.75, ...params });

const bounceOutSide = ({
  opacityStart = '1',
  opacityEnd = '0',
  translateStart,
  translate40,
  translateEnd,
  scaleStart,
  scale40,
  scaleEnd,
}) => keyframes`
  20% {
    transform: translate3d(${translateStart}) scale(${scaleStart});
  }
  40%,
  45% {
    opacity: ${opacityStart};
    transform: translate3d(${translate40}) scale(${scale40});
  }
  to {
    opacity: ${opacityEnd};
    transform: translate3d(${translateEnd}) scale(${scaleEnd});
  }
`;

export const bounceOutDownAnimation = (params = {}) =>
  baseAnimationStyle(
    bounceOutSide({
      translateStart: '0, 20px, 0',
      translate40: '0, -40px, 0',
      translateEnd: '0, 2000px, 0',
      scaleStart: '1, 0.985',
      scale40: '1, 0.9',
      scaleEnd: '1, 3',
      ...params,
    }),
    params,
  );

export const bounceOutLeftAnimation = (params = {}) =>
  baseAnimationStyle(
    bounceOutSide({
      translateStart: '-20px, 0, 0',
      translate40: '40px, 0, 0',
      translateEnd: '-2000px, 0, 0',
      scaleStart: '0.985, 1',
      scale40: '0.9, 1',
      scaleEnd: '2, 1',
      ...params,
    }),
    params,
  );

export const bounceOutRightAnimation = (params = {}) =>
  baseAnimationStyle(
    bounceOutSide({
      translateStart: '20px, 0, 0',
      translate40: '-40px, 0, 0',
      translateEnd: '2000px, 0, 0',
      scaleStart: '0.985, 1',
      scale40: '0.9, 1',
      scaleEnd: '2, 1',
      ...params,
    }),
    params,
  );

export const bounceOutUpAnimation = (params = {}) =>
  baseAnimationStyle(
    bounceOutSide({
      translateStart: '0, -20px, 0',
      translate40: '0, 40px, 0',
      translateEnd: '0, -2000px, 0',
      scaleStart: '1, 0.985',
      scale40: '1, 0.9',
      scaleEnd: '1, 3',
      ...params,
    }),
    params,
  );

const fadeIn = ({
  opacityStart = 0,
  opacityEnd = 1,
  translateStart = '0, 0, 0',
  translateEnd = '0, 0, 0',
}) => keyframes`
    0% {
      opacity: ${opacityStart};
      transform: translate3d(${translateStart});
    }
    to {
      opacity: ${opacityEnd};
      transform: translate3d(${translateEnd});
    }
  `;

export const fadeInAnimation = (params = {}) => baseAnimationStyle(fadeIn(params), params);

export const fadeInDownAnimation = (params = {}) =>
  baseAnimationStyle(fadeIn({ translateStart: '0, -700px, 0', ...params }), params);

export const fadeInLeftAnimation = (params = {}) =>
  baseAnimationStyle(fadeIn({ translateStart: '-700px, 0, 0', ...params }), params);

export const fadeInRightAnimation = (params = {}) =>
  baseAnimationStyle(fadeIn({ translateStart: '700px, 0, 0', ...params }), params);

export const fadeInUpAnimation = (params = {}) =>
  baseAnimationStyle(fadeIn({ translateStart: '0, 700px, 0', ...params }), params);

export const fadeInTopLeftAnimation = (params = {}) =>
  baseAnimationStyle(fadeIn({ translateStart: '-700px, -700px, 0', ...params }), params);

export const fadeInTopRightAnimation = (params = {}) =>
  baseAnimationStyle(fadeIn({ translateStart: '700px, -700px, 0', ...params }), params);

export const fadeInBottomLeftAnimation = (params = {}) =>
  baseAnimationStyle(fadeIn({ translateStart: '-700px, 700px, 0', ...params }), params);

export const fadeInBottomRightAnimation = (params = {}) =>
  baseAnimationStyle(fadeIn({ translateStart: '700px, 700px, 0', ...params }), params);

const fadeOut = ({
  opacityStart = 1,
  opacityEnd = 0,
  translateStart = '0, 0, 0',
  translateEnd = '0, 0, 0',
}) => keyframes`
  0% {
    opacity: ${opacityStart};
    transform: translate3d(${translateStart});
  }
  to {
    opacity: ${opacityEnd};
    transform: translate3d(${translateEnd});
  }
`;

export const fadeOutAnimation = (params = {}) => baseAnimationStyle(fadeOut(params), params);

export const fadeOutDownAnimation = (params = {}) =>
  baseAnimationStyle(fadeOut({ translateEnd: '0, -700px, 0', ...params }), params);

export const fadeOutLeftAnimation = (params = {}) =>
  baseAnimationStyle(fadeOut({ translateEnd: '-700px, 0, 0', ...params }), params);

export const fadeOutRightAnimation = (params = {}) =>
  baseAnimationStyle(fadeOut({ translateEnd: '700px, 0, 0', ...params }), params);

export const fadeOutUpAnimation = (params = {}) =>
  baseAnimationStyle(fadeOut({ translateEnd: '0, 700px, 0', ...params }), params);

export const fadeOutTopLeftAnimation = (params = {}) =>
  baseAnimationStyle(fadeOut({ translateEnd: '-700px, -700px, 0', ...params }), params);

export const fadeOutTopRightAnimation = (params = {}) =>
  baseAnimationStyle(fadeOut({ translateEnd: '700px, -700px, 0', ...params }), params);

export const fadeOutBottomLeftAnimation = (params = {}) =>
  baseAnimationStyle(fadeOut({ translateEnd: '700px, 700px, 0', ...params }), params);

export const fadeOutBottomRightAnimation = (params = {}) =>
  baseAnimationStyle(fadeOut({ translateEnd: '-700px, 700px, 0', ...params }), params);

const flip = ({ perspective = 500 }) => keyframes`
  0% {
    transform: perspective(${perspective}px) translateZ(0) rotateY(-1turn);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(${perspective}px) translateZ(150px) rotateY(-190deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(${perspective}px) translateZ(150px) rotateY(-170deg);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(${perspective}px) scale3d(0.95, 0.95, 0.95) translateZ(0)
      rotateY(0deg);
    animation-timing-function: ease-in;
  }
  to {
    transform: perspective(${perspective}px) translateZ(0) rotateY(0deg);
    animation-timing-function: ease-in;
  }
`;

export const flipAnimation = (params = {}) => ({
  ...baseAnimationStyle(flip(params), params),
  backfaceVisibility: 'visible !important',
});

const flipInX = ({ perspective = 400 }) => keyframes`
  0% {
    transform: perspective(${perspective}px) rotateX(90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(${perspective}px) rotateX(-20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(${perspective}px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(${perspective}px) rotateX(-5deg);
  }
  to {
    transform: perspective(${perspective}px);
  }
`;

export const flipInXAnimation = (params = {}) => ({
  ...baseAnimationStyle(flipInX(params), params),
  backfaceVisibility: 'visible !important',
});

const flipInY = ({ perspective = 400 }) => keyframes`
  0% {
    transform: perspective(${perspective}px) rotateY(90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(${perspective}px) rotateY(-20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(${perspective}px) rotateY(10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(${perspective}px) rotateY(-5deg);
  }
  to {
    transform: perspective(${perspective}px);
  }
`;

export const flipInYAnimation = (params = {}) => ({
  ...baseAnimationStyle(flipInY(params), params),
  backfaceVisibility: 'visible !important',
});

const flipOutX = ({ perspective = 400 }) => keyframes`
  0% {
    transform: perspective(${perspective}px);
  }
  30% {
    transform: perspective(${perspective}px) rotateX(-20deg);
    opacity: 1;
  }
  to {
    transform: perspective(${perspective}px) rotateX(90deg);
    opacity: 0;
  }
`;

export const flipOutXAnimation = (params = {}) => ({
  ...baseAnimationStyle(flipOutX(params), { duration: 0.75, ...params }),
  backfaceVisibility: 'visible !important',
});

const flipOutY = ({ perspective = 400 }) => keyframes`
  0% {
    transform: perspective(${perspective}px);
  }
  30% {
    transform: perspective(${perspective}px) rotateY(-15deg);
    opacity: 1;
  }
  to {
    transform: perspective(${perspective}px) rotateY(90deg);
    opacity: 0;
  }
`;

export const flipOutYAnimation = (params = {}) => ({
  ...baseAnimationStyle(flipOutY(params), { duration: 0.75, ...params }),
  backfaceVisibility: 'visible !important',
});

const lightSpeedInRight = ({ opacityStart = 0, opacityEnd = 1, translate = '100%, 0, 0' }) => keyframes`
  0% {
    transform: translate3d(${translate}) skewX(-30deg);
    opacity: ${opacityStart};
  }
  60% {
    transform: skewX(20deg);
    opacity: ${opacityEnd};
  }
  80% {
    transform: skewX(-5deg);
  }
  to {
    transform: translateZ(0);
  }
`;

export const lightSpeedInRightAnimation = (params = {}) => ({
  ...baseAnimationStyle(lightSpeedInRight(params), params),
  animationTimingFunction: 'ease-out',
});

const lightSpeedInLeft = ({ opacityStart = 0, opacityEnd = 1, translate = '-100%, 0, 0' }) => keyframes`
  0% {
    transform: translate3d(${translate}) skewX(30deg);
    opacity: ${opacityStart};
  }
  60% {
    transform: skewX(-20deg);
    opacity: ${opacityEnd};
  }
  80% {
    transform: skewX(5deg);
  }
  to {
    transform: translateZ(0);
  }
`;

export const lightSpeedInLeftAnimation = (params = {}) => ({
  ...baseAnimationStyle(lightSpeedInLeft(params), params),
  animationTimingFunction: 'ease-out',
});

const lightSpeedOutRight = ({ opacityStart = 1, opacityEnd = 0, translate = '100%, 0, 0' }) => keyframes`
  0% {
    opacity: ${opacityStart};
  }
  to {
    transform: translate3d(${translate}) skewX(30deg);
    opacity: ${opacityEnd};
  }
`;

export const lightSpeedOutRightAnimation = (params = {}) => ({
  ...baseAnimationStyle(lightSpeedOutRight(params), params),
  animationTimingFunction: 'ease-out',
});

const lightSpeedOutLeft = ({ opacityStart = 1, opacityEnd = 0, translate = '-100%, 0, 0' }) => keyframes`
  0% {
    opacity: ${opacityStart};
  }
  to {
    transform: translate3d(${translate}) skewX(-30deg);
    opacity: ${opacityEnd};
  }
`;

export const lightSpeedOutLeftAnimation = (params = {}) => ({
  ...baseAnimationStyle(lightSpeedOutLeft(params), params),
  animationTimingFunction: 'ease-out',
});

const rotateIn = ({ opacityStart = 0, opacityEnd = 1, rotate = 200 }) => keyframes`
  0% {
    transform: rotate(${rotate}deg);
    opacity: ${opacityStart};
  }
  to {
    transform: rotate(0);
    opacity: ${opacityEnd};
  }
`;

export const rotateInAnimation = (params = {}) => ({
  transformOrigin: 'center',
  ...baseAnimationStyle(rotateIn(params), params),
});

export const rotateInDownLeftAnimation = (params = {}) => ({
  transformOrigin: 'left bottom',
  ...baseAnimationStyle(rotateIn({ rotate: -45, ...params }), params),
});

export const rotateInDownRightAnimation = (params = {}) => ({
  transformOrigin: 'right bottom',
  ...baseAnimationStyle(rotateIn({ rotate: 45, ...params }), params),
});

export const rotateInUpLeftAnimation = (params = {}) => ({
  transformOrigin: 'left bottom',
  ...baseAnimationStyle(rotateIn({ rotate: 45, ...params }), params),
});

export const rotateInUpRightAnimation = (params = {}) => ({
  transformOrigin: 'right bottom',
  ...baseAnimationStyle(rotateIn({ rotate: -45, ...params }), params),
});

const rotateOut = ({ opacityStart = 1, opacityEnd = 0, rotate = 200 }) => keyframes`
  0% {
    opacity: ${opacityStart};
  }
  to {
    transform: rotate(${rotate}deg);
    opacity: ${opacityEnd};
  }
`;

export const rotateOutAnimation = (params = {}) => ({
  transformOrigin: 'center',
  ...baseAnimationStyle(rotateOut(params), params),
});

export const rotateOutDownLeftAnimation = (params = {}) => ({
  transformOrigin: 'left bottom',
  ...baseAnimationStyle(rotateOut({ rotate: 45, ...params }), params),
});

export const rotateOutDownRightAnimation = (params = {}) => ({
  transformOrigin: 'right bottom',
  ...baseAnimationStyle(rotateOut({ rotate: -45, ...params }), params),
});

export const rotateOutUpLeftAnimation = (params = {}) => ({
  transformOrigin: 'left bottom',
  ...baseAnimationStyle(rotateOut({ rotate: -45, ...params }), params),
});

export const rotateOutUpRightAnimation = (params = {}) => ({
  transformOrigin: 'right bottom',
  ...baseAnimationStyle(rotateOut({ rotate: 45, ...params }), params),
});

const hinge = ({
  opacityStart = 1,
  opacityEnd = 0,
  translate = '0, 200px, 0',
  rotateStart = 80,
  rotateEnd = 60,
}) => keyframes`
  0% {
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    transform: rotate(${rotateStart}deg);
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    transform: rotate(${rotateEnd}deg);
    animation-timing-function: ease-in-out;
    opacity: ${opacityStart};
  }
  to {
    transform: translate3d(${translate});
    opacity: ${opacityEnd};
  }
`;

export const hingeAnimation = (params = {}) => ({
  ...baseAnimationStyle(hinge(params), { duration: 2, ...params }),
  transformOrigin: 'top left',
});

const jackInTheBox = ({ opacityStart = 0, opacityEnd = 1, scaleStart = 0.1, scaleEnd = 1 }) => keyframes`
  0% {
    opacity: ${opacityStart};
    transform: scale(${scaleStart}) rotate(30deg);
    transform-origin: center bottom;
  }
  50% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(3deg);
  }
  to {
    opacity: ${opacityEnd};
    transform: scale(${scaleEnd});
  }
`;

export const jackInTheBoxAnimation = (params = {}) => baseAnimationStyle(jackInTheBox(params), params);

const roll = ({ opacityStart, opacityEnd, translateStart, translateEnd, rotate }) => keyframes`
  0% {
    opacity: ${opacityStart};
    transform: translate3d(${translateStart}) rotate(${rotate}deg);
  }
  to {
    opacity: ${opacityEnd};
    transform: translate3d(${translateEnd}) rotate(${rotate}deg);
  }
`;

export const rollInAnimation = (params = {}) =>
  baseAnimationStyle(
    roll({
      opacityStart: 0,
      opacityEnd: 1,
      translateStart: '-100%, 0, 0',
      rotate: -120,
      ...params,
    }),
    params,
  );

export const rollOutAnimation = (params = {}) =>
  baseAnimationStyle(
    roll({
      opacityStart: 1,
      opacityEnd: 0,
      translateEnd: '100%, 0, 0',
      rotate: 120,
      ...params,
    }),
    params,
  );

const zoomIn = ({ opacityStart = 0, opacityEnd = 1, scale = '0.3, 0.3, 0.3' }) => keyframes`
  0% {
    opacity: ${opacityStart};
    transform: scale3d(${scale});
  }
  50%,
  to {
    opacity: ${opacityEnd};
  }
`;

export const zoomInAnimation = (params = {}) => baseAnimationStyle(zoomIn(params), params);

const zoomOut = ({ opacityStart = 1, opacityEnd = 0, scale = '0.3, 0.3, 0.3' }) => keyframes`
  0% {
    opacity: ${opacityStart};
  }
  50%,
  to {
    opacity: ${opacityEnd};
    transform: scale3d(${scale});
  }
`;

export const zoomOutAnimation = (params = {}) => baseAnimationStyle(zoomOut(params), params);

const zoomInSide = ({
  opacityStart = 0,
  opacityEnd = 1,
  scaleStart = '0.1, 0.1, 0.1',
  scaleEnd = '0.475, 0.475, 0.475',
  translateStart,
  translateEnd,
}) => keyframes`
  0% {
    opacity: ${opacityStart};
    transform: scale3d(${scaleStart}) translate3d(${translateStart});
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: ${opacityEnd};
    transform: scale3d(${scaleEnd}) translate3d(${translateEnd});
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
`;

export const zoomInDownAnimation = (params = {}) => ({
  ...baseAnimationStyle(
    zoomInSide({
      translateStart: '0, -1000px, 0',
      translateEnd: '0, 60px, 0',
      ...params,
    }),
    params,
  ),
});

export const zoomInLeftAnimation = (params = {}) => ({
  ...baseAnimationStyle(
    zoomInSide({
      translateStart: '-1000px, 0, 0',
      translateEnd: '10px, 0, 0',
      ...params,
    }),
    params,
  ),
});

export const zoomInRightAnimation = (params = {}) => ({
  ...baseAnimationStyle(
    zoomInSide({
      translateStart: '1000px, 0, 0',
      translateEnd: '-10px, 0, 0',
      ...params,
    }),
    params,
  ),
});

export const zoomInUpAnimation = (params = {}) => ({
  ...baseAnimationStyle(
    zoomInSide({
      translateStart: '0, 1000px, 0',
      translateEnd: '0, -60px, 0',
      ...params,
    }),
    params,
  ),
});

const zoomOutSide = ({
  opacityStart = 1,
  opacityEnd = 0,
  scaleStart = '0.475, 0.475, 0.475',
  scaleEnd = '0.1, 0.1, 0.1',
  translateStart,
  translateEnd,
}) => keyframes`
  40% {
    opacity: ${opacityStart};
    transform: scale3d(${scaleStart}) translate3d(${translateStart});
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: ${opacityEnd};
    transform: scale3d(${scaleEnd}) translate3d(${translateEnd});
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
`;

export const zoomOutDownAnimation = (params = {}) => ({
  ...baseAnimationStyle(
    zoomOutSide({
      translateStart: '0, -60px, 0',
      translateEnd: '0, 2000px, 0',
      ...params,
    }),
    params,
  ),
});

export const zoomOutLeftAnimation = (params = {}) => ({
  ...baseAnimationStyle(
    zoomOutSide({
      translateStart: '42px, 0, 0',
      translateEnd: '-2000px, 0, 0',
      ...params,
    }),
    params,
  ),
});

export const zoomOutRightAnimation = (params = {}) => ({
  ...baseAnimationStyle(
    zoomOutSide({
      translateStart: '-42px, 0, 0',
      translateEnd: '2000px, 0, 0',
      ...params,
    }),
    params,
  ),
});

export const zoomOutUpAnimation = (params = {}) => ({
  ...baseAnimationStyle(
    zoomOutSide({
      translateStart: '0, 60px, 0',
      translateEnd: '0, -2000px, 0',
      ...params,
    }),
    params,
  ),
});

const slide = ({ translateStart = '0, 0, 0', translateEnd = '0, 0, 0' }) => keyframes`
  0% {
    transform: translate3d(${translateStart});
  }
  to {
    transform: translate3d(${translateEnd});
  }
`;

export const slideInDownAnimation = (params = {}) => ({
  ...baseAnimationStyle(slide({ translateStart: '0, -1000px, 0', ...params }), params),
});

export const slideInLeftAnimation = (params = {}) => ({
  ...baseAnimationStyle(slide({ translateStart: '-1000px, 0, 0', ...params }), params),
});

export const slideInRightAnimation = (params = {}) => ({
  ...baseAnimationStyle(slide({ translateStart: '1000px, 0, 0', ...params }), params),
});

export const slideInUpAnimation = (params = {}) => ({
  ...baseAnimationStyle(slide({ translateStart: '0, 1000px, 0', ...params }), params),
});

export const slideOutDownAnimation = (params = {}) => ({
  ...baseAnimationStyle(slide({ translateEnd: '0, 2000px, 0', ...params }), params),
});

export const slideOutLeftAnimation = (params = {}) => ({
  ...baseAnimationStyle(slide({ translateEnd: '-2000px, 0, 0', ...params }), params),
});

export const slideOutRightAnimation = (params = {}) => ({
  ...baseAnimationStyle(slide({ translateEnd: '2000px, 0, 0', ...params }), params),
});

export const slideOutUpAnimation = (params = {}) => ({
  ...baseAnimationStyle(slide({ translateEnd: '0, -2000px, 0', ...params }), params),
});
