import { useState, useEffect } from 'react';
import { isNil } from 'lodash-es';
import { Skeleton, Button, showInfo, showApiError, Row, Col } from 'components/UI';
import { FLOW_TYPE } from 'enums/kyc';
import { stringToBoolean, copyToClipboard } from 'utils';
import { getConfigParam } from 'services';
import { ReactComponent as NormalVipIcon } from 'assets/images/icn-automatic-verification.svg';
import { ReactComponent as NormalIcon } from 'assets/images/icn-normal-verification.svg';
import { verificationBox, recommended } from './styles';

const walletAddress = 'bc1qyap59m06vpj3p0y9ur936kg65sl6u34nq59gqamamtx5k2t2yvmsq28fjn';

const ProfileKycFlowSelect = ({ onClick }) => {
  const [kycSettings, setKycSettings] = useState(null);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [resXKycSettings, errXKycSettings] = await getConfigParam('XKycSettings', 'json');
    if (errXKycSettings) showApiError(errXKycSettings);

    const [resKYCFree, errKYCFree] = await getConfigParam('KYCFree', 'bool');
    if (errKYCFree) showApiError(errKYCFree);

    const [resKYCPaid, errKYCPaid] = await getConfigParam('KYCPaid', 'json');
    if (errKYCPaid) showApiError(errKYCPaid);

    setKycSettings((prev) => ({
      ...prev,
      XKycSettings: resXKycSettings || false,
      KYCFree: resKYCFree,
      KYCPaid: resKYCPaid,
    }));
  };

  if (isNil(kycSettings)) return <Skeleton style={{ marginBottom: 24, width: '100%', height: '160px' }} count={3} />;

  const handleCopy = (e) => {
    e.stopPropagation();
    showInfo({ title: 'Copy to clipboard' });
    copyToClipboard(walletAddress);
  };

  return (
    <>
      {stringToBoolean(kycSettings?.KYCPaid?.visibility) && (
        <div role="button" tabIndex="0" css={[verificationBox, recommended]} onClick={() => onClick(FLOW_TYPE.PAID)}>
          <Row>
            <Col xs={12} md={3}>
              <NormalVipIcon className="icon" />
            </Col>
            <Col xs={12} md={9}>
              <div className="titleContainer">
                <h4>VIP Priority Verification</h4>
                <span>24-72 hours</span>
              </div>
              <div className="description">
                <p>
                  Priority Verification is a paid service to speed up your verification process. Please use the
                  following BTC wallet to pay the service fee:
                  {walletAddress} <br />
                  After you make your payment you need to contact our Accounting department{' '}
                  <a href="mailto:invoice@onelifecorp.eu" onClick={(e) => e.stopPropagation()}>
                    invoice@onelifecorp.eu
                  </a>{' '}
                  with a Screenshot of your transaction in order to get processed.
                </p>
                <Button small clicked={handleCopy}>
                  Copy
                </Button>
              </div>
              <div className="payInfo">
                <h4>EUR {kycSettings?.KYCPaid?.price}</h4>
                <span>Payable With BTC</span>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {kycSettings?.KYCFree && (
        <div role="button" tabIndex="0" css={verificationBox} onClick={() => onClick(FLOW_TYPE.REGULAR)}>
          <Row>
            <Col xs={12} md={3}>
              <NormalIcon className="icon" />
            </Col>
            <Col xs={12} md={9}>
              <div className="titleContainer">
                <h4>Normal Verification</h4>
                <span>7+ Business days</span>
              </div>
              <p className="desc">
                The normal process takes longer to verify due to the amount of document we need to go though manually
                and verify everybody`s identity.
              </p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ProfileKycFlowSelect;
