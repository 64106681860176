import { useState } from 'react';
import PropTypes from 'prop-types';
import { ibanMask, bicSwiftMask, accountNumberMask, cardNumberMask } from 'utils/numberMask';
import { ReactComponent as EyeIcn } from 'assets/images/icn-eye.svg';
import { maskContainer } from './styles';

const MaskText = (props) => {
  const [visible, setVisible] = useState(false);
  const { value, type } = props;

  const maskType = () => {
    switch (type) {
      case 'iban':
        return ibanMask(value);

      case 'bicSwift':
        return bicSwiftMask(value);

      case 'accountNumber':
        return accountNumberMask(value);

      case 'cardNumber':
        return cardNumberMask(value);

      default:
        return value;
    }
  };

  return (
    <div css={maskContainer(visible)}>
      {value ? (
        <>
          <EyeIcn role="button" tabIndex="0" className="eye-icon" onClick={() => setVisible((prev) => !prev)} />
          {visible ? value : maskType()}
        </>
      ) : (
        'N/A'
      )}
    </div>
  );
};

MaskText.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default MaskText;
