export const backButtonContainer = (theme) => ({
  position: 'absolute',
  top: 0,
  right: 'calc(100% + 12px)',
  border: `1px solid ${theme.gray200}`,
  borderRadius: 4,
  padding: '0.5em',
  color: theme.gray500,
  transition: 'all .3s ease-in-out',

  '&:hover, &:active': {
    backgroundColor: theme.gray200,
    color: theme.white,
  },
});
