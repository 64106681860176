import { Row, Col } from 'components';
import { SendInvitationForm, InvitationCards } from 'components/screens/ProfileOverview';
import { useUserStore } from 'stores';
import { inviteContainer } from './styles';

const SendInvitation = () => {
  const user = useUserStore();
  const currentAccountId = user?.currentAccount?.id;

  return (
    <>
      <div css={inviteContainer}>
        <h3>Invite Friends</h3>
        <Row gap={16}>
          <Col md={12} xl={7}>
            <SendInvitationForm currentAccountId={currentAccountId} />
          </Col>
          <Col md={12} xl={5}>
            <InvitationCards currentAccountId={currentAccountId} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SendInvitation;
