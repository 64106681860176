import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { isBoolean } from 'lodash-es';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  inputValidation,
  useUser,
  CheckButton,
  // SearchBar,
  Paper,
} from 'components';
import { useUserStore } from 'stores';
import { ReactComponent as TickIcon } from 'assets/images/tickkIcon.svg';
import { actionButton, columnContainer, container, paperStyles } from './styles';

const CreateAccount = ({ history }) => {
  const { isAuthenticated, onRegistrationProgress } = useUser();
  const location = useLocation();
  const params = qs.parse(location.search);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const user = useUserStore();
  const currentAccount = user?.currentAccount;

  const handleCrateAccount = async () => {
    // console.log(data);

    // const [res, err] = await createAccount(data);
    // if (err) showError(err);
    onRegistrationProgress('/register-success');
    history.push('/register-success');
  };

  // if (!params?.sponsorAccountId && !isAuthenticated) history.push('/login');

  // const fetchNicknames = debounce(async ({ inputValue, callback, formikHandleChange }) => {
  //   if (isFunction(formikHandleChange)) searchRef.current.handleChange = formikHandleChange;

  //   const [data, err] = await getAllNicknames(inputValue);

  //   if (err) return showApiError(err);
  //   callback(data.items.map(mapNicknames));
  // }, 1000);

  // const MenuList = ({ children }) => <div style={{ maxHeight: '300px', overflow: 'auto' }}>{children}</div>;

  return (
    <div css={container}>
      <div className="container">
        <div className="tick-icon-container">
          <TickIcon className="tick-icon" />
          <h5>Profile created. Almost there!</h5>
        </div>
        <p className="label">Step 3 of 3</p>
        <h2 className="title">Last step: Create your account</h2>
        <Paper
          header={
            <div css={paperStyles}>
              {' '}
              <Form
                onSubmit={handleCrateAccount}
                values={{
                  sponsorAccountId: isAuthenticated
                    ? selectedSponsor === currentAccount?.id
                      ? currentAccount?.nickName
                      : selectedSponsor === currentAccount?.uplinkId
                      ? currentAccount?.sponsorFullName
                      : ''
                    : Number(params?.sponsorAccountId),
                }}
              >
                <Row sm={12} horizontalGap={16} verticalGap={8}>
                  {isAuthenticated && (
                    <div css={columnContainer}>
                      <h5 className="title">Sponsor details</h5>
                      <h5 className="subtitle">Sponsor of the new account</h5>
                    </div>
                  )}

                  {isAuthenticated && (
                    <Col>
                      <CheckButton
                        radio
                        value={selectedSponsor === currentAccount?.id}
                        onChange={() => setSelectedSponsor(currentAccount?.id)}
                      >
                        Current Account
                      </CheckButton>
                    </Col>
                  )}

                  {isAuthenticated && (
                    <Col>
                      <CheckButton
                        radio
                        value={selectedSponsor === currentAccount?.uplinkId}
                        onChange={() => setSelectedSponsor(currentAccount?.uplinkId)}
                      >
                        Sponsor of the current account
                      </CheckButton>
                    </Col>
                  )}

                  {isAuthenticated && (
                    <Col>
                      <CheckButton radio value={isBoolean(selectedSponsor)} onChange={() => setSelectedSponsor(true)}>
                        Account between current account and the sponsor of the current account
                      </CheckButton>
                    </Col>
                  )}

                  {isAuthenticated && (
                    <Col>
                      {/* <SearchBar label="Sponsor" placeholder="Search Sponsors" onChange={val => console.log(val)} /> */}
                      <Input
                        required
                        disabled={
                          selectedSponsor === currentAccount?.id || selectedSponsor === currentAccount?.uplinkId
                        }
                        formId="sponsorAccountId"
                        label="Sponsor"
                        placeholder="Search Sponsors"
                        // onChange={val => console.log(val)}
                        validate={inputValidation('required')}
                      />
                    </Col>
                  )}

                  <Col>
                    <Input
                      required
                      formId="preferredAccountNickname"
                      label="Account name"
                      placeholder="Name of your first account"
                      validate={inputValidation('lowercase', 'uppercase', 'required')}
                    />
                  </Col>

                  {!isAuthenticated && (
                    <Col>
                      <Input
                        required
                        disabled
                        formId="sponsorAccountId"
                        label="Your sponsor:"
                        validate={inputValidation('required')}
                      />
                    </Col>
                  )}

                  <Col md={12}>
                    {({ onSubmit }) => (
                      <Button css={actionButton} onClick={onSubmit}>
                        {isAuthenticated ? 'Finalize' : 'Next'}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CreateAccount;
