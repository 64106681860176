import { Row, Col, Skeleton } from 'components/UI';
import { subscriptionItem } from './styles';

const LoadingSubscriptionCard = ({ items }) =>
  Array(items ?? 10)
    .fill()
    .map((el, i) => (
      <Row align="center" justify="center" gap={32} key={`loading-state-skeleton-${i}`}>
        <Col xs={4}>
          <Skeleton width={100} height={100} circle />
        </Col>
        <Col xs={8}>
          <Skeleton height={65} />
        </Col>
        <Col xs={10} css={subscriptionItem}>
          <Skeleton className="subscriptionHeading" margin="0 0 8px 0" />
          <br />
          <Skeleton className="subscriptionName" margin="0 0 8px 0" />
          <Skeleton className="subscriptionDuration" margin="0 0 8px 0" />
          <Skeleton className="subscriptionName" margin="0 0 8px 0" />
          <Skeleton className="subscriptionDate" />
        </Col>
        <Col xs={10}>
          <Skeleton className="subscriptionActions" />
        </Col>
      </Row>
    ));
export default LoadingSubscriptionCard;
