import { useState, useEffect, useImperativeHandle } from 'react';
import { isFunction } from 'lodash-es';
import { useDeepEffect } from '../../hooks';
import { getBase64, checkSize, prepareValue } from './utils';
import inputValidation from '../inputValidation';
import { showError } from '../../Notifications';

export const useFileInput = ({
  value: initValue = '',
  onChange,
  onError,
  onBlur,
  validate,
  multiple = false,
  disabled = false,
  required = false,
  isTouched: isTouchedInit = false,
  max,
  numberOfFiles,
  componentRef,
}) => {
  const inputValue = prepareValue(initValue);
  const [value, setValue] = useState(inputValue);
  const [dragId, setDragId] = useState();
  const [error, setError] = useState();
  const [isTouched, setIsTouched] = useState(isTouchedInit ?? false);

  useDeepEffect(() => {
    setValue(inputValue);
  }, [initValue]);

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useEffect(() => {
    isTouched && checkForError(value);
  }, [isTouched]);

  useImperativeHandle(componentRef, () => ({
    value,
    changeValue: (newValue) => handleChange({ target: { value: newValue } }),
    checkForError,
    changeError: setError,
    changeIsTouched: setIsTouched,
  }));

  const handleChange = async (event) => {
    const { files } = event.target;
    // console.log(files);
    const filesErr = checkSizeAndNumber(files, max);
    if (filesErr) return submitError(filesErr);
    const [base64Files, base64Error] = await getBase64(files);

    if (base64Error) return showError(base64Error);

    const newValue = !multiple ? base64Files[0] : base64Files;

    setValue((prev) => {
      const items = multiple && prev ? [...prev, ...newValue] : newValue;

      setTimeout(() => {
        checkForError(items);
        isFunction(onChange) && onChange(items);
      });

      return items;
    });
  };

  const handleRemoveItem = (file) => {
    multiple
      ? setValue((prev) => {
          const items = prev.filter((el) => el.__id !== file.__id);

          setTimeout(() => {
            checkForError(items);
            isFunction(onChange) && onChange(items);
          });

          return items;
        })
      : setValue(null);
  };

  const handleBlur = () => {
    window.removeEventListener('focus', handleBlur);
    !isTouched && setIsTouched(true);
    checkForError(value);
    isFunction(onBlur) && onBlur();
  };

  const checkSizeAndNumber = (files) => {
    let err = null;
    if (numberOfFiles && files?.length > numberOfFiles) err = { msg: `Maximum number of files is: ${numberOfFiles}` };
    if (checkSize(files, max)) err = { msg: `Maximum file size is: ${max}MB` };
    return err;
  };

  const checkForError = (newValue) => {
    let newError = null;
    if (isFunction(validate)) newError = validate(newValue);
    else if (required)
      newError = inputValidation('required')(multiple ? (newValue.length ? newValue.length : '') : newValue?.data);
    submitError(newError);
  };

  const submitError = (newError) => {
    setError(newError);
    isFunction(onError) && onError(newError);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (e) => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    setValue((prev) => {
      const dragImageIndex = prev.findIndex((image) => image.__id === dragId);
      const dropImageIndex = prev.findIndex((image) => image.__id === e.currentTarget.id);
      const reorderedItems = moveItem(dragImageIndex, dropImageIndex);

      setTimeout(() => {
        isFunction(onChange) && onChange(reorderedItems);
      });

      return reorderedItems;
    });
  };

  const moveItem = (fromIndex, toIndex) => {
    const items = [...value];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    return items;
  };

  return {
    // Input props
    value,
    onChange: handleChange,
    onBlur: handleBlur,
    onDragOver: handleDragOver,
    onDragStart: handleDragStart,
    onDrop: handleDrop,
    onRemoveItem: handleRemoveItem,
    disabled,
    multiple,
    // Form related props
    error,
    isTouched,
  };
};
