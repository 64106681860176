import { useEffect, useState } from 'react';
import BodyOverflow from '../BodyOverflow';
import Notification from './Notification';
import { notificationsHandler } from './Store';
import { allNotificationsContainer } from './styles';

const ManageNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    notificationsHandler({
      addNotification: add,
      removeNotification: remove,
    });
  }, []);

  const add = (notification) => {
    setNotifications((prev) => (notification.insertOnTop ? [notification, ...prev] : [...prev, notification]));

    return notification;
  };

  const remove = (id) => setNotifications((prev) => prev.filter(({ id: nId }) => nId !== id));

  return (
    <BodyOverflow zIndex={2000}>
      <div css={allNotificationsContainer}>
        {notifications.map((notification) => (
          <Notification key={notification.id} {...notification} toggleRemoval={remove} />
        ))}
      </div>
    </BodyOverflow>
  );
};

export default ManageNotifications;
