import PropTypes from 'prop-types';
import { Button, Image } from 'components/UI';
import { pluralizeMonths } from 'utils';
import { vendorsInformation } from './config';
import { subscriptionItemContainer } from './styles';

const shopSubscriptionsUrl = 'https://portal.oneecosystem.eu/shop/all-subscriptions';

const SubscriptionItem = (props) => {
  const { item = {}, discountLabel, children, planIndex, className } = props;

  const getDurationLabel = () => {
    if (item.duration === 1 || item.duration / 12 === 1) return pluralizeMonths(item.duration);

    return `${item.duration % 12 === 0 ? item.duration / 12 : item.duration} ${pluralizeMonths(item.duration)}`;
  };

  const planHasThumb =
    vendorsInformation[item.vendor] &&
    vendorsInformation[item.vendor].plansThumbs &&
    vendorsInformation[item.vendor].plansThumbs?.length > 0;

  return (
    <div css={subscriptionItemContainer} {...(className && { className })}>
      {!planHasThumb && (
        <div>
          {discountLabel && discountLabel.length > 0 && (
            <div className="subscriptionDiscount">
              <span>{discountLabel}</span>
            </div>
          )}
          <div className="subscriptionDataName">{item.name}</div>
        </div>
      )}
      <div>
        {planHasThumb && (
          <Image
            src={vendorsInformation[item.vendor].plansThumbs[planIndex]}
            className="image"
            alt="img-subscription-card"
          />
        )}
        <h4 className="price">€{item.price}</h4>
        <p className="duration">/{getDurationLabel(item.duration)}</p>

        <Button small linkTo={shopSubscriptionsUrl} className="button">
          Subscribe
        </Button>

        {children}
      </div>
    </div>
  );
};

SubscriptionItem.propTypes = {
  item: PropTypes.any,
  discountLabel: PropTypes.string,
  children: PropTypes.any,
  planIndex: PropTypes.number,
  className: PropTypes.string,
};

export default SubscriptionItem;
