import { vendorNames } from 'enums';
import myLifeLogo from 'assets/images/one-life-logo.png';
import oneForexLogo from 'assets/images/one-forex-logo.png';
import oneVoyageLogo from 'assets/images/one-voyage-logo.png';
import basicLogo from 'assets/images/OE_VOYAGE_BASIC.png';
import unlimitedLogo from 'assets/images/OE_VOYAGE_GOLDEN-UNLIMITED.jpg';
import premiumLogo from 'assets/images/OE_VOYAGE_PREMIUM.jpg';

export const vendorsInformation = {
  [vendorNames.OneLife]: {
    name: vendorNames.OneLife,
    color: '#AB8735',
    title: 'Get started with our app!',
    description: 'My Life app subscription plans',
    logo: myLifeLogo,
  },
  [vendorNames.OneForex]: {
    name: vendorNames.OneForex,
    color: '#1A1E21',
    title: 'One Forex subscription plans',
    description: 'One Forex app subscription plans',
    logo: oneForexLogo,
  },
  [vendorNames.OneVoyage]: {
    name: vendorNames.OneVoyage,
    color: '#60D9AA',
    title: 'One Voyage subscription plans',
    description: 'One Voyage app subscription plans',
    logo: oneVoyageLogo,
    plansThumbs: [basicLogo, premiumLogo, unlimitedLogo],
  },
};
