import { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/UI';
import {
  StatusIndicator,
  KycModalAddressForm,
  ProfileKycFlowSelect,
  ProfileUploadDocsWarning,
  KycModalIdForm,
} from 'components/screens/KycVerification';
import { KYCType, profileStatus, FLOW_TYPE, STATUS_TYPES } from 'enums';
import { ReactComponent as CloseIcn } from 'assets/images/icn-close.svg';
import { DOCUMENT_STEPS, uploadDocsMap } from './config';
import { uploadModalBody, modalContainer } from './styles';

const ProfileUploadDocsModal = forwardRef((props, modalRef) => {
  const { documentType, onUploadDocs, nonPendingDocumentTypes } = props;
  const userStatus = (state) => state?.user?.status;
  const firstStep = userStatus === profileStatus.Active ? DOCUMENT_STEPS.WARNING_MESSAGE : DOCUMENT_STEPS.CHOSE_METHOD;
  const [open, setOpen] = useState(false);
  const [processStep, setProcessStep] = useState(0);
  const [loadingState, setLoadingState] = useState(null);
  const [step, setStep] = useState(firstStep);
  const selectedFlowRef = useRef(null);

  useImperativeHandle(modalRef, () => ({
    open: () => {
      setStep(firstStep);
      setProcessStep(0);
      setLoadingState(null);
      setOpen(true);
    },
    close: () => handleClose(),
  }));

  const handleFlowSelect = (state) => {
    selectedFlowRef.current = state;
    setStep(state === FLOW_TYPE.XKYC ? DOCUMENT_STEPS.PAYMENT : DOCUMENT_STEPS.UPLOAD_DOCS);
  };

  const handleClose = () => setOpen(false);

  const onDocsUploadSubmit = () =>
    setLoadingState({
      type: STATUS_TYPES.WAIT,
      message: 'We are processing your request. Please wait.',
    });

  const onDocsUploadFail = () =>
    setLoadingState({
      type: STATUS_TYPES.FAIL,
      message: 'There is problem with document uploading. PLease try again.',
    });

  const onDocsUploadSuccess = () =>
    setLoadingState({
      type: STATUS_TYPES.SUCCESS,
      message: 'Successfully uploaded document.',
      successButtonText: documentType.length - 1 > processStep ? 'Next Step' : 'Back to Profile',
    });

  const goToNextStep = () => {
    if (step === DOCUMENT_STEPS.UPLOAD_DOCS) {
      if (documentType.length - 1 > processStep) {
        setLoadingState(null);
        setProcessStep((prev) => prev + 1);
      } else handleClose();
    } else handleClose();
  };

  return (
    <Modal show={open} onClose={handleClose} closeIcon={<CloseIcn />} css={modalContainer}>
      <div css={uploadModalBody(step === DOCUMENT_STEPS.WARNING_MESSAGE)}>
        {step !== DOCUMENT_STEPS.WARNING_MESSAGE && (
          <div className="container">
            {step === DOCUMENT_STEPS.UPLOAD_DOCS && (
              <div className="image">{uploadDocsMap[documentType[processStep]]?.icon}</div>
            )}
            {step !== DOCUMENT_STEPS.WARNING_MESSAGE && (
              <h4>
                {step === DOCUMENT_STEPS.UPLOAD_DOCS
                  ? uploadDocsMap[documentType[processStep]]?.title
                  : uploadDocsMap[step]?.title}
              </h4>
            )}
            {step === DOCUMENT_STEPS.UPLOAD_DOCS && (
              <p>Available formats are all image formats: jpeg, jpg, png and etc.</p>
            )}
          </div>
        )}
        {step === DOCUMENT_STEPS.WARNING_MESSAGE && (
          <ProfileUploadDocsWarning onAccept={() => setStep(DOCUMENT_STEPS.CHOSE_METHOD)} onDeny={handleClose} />
        )}

        {step === DOCUMENT_STEPS.CHOSE_METHOD && (
          <ProfileKycFlowSelect onClick={handleFlowSelect} docsToUpload={nonPendingDocumentTypes} />
        )}

        {step === DOCUMENT_STEPS.UPLOAD_DOCS && documentType[processStep] === KYCType.idProof && (
          <KycModalIdForm
            onClose={handleClose}
            onSubmit={onDocsUploadSubmit}
            onFail={onDocsUploadFail}
            onSuccess={onDocsUploadSuccess}
            onUploadDocs={onUploadDocs}
            vipStatus={selectedFlowRef.current === FLOW_TYPE.PAID}
          />
        )}
        {step === DOCUMENT_STEPS.UPLOAD_DOCS && documentType[processStep] === KYCType.addressProof && (
          <KycModalAddressForm
            onClose={handleClose}
            onSubmit={onDocsUploadSubmit}
            onFail={onDocsUploadFail}
            onSuccess={onDocsUploadSuccess}
            onUploadDocs={onUploadDocs}
            vipStatus={selectedFlowRef.current === FLOW_TYPE.PAID}
          />
        )}
        <StatusIndicator
          status={loadingState?.type}
          message={loadingState?.message}
          successButtonText={loadingState?.successButtonText ?? 'Back to Profile'}
          failureButtonText="Try Again"
          onFail={() => setLoadingState(null)}
          onSuccess={goToNextStep}
        />
        {loadingState && (
          <StatusIndicator
            status={loadingState?.type}
            message={loadingState?.message}
            successButtonText={loadingState?.successButtonText ?? 'Back to Profile'}
            failureButtonText="Try Again"
            onFail={() => setLoadingState(null)}
            onSuccess={goToNextStep}
          />
        )}
      </div>
    </Modal>
  );
});

ProfileUploadDocsModal.propTypes = {
  onUploadDocs: PropTypes.func,
  documentType: PropTypes.array,
  user: PropTypes.object,
  modalRef: PropTypes.object,
  nonPendingDocumentTypes: PropTypes.any,
};

export default ProfileUploadDocsModal;
