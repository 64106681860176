import { Row, Col, Skeleton } from 'components/UI';
import { loaderContainer } from './styles';

const Loading = () => (
  <div css={loaderContainer}>
    <Row gap={32}>
      {Array(4)
        .fill()
        .map((el, i) => (
          <Col sm={3} key={`loading-state-skeleton-${i}`}>
            <Skeleton height={18} margin="0 0 8px 0" />
            <Skeleton width={80} height={18} margin="0 0 8px 0" />
            <Skeleton width={80} height={18} margin="0 0 8px 0" />
            <Skeleton height={24} margin="0 0 8px 0" />
            <Skeleton height={18} margin="0 0 8px 0" />
            <Skeleton height={18} margin="0 0 8px 0" />
            <Skeleton height={18} />
          </Col>
        ))}
    </Row>
  </div>
);
export default Loading;
