import { accountStatus } from 'enums';

export const accountStatusMap = {
  [accountStatus.Active]: {
    text: 'Active',
    color: 'green',
  },
  [accountStatus.Inactive]: {
    text: 'Inactive',
    color: 'blue',
  },
  [accountStatus.Frozen]: {
    text: 'Frozen',
    color: 'warning',
  },
  [accountStatus.Terminated]: {
    text: 'Terminated',
    color: 'red',
  },
};
