import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import moment from 'moment';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow-icon.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/right-arrow-icon.svg';
import { dateRangeIcon, dateRangeContainer } from './styles';

const DateRangeSelect = ({ onChange, startDate: startDateProp, dateFormat = 'DD-MM-YYYY' }) => {
  const [startDate, setStartDate] = useState(moment(startDateProp ?? moment(), dateFormat));
  const endDate = moment(startDate).add(6, 'days');

  useEffect(() => {
    startDateProp && setStartDate(moment(startDateProp, dateFormat));
  }, [startDateProp]);

  const handleChange = (isNext) => {
    const newState = isNext ? moment(startDate).add(7, 'days') : moment(startDate).subtract(7, 'days');
    setStartDate(newState);
    isFunction(onChange) &&
      onChange({
        startDate: newState.format(dateFormat),
        endDate: moment(newState).add(6, 'days').format(dateFormat),
      });
  };

  const headerText =
    startDate.month() === endDate.month()
      ? `${startDate.format('MMMM')} ${startDate.date()} - ${endDate.date()} `
      : `${startDate.format('MMMM')} ${startDate.date()} - ${endDate.format('MMMM')} ${endDate.date()}`;

  return (
    <div css={dateRangeContainer}>
      <div role="button" tabIndex={0} css={dateRangeIcon} onClick={() => handleChange(false)}>
        <LeftArrowIcon />
      </div>
      <div role="button" tabIndex={0} css={dateRangeIcon} onClick={() => handleChange(true)}>
        <RightArrowIcon />
      </div>
      <h4 className="mr1">{headerText}</h4>
    </div>
  );
};

DateRangeSelect.propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.string,
  dateFormat: PropTypes.string,
};

export default DateRangeSelect;
