import { useRef, useState, useEffect, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { omit } from 'lodash-es';
import { containerStyle } from './styles';

const BodyOverflow = forwardRef((props, ref) => {
  const { children, disableScroll, className, zIndex = 200, ...restProps } = props;
  const containerId = useRef(parseInt(Math.random() * 100000, 10));
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const container = document.createElement('div');
    container.id = containerId.current;
    container.style.position = 'fixed';
    container.style.zIndex = zIndex;
    document.body.insertBefore(container, document.body.getElementsByTagName('script')[0]);
    setReady(true);
    disableScroll && (document.body.style.overflow = 'hidden');

    return () => {
      document.body.removeChild(container);
      disableScroll && (document.body.style.overflow = 'auto');
    };
  }, []);

  return (
    ready &&
    ReactDOM.createPortal(
      <div
        {...(className && { className })}
        css={containerStyle(props)}
        {...omit(restProps, ['fixed', 'fullScreen'])}
        ref={ref}
      >
        {children}
      </div>,
      document.getElementById(containerId.current),
    )
  );
});

BodyOverflow.propTypes = {
  children: PropTypes.any,
  zIndex: PropTypes.number,
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  fixed: PropTypes.bool,
  disableScroll: PropTypes.bool,
};

export default BodyOverflow;
