/* eslint-disable react/destructuring-assignment */
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { isFunction } from 'lodash-es';
import { Icon } from '../../Icon';
import InputContainer from '../InputContainer';
import { useDateTimePicker } from './useDateTimePicker';
import { inputField, inputDateContainer } from './styles';
import 'react-datetime/css/react-datetime.css';

const DateTimePicker = (props) => {
  const { required, validate, dateFormat, showClear, inputProps } = props;

  const { isTouched, error, onBlur, onChange, clearDates, value, disabled, restProps } = useDateTimePicker(props);

  const hasError = isTouched && error;
  const hasValidation = isFunction(validate) || required;

  return (
    <InputContainer {...props} error={error} isTouched={isTouched} css={inputDateContainer}>
      <Datetime
        closeOnSelect
        dateFormat={dateFormat}
        value={value}
        onChange={onChange}
        onClose={onBlur}
        css={inputField(hasValidation, hasError, props)}
        renderInput={(compProps) => (
          <>
            <input {...compProps} {...inputProps} disabled={disabled} />
            {showClear && (
              <Icon
                iconName="las la-times-circle"
                color="gray200"
                className="icon-close"
                onClick={clearDates(compProps)}
              />
            )}
          </>
        )}
        {...restProps}
      />
    </InputContainer>
  );
};

DateTimePicker.propTypes = {
  showClear: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  dateTimeProps: PropTypes.any,
  validate: PropTypes.func,
  label: PropTypes.string,
  horizontal: PropTypes.bool,
  required: PropTypes.bool,
  inputProps: PropTypes.object,
  dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const DateTimePickerRef = forwardRef((props, ref) => <DateTimePicker {...props} componentRef={ref} />);

DateTimePickerRef.displayName = 'DateTimePicker';

export default DateTimePickerRef;
