export const getRandomUpperCase = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65);

export const getRandomLowerCase = () => String.fromCharCode(Math.floor(Math.random() * 26) + 97);

export const getRandomNumber = () => String.fromCharCode(Math.floor(Math.random() * 10) + 48);

export const getRandomSymbol = () => {
  const symbol = '?=.*[!?/“”~|@#$%^&*()\\-_=+{};:,<.>[]]';
  return symbol[Math.floor(Math.random() * symbol.length)];
};

export const generateRandomPassword = () => {
  const randomFunc = [getRandomUpperCase, getRandomLowerCase, getRandomNumber, getRandomSymbol];
  const getRandomFunc = () => randomFunc[Math.floor(Math.random() * Object.keys(randomFunc).length)];

  let password = '';
  const passwordLength = Math.random() * (32 - 8) + 8;

  for (let i = 1; i <= passwordLength; i++) password += getRandomFunc()();

  // check with regex
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?/'`“”~|@#$%^&*()\\\-_=+{};:,<.>[\]])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!password.match(regex)) password = generateRandomPassword();

  return password;
};
