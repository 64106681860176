import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Paper, Row, Col, Button } from 'components/UI';
import { copyToClipboard } from 'utils';
import { ReactComponent as EyeIcn } from 'assets/images/icn-eye.svg';
import { ReactComponent as LinkIcon } from 'assets/images/link.svg';
import { profileData } from './config';
import { identificationActions, paperContainer, label, identification, publicProfile } from './styles';

const UserInformation = (props) => {
  const { userData } = props;
  const [isToggleIdentification, setIsToggleIdentification] = useState({
    idCardNumber: false,
    idCardExpDate: false,
  });
  const profileUrl = `/profile/public-profile/${userData.userId}`;

  const handleIdCardNumberMask = () =>
    setIsToggleIdentification((prev) => ({
      idCardNumber: !prev.idCardNumber,
    }));

  const idCardNumberMask = () => {
    const idCardNumber = userData.idNumber ?? 'N/A';
    const maskedValue = idCardNumber.replace(
      /(\+?\d{3})(\d+)(\d{2})/g,
      (match, start, middle, end) => start + '*'.repeat(middle.length) + end,
    );
    return maskedValue;
  };

  const handleIdCarExpDateToogle = () =>
    setIsToggleIdentification((prev) => ({
      idCardExpDate: !prev.idCardExpDate,
    }));

  const idCardExpDaterMask = () => {
    const idCardExpiryDate = userData.idExpiration?.split('T')[0] ?? 'N/A';
    const maskedValue = idCardExpiryDate.slice(0, 5).replace(/$/, '****').replace(/-/g, '/');
    return maskedValue;
  };

  return (
    <div css={paperContainer}>
      <Paper>
        {profileData(userData)?.map((el) => (
          <Row gap={24} key={el.value}>
            <Col sm={6} md={3}>
              <h5 className="label-column">{el.label}</h5>
            </Col>
            <Col sm={6} md={9} css={label}>
              {el.value}
            </Col>
          </Row>
        ))}
        <hr className="horizontal-line" />
        <Row gap={24}>
          <Col sm={6} md={3}>
            <h5 className="label-column">Sponsor</h5>
          </Col>
          <Col sm={6} md={9} css={label}>
            {userData?.currentAccount?.sponsorFullName}
          </Col>
        </Row>
        <hr className="horizontal-line" />
        <Row gap={24}>
          <Col sm={6} md={3}>
            <h5 className="label-column">Public profile</h5>
          </Col>
          <Col sm={6} md={9}>
            <div css={publicProfile}>
              <Link to={profileUrl}>{profileUrl}</Link>
              <LinkIcon
                className="icon"
                onClick={() => copyToClipboard(profileUrl, 'Successfully copied your Public Profile.')}
              />
            </div>
          </Col>
        </Row>
        <hr className="horizontal-line" />
        <div>
          <div css={identificationActions}>
            <h5 className="label-column">Identity</h5>
            <Button small type="link" linkTo="/kyc" className="edit-kyc-button">
              Edit
            </Button>
          </div>

          <Row sm={6} gap={12}>
            <Col>
              <p css={label}>Passport / ID Card Number</p>
            </Col>
            <Col>
              <div css={identification}>
                <EyeIcn
                  role="button"
                  tabIndex="0"
                  className={`id-eye ${isToggleIdentification?.idCardNumber && 'visible'}`}
                  onClick={handleIdCardNumberMask}
                />

                <span className="mask-container">
                  {isToggleIdentification?.idCardNumber
                    ? userData.idNumber?.split('T')[0].replace(/-/g, '/') ?? 'N/A'
                    : idCardNumberMask()}
                </span>
              </div>
            </Col>
            <Col>
              <p css={label}>Passport / ID Card Expiry Date</p>
            </Col>
            <Col>
              <div css={identification}>
                <EyeIcn
                  role="button"
                  tabIndex="0"
                  className={`id-eye ${isToggleIdentification?.idCardExpDate && 'visible'}`}
                  onClick={handleIdCarExpDateToogle}
                />

                <span className="mask-container">
                  {isToggleIdentification?.idCardExpDate
                    ? userData.idExpiration?.split('T')[0].replace(/-/g, '/')
                    : idCardExpDaterMask()}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </Paper>
    </div>
  );
};

UserInformation.propTypes = {
  userData: PropTypes.object,
};

export default UserInformation;
