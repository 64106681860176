import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { flatten } from 'lodash-es';
import axios from 'axios';
import { setAuthConfig, AUTH_EVENTS_TYPES, renewSession } from '@oneecosystem/authenticate';
import { MenuItem, SideNav, NavBar, NavigationProvider, useUser, Notifications } from 'components';
import { initCountries, initializeUser } from 'stores';
import { trackGeoLocation } from 'services';
import { PrivateRoutes } from '../Private/PrivateRoutes';
import { PublicRoutes } from '../Public/PublicRoutes';
import NotFound from '../Public/NotFound';
import Logout from '../Authorization/Logout';
import AuthorizationCallback from '../Authorization/Callback';
import Registration from '../Private/Registration';
import ProfileOverview from '../Private/ProfileOverview';
import PaymentDetails from '../Private/PaymentDetails';
import KycVerification from '../Private/KycVerification';
import Password from '../Private/Password';
import SecurityPinCode from '../Private/SecurityPin';
import Accounts from '../Private/Accounts';
import Modules from '../Private/Modules';
import Subscriptions from '../Private/Subscriptions';

setAuthConfig({
  identity: { domain: identityUrl, clientId: 'MyOneEcosystem' },
  oneLifeDomain: apiUrls.oneLife,
  [AUTH_EVENTS_TYPES.SET_TOKEN]: (token) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [AUTH_EVENTS_TYPES.LOGIN]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: () => {
    trackGeoLocation();
    window.location.reload();
  },
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {
    window.location = '/';
  },
});

const App = () => {
  const { isAuthenticated, registrationProgressPath } = useUser();
  const history = useHistory();
  const inRegistrationProgress = history.location.pathname === registrationProgressPath;
  // console.log(isAuthenticated);

  useEffect(() => {
    initCountries();
  }, []);

  useEffect(() => {
    isAuthenticated && fetchUser();
  }, [isAuthenticated]);

  const fetchUser = async () => {
    await renewSession();
    initializeUser();
  };

  return (
    <NavigationProvider>
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/authorization-callback" component={AuthorizationCallback} />
      </Switch>

      {isAuthenticated ? (
        <>
          <Notifications />
          {inRegistrationProgress ? (
            <Switch>
              <Route component={Registration} />
            </Switch>
          ) : (
            <SideNav
              homeRoute="/overview"
              routes={flatten(Object.values(PrivateRoutes).map((el) => el.internal))}
              notFoundComponent={NotFound}
              topNavigation={NavBar}>
              <MenuItem url="/overview" label="Profile Overview" icon="las la-user-alt" component={ProfileOverview} />
              <MenuItem
                url="/payment-details"
                label="Payment Details"
                icon="las la-credit-card"
                component={PaymentDetails}
              />
              <MenuItem url="/kyc" label="KYC Verification" icon="las la-user-check" component={KycVerification} />
              <MenuItem url="/password" label="Password" icon="las la-lock" component={Password} />
              <MenuItem url="/pin-code" label="Security Pin" icon="las la-key" component={SecurityPinCode} />
              <MenuItem url="/accounts" label="Accounts" icon="las la-users" component={Accounts} />
              <MenuItem url="/modules" label="Modules" icon="las la-cube" component={Modules} />
              <MenuItem url="/subscriptions" label="Subscriptions" icon="las la-star" component={Subscriptions} />
            </SideNav>
          )}
        </>
      ) : (
        <PublicRoutes />
      )}
    </NavigationProvider>
  );
};

export default App;
