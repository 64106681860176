/* eslint-disable no-bitwise */
import { isString, isNumber, isEmpty } from 'lodash-es';

export const isStrNum = (val) => isString(val) || isNumber(val);

export const isJsonString = (text) => {
  if (typeof text !== 'string') return false;

  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const concatStrings = (...args) => args.filter(Boolean).join(' ');

export const uuid = () => {
  const pattern = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
  return pattern.replace(/x/g, () => ((Math.random() * 16) | 0).toString(16));
};

export const unset = (obj, key) => {
  const properties = key.split('.');
  const newObj = { ...obj };
  if (properties.length === 1) {
    delete newObj[properties.pop()];
    return newObj;
  }
  const curProperty = properties.shift();
  newObj[curProperty] = unset(newObj[curProperty], properties);
  if (isEmpty(newObj[curProperty])) delete newObj[curProperty];
  return newObj;
};

export const parseJson = (string, defaultValue) => {
  try {
    const parsed = JSON.parse(string);
    return parsed;
  } catch (e) {
    return defaultValue;
  }
};

export const plural = (count, label) => (count > 1 ? `${label}s` : label);

export const stringToBoolean = (string) => {
  switch (String(string).toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
};
