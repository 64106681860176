export const container = (theme) => ({
  position: 'absolute',
  top: 60,
  right: 10,
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  backgroundColor: theme.white,
  borderRadius: 6,
  color: theme.primary,
  width: 300,
  boxShadow: theme.boxShadow,

  '.appContainer': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',

    '& > svg': {
      width: 50,
      height: 50,
    },

    '& > a': {
      color: theme.gray600,
    },
  },
});

export const appIcon = {
  width: 20,
  height: 20,
  marginRight: 16,
};
