import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { tableMessageContainer } from './styles';

export const TableNoResults = ({ emptyMessage }) => (
  <section css={tableMessageContainer}>
    <div className="table-message-icon">
      <Icon iconName="las la-search" color="primary" size={96} />
    </div>
    <h4 className="table-message-title">{emptyMessage}</h4>
  </section>
);

TableNoResults.propTypes = {
  emptyMessage: PropTypes.string,
};
