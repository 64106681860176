export const actionInputContainer = (theme) => ({
  marginBottom: 16,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',

  '.actionInput': {
    '& > input': {
      position: 'relative',
      fontSize: 14,
      backgroundColor: theme.whiteGrayWhite,
      border: `1px solid ${theme.gray100}`,
      borderTopLeftRadius: 3,
      borderBottomLeftRadius: 3,
    },
  },
});
