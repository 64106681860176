import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Icon, Dropdown } from 'components';
import { useUrlParams } from '../hooks/useUrlParams';
import { paginationContainer, shownResultsIndicator, paginationButton, pageSizeDropdown } from './styles';

const defaultPageSizes = [10, 20, 50];

const Pagination = forwardRef(({ tableOptions, getData, pageSizes }, ref) => {
  const { urlQueryParams } = useUrlParams();
  const [page, setPage] = useState(urlQueryParams?.pageIndex ?? 0);
  const { totalPages, pageIndex: pageProp, pageSize, totalResults } = tableOptions.current;
  const noResults = totalPages === 0;
  const needEllipses = totalPages > 5;

  useImperativeHandle(ref, () => ({ page, setPage }));

  useEffect(() => {
    setPage(pageProp);
  }, [pageProp]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    getData({ pageIndex: newPage });
  };

  const arrowsButton = (disabled, type) => (
    <div
      role="button"
      tabIndex={0}
      css={paginationButton(disabled, false)}
      {...(!disabled && {
        onClick: () => handlePageChange(page - (type === 'left' ? 1 : -1)),
      })}
    >
      <Icon bold size={12} iconName={type === 'left' ? 'la la-angle-left' : 'la la-angle-right'} />
    </div>
  );

  const pageButton = (ind) => (
    <span
      key={ind}
      css={paginationButton(totalPages === 1, ind === page)}
      {...(totalPages !== 1 &&
        page !== ind && {
          onClick: () => handlePageChange(ind),
        })}
    >
      {ind + 1}
    </span>
  );

  const ellipsesElement = <span css={paginationButton(true, false, true)}>...</span>;

  const fromIndex = page * pageSize + 1;
  const toIndex = (page + 1) * pageSize;

  return (
    <Row justify="space-between">
      <Dropdown
        noClear
        onTop
        disabled={totalResults < 1}
        options={pageSizes ?? defaultPageSizes}
        value={urlQueryParams?.pageSize ?? tableOptions.current.pageSize}
        onChange={(value) => getData({ pageSize: value })}
        css={pageSizeDropdown}
      />
      <div css={paginationContainer}>
        <div css={shownResultsIndicator}>
          {`${fromIndex} - ${toIndex > totalResults ? totalResults : toIndex} `}(
          {totalResults ?? tableOptions.current.pageSize})
        </div>
        {arrowsButton(page === 0 || noResults, 'left')}
        {needEllipses ? (
          <>
            {pageButton(0)}
            {page > 2 && ellipsesElement}
            {page > 1 && totalPages > 3 && pageButton(page - 1)}
            {page !== 0 && page !== totalPages - 1 && pageButton(page)}
            {page < totalPages - 2 && totalPages > 4 && pageButton(page + 1)}
            {totalPages - page > 3 && ellipsesElement}
            {totalPages !== 1 && pageButton(totalPages - 1)}
          </>
        ) : (
          Array(totalPages)
            .fill()
            .map((el, ind) => pageButton(ind))
        )}
        {arrowsButton(page === totalPages - 1 || noResults, 'right')}
      </div>
    </Row>
  );
});

Pagination.propTypes = {
  tableOptions: PropTypes.object,
  getData: PropTypes.func,
  pageSizes: PropTypes.array,
};

export default Pagination;
