import { css } from '@emotion/react';

export default (theme) => css`
  @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600&display=swap');

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
  :root {
    font-size: ${theme.rootFontSize}px;
    font-weight: 400;
    line-height: 1.2;
  }
  ::placeholder {
    color: ${theme.gray200};
  }
  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }
  body {
    font-family: 'Public Sans';
    color: ${theme.gray700};
    background-color: ${theme.whiteGrayWhite};
    margin: 0;
    font-size: 1.4rem;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }
  hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    opacity: 0.25;
  }
  hr:not([size]) {
    height: 1px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1.2;
  }
  h1 {
    font-size: 4.2rem;
    font-weight: 600;
  }
  h2 {
    font-size: 2.8rem;
    font-weight: 600;
  }
  h3 {
    font-size: 2.6rem;
  }
  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.6rem;
    font-weight: 600;
  }
  h6 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  p {
    margin: 0;
  }
  b,
  strong {
    font-weight: 600;
  }
  .small,
  small {
    font-size: 1.2rem;
  }
  .big,
  big {
    font-size: 1.6rem;
  }
  .gray {
    color: ${theme.gray300};
  }
  .mark,
  mark {
    padding: 0.2em;
  }
  sub,
  sup {
    position: relative;
    font-size: 0.75rem;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    text-decoration: none;
  }
  abbr[data-bs-original-title],
  abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  ol,
  ul {
    padding: 0;
  }
  dl,
  ol,
  ul {
    margin: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  code,
  kbd,
  pre,
  samp {
    font-size: 1rem;
    direction: ltr;
    unicode-bidi: bidi-override;
  }
  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875rem;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  code {
    font-size: 0.875rem;
    word-wrap: break-word;
  }
  a > code {
    color: inherit;
  }
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 1rem;
  }
  figure {
    margin: 0 0 1rem;
  }
  img,
  svg {
    vertical-align: middle;
  }
  table {
    caption-side: bottom;
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: left;
  }
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  label {
    display: inline-block;
  }
  button {
    border-radius: 0;
  }
  button:focus:not(:focus-visible) {
    outline: 0;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  select {
    text-transform: none;
  }
  [role='button'] {
    cursor: pointer;
  }
  select {
    word-wrap: normal;
  }
  select:disabled {
    opacity: 1;
  }
  [list]::-webkit-calendar-picker-indicator {
    display: none;
  }
  [type='button'],
  [type='reset'],
  [type='submit'],
  button {
    -webkit-appearance: button;
  }
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }
  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  textarea {
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
  }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem;
    }
  }
  legend + * {
    clear: left;
  }
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }
  ::-webkit-inner-spin-button {
    height: auto;
  }
  [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::file-selector-button {
    font: inherit;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  iframe {
    // border: 0;
    display: none;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  progress {
    vertical-align: baseline;
  }
  [hidden] {
    appearance: none !important;
  }

  .container {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 300px;
    }
  }
  @media (min-width: 768px) {
    .container {
      max-width: 420px;
    }
  }
  @media (min-width: 992px) {
    .container {
      max-width: 650px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 850px;
    }
  }
  @media (min-width: 1400px) {
    .container {
      max-width: 1050px;
    }
  }

  .m05 {
    margin: 0.5em !important;
  }
  .m1 {
    margin: 1em !important;
  }
  .m15 {
    margin: 1.5em !important;
  }
  .m2 {
    margin: 2em !important;
  }
  .m25 {
    margin: 2.5em !important;
  }
  .m3 {
    margin: 3em !important;
  }
  .m35 {
    margin: 3.5em !important;
  }
  .m4 {
    margin: 4em !important;
  }
  .mt05 {
    margin-top: 0.5em !important;
  }
  .mt1 {
    margin-top: 1em !important;
  }
  .mt15 {
    margin-top: 1.5em !important;
  }
  .mt2 {
    margin-top: 2em !important;
  }
  .mt25 {
    margin-top: 2.5em !important;
  }
  .mt3 {
    margin-top: 3em !important;
  }
  .mt35 {
    margin-top: 3.5em !important;
  }
  .mt4 {
    margin-top: 4em !important;
  }
  .mr05 {
    margin-right: 0.5em !important;
  }
  .mr1 {
    margin-right: 1em !important;
  }
  .mr15 {
    margin-right: 1.5em !important;
  }
  .mr25 {
    margin-right: 2em !important;
  }
  .mr2 {
    margin-right: 2.5em !important;
  }
  .mr3 {
    margin-right: 3em !important;
  }
  .mr35 {
    margin-right: 3.5em !important;
  }
  .mr4 {
    margin-right: 4em !important;
  }
  .mb05 {
    margin-bottom: 0.5em !important;
  }
  .mb1 {
    margin-bottom: 1em !important;
  }
  .mb15 {
    margin-bottom: 1.5em !important;
  }
  .mb2 {
    margin-bottom: 2em !important;
  }
  .mb25 {
    margin-bottom: 2.5em !important;
  }
  .mb3 {
    margin-bottom: 3em !important;
  }
  .mb35 {
    margin-bottom: 3.5em !important;
  }
  .mb4 {
    margin-bottom: 4em !important;
  }
  .ml05 {
    margin-left: 0.5em !important;
  }
  .ml1 {
    margin-left: 1em !important;
  }
  .ml15 {
    margin-left: 1.5em !important;
  }
  .ml2 {
    margin-left: 2em !important;
  }
  .ml25 {
    margin-left: 2.5em !important;
  }
  .ml3 {
    margin-left: 3em !important;
  }
  .ml35 {
    margin-left: 3.5em !important;
  }
  .ml4 {
    margin-left: 4em !important;
  }
  .m05 {
    margin: 0.5em !important;
  }
  .p1 {
    padding: 1em !important;
  }
  .p15 {
    padding: 1.5em !important;
  }
  .p2 {
    padding: 2em !important;
  }
  .p25 {
    padding: 2.5em !important;
  }
  .p3 {
    padding: 3em !important;
  }
  .p35 {
    padding: 3.5em !important;
  }
  .p4 {
    padding: 4em !important;
  }
  .pt05 {
    padding-top: 0.5em !important;
  }
  .pt1 {
    padding-top: 1em !important;
  }
  .pt15 {
    padding-top: 1.5em !important;
  }
  .pt2 {
    padding-top: 2em !important;
  }
  .pt25 {
    padding-top: 2.5em !important;
  }
  .pt3 {
    padding-top: 3em !important;
  }
  .pt35 {
    padding-top: 3.5em !important;
  }
  .pt4 {
    padding-top: 4em !important;
  }
  .pr05 {
    padding-right: 0.5em !important;
  }
  .pr1 {
    padding-right: 1em !important;
  }
  .pr15 {
    padding-right: 1.5em !important;
  }
  .pr25 {
    padding-right: 2em !important;
  }
  .pr2 {
    padding-right: 2.5em !important;
  }
  .pr3 {
    padding-right: 3em !important;
  }
  .pr35 {
    padding-right: 3.5em !important;
  }
  .pr4 {
    padding-right: 4em !important;
  }
  .pb05 {
    padding-bottom: 0.5em !important;
  }
  .pb1 {
    padding-bottom: 1em !important;
  }
  .pb15 {
    padding-bottom: 1.5em !important;
  }
  .pb2 {
    padding-bottom: 2em !important;
  }
  .pb25 {
    padding-bottom: 2.5em !important;
  }
  .pb3 {
    padding-bottom: 3em !important;
  }
  .pb35 {
    padding-bottom: 3.5em !important;
  }
  .pb4 {
    padding-bottom: 4em !important;
  }
  .pl05 {
    padding-left: 0.5em !important;
  }
  .pl1 {
    padding-left: 1em !important;
  }
  .pl15 {
    padding-left: 1.5em !important;
  }
  .pl2 {
    padding-left: 2em !important;
  }
  .pl25 {
    padding-left: 2.5em !important;
  }
  .pl3 {
    padding-left: 3em !important;
  }
  .pl35 {
    padding-left: 3.5em !important;
  }
  .pl4 {
    padding-left: 4em !important;
  }
`;
