export const accountBoxContainer = (isCurrent) => (theme) => ({
  borderRadius: 3,
  boxShadow: '0 6px 8px 0 rgba(224, 225, 229, 0.58)',
  border: `solid 1px ${theme.gray100}`,
  position: 'relative',
  marginBottom: 32,
  height: 'calc(100% - 32px)',
  backgroundColor: isCurrent ? theme.white : 'none',

  '.accountBoxCurrentLabel': {
    fontWeight: 600,
    color: theme.white,
    fontSize: 14,
    lineHeight: 1.2,
    backgroundColor: theme.primaryLight,
    padding: '8px 16px',
    position: 'absolute',
    borderRadius: 3,
    top: -16,
    left: 32,
  },

  '.accountBoxHeader': {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.dark,
    padding: ' 24px 16px',
    border: `1px solid ${theme.gray100}`,
    color: theme.white,
    fontWeight: 400,

    '.accountBoxHeaderContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginBottom: 8,

      '.accountBoxHeaderId': {
        fontSize: 16,
        opacity: 0.5,
        lineHeight: 1.2,
        marginRight: 8,
        fontWeight: 400,
      },
    },

    '.accountBoxHeaderType': {
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: 0.44,
      marginBottom: 8,
      fontWeight: 400,
    },

    '.accountBoxHeaderBalance': {
      fontSize: 26,
      letterSpacing: -1.22,
      lineHeight: 1.2,
      fontWeight: 600,
    },
  },

  '.accountBoxBody': {
    padding: '24px 40px',

    '.accountBoxBodyTitle': {
      color: theme.gray500,
      fontSize: 14,
      letterSpacing: 0.38,
      lineHeight: 1.3,
      marginBottom: 16,
    },

    '.descriptionWrapper': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 24,
    },

    '.descriptionLabel': {
      marginRight: 40,
      minWidth: 55,
      color: theme.gray300,
      fontSize: 13,
      letterSpacing: 0.2,
    },

    '.descriptionValue': {
      fontSize: 14,
      letterSpacing: 0.38,
      color: theme.gray600,
    },

    '.actionContainer': {
      display: 'flex',
      justifyContent: 'center',

      '.button': {
        width: '100%',
      },
    },
  },
});
