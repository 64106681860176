import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import errorIcon from 'assets/icons/alert-warning-input.svg';
import { fadeInAnimation } from '../../styles';
import { inputContainer, inputLabel, inputHelpContainer, inputHelpContent, inputHelpIcon } from './styles';

const InputContainer = (props) => {
  const { isTouched, required, error, validate, label, className, children } = props;
  const hasError = isTouched && !!error;
  const hasValidation = isFunction(validate) || required;

  return (
    <div css={inputContainer(props)} {...(className && { className })}>
      {!!label && (
        <label css={inputLabel(props)} className="input-label">
          {`${label}${required ? ' *' : ''}`}
        </label>
      )}

      {children}

      {hasValidation && (
        <div css={inputHelpContainer} className="input-help-container">
          {hasError && (
            <div css={[inputHelpContent, fadeInAnimation()]}>
              <img src={errorIcon} alt="icon" css={inputHelpIcon} />
              <span>{error.msg}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

InputContainer.propTypes = {
  label: PropTypes.string,
  isTouched: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.any,
  validate: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default InputContainer;
