import { isFunction } from 'lodash-es';
import { commonStyles } from '../../styles';

export const inputContainer = (props) => {
  const { horizontal, formId, validate, required } = props;
  const hasValidation = isFunction(validate) || required;

  return [
    {
      display: 'flex',
      flexDirection: horizontal ? 'row' : 'column',
      alignItems: 'flex-start',
      justifyContent: horizontal ? 'space-between' : 'flex-start',
      position: 'relative',
      width: '100%',
      outline: 'none',
      marginBottom: !hasValidation && formId ? '1.6rem' : 0,
    },
    ...commonStyles(props),
  ];
};

export const inputLabel = ({ horizontal, disabled }) => (theme) => ({
  fontSize: 13,
  fontWeight: 600,
  color: disabled ? theme.gray100 : theme.gray400,
  margin: horizontal ? '0 1.5em 0 0' : '0 0 0.3em 0',
});

export const inputHelpContainer = (theme) => ({
  fontSize: '1.15rem',
  lineHeight: 1,
  color: theme.error,
  minHeight: '1.2rem',
  fontWeight: 300,
});

export const inputHelpContent = {
  display: 'flex',
  alignItems: 'flex-start',
};

export const inputHelpIcon = {
  marginRight: 3,
  width: 'auto',
  height: '1rem',
  maxHeight: '100%',
};
