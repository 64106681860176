export const userContainer = (theme) => ({
  maxWidth: 300,
  marginRight: 48,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '& .name': {
    marginBottom: 8,
  },

  '& .first-login': {
    color: theme.gray,
    marginBottom: 16,

    '& > span': {
      fontWeight: 600,
    },
  },
});

export const imageContainer = (basis, right) => (theme) => ({
  borderRadius: '50%',
  flexBasis: basis,
  flexShrink: 0,
  marginRight: right ?? 0,
  marginBottom: 16,
  border: `8px solid ${theme.white}`,
});

export const city = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.gray,
  backgroundColor: theme.white,
  padding: '6px 12px',
  borderRadius: 25,
  marginBottom: 32,

  '& .flag': {
    fontSize: 24,
  },
});

export const identificationActions = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24,

  '& .edit-kyc-button': {
    color: theme.secondaryDark,
    borderBottom: 'none',
  },
});

export const publicProfile = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '& > a': {
    color: theme.infoDark,
    fontSize: 14,
    marginRight: 24,
  },

  '.icon': {
    width: 30,
    height: 30,
    cursor: 'pointer',
    backgroundColor: theme.secondaryLight,
    color: theme.highlight,
    borderRadius: 25,
    padding: 4,
  },
});

export const label = (theme) => ({
  color: theme.gray,
  fontSize: 15,
});

export const paperContainer = (theme) => ({
  flex: 1,
  marginBottom: 32,

  '.label-column': {
    color: theme.gray200,
    fontWeight: 300,
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
    margin: '24px 0',
  },

  '.checkButtons': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },

  '.label': {
    color: theme.gray400,
    fontWeight: 300,
  },
});

export const identification = (theme) => ({
  display: 'flex',
  alignItems: 'center',

  '& .mask-container': {
    color: theme.gray300,
  },

  '& .id-eye': {
    width: 20,
    height: 20,
    marginRight: 8,
    opacity: 0.7,
    cursor: 'pointer',

    '&:hover': {
      transition: 'opacity 0.3s ease',
      opacity: 1,
    },

    '& .visible': {
      opacity: 1,
      '&:hover': {
        transition: 'opacity 0.3s ease',
        opacity: 0.7,
      },
    },
  },
});

export const spaceContainer = {
  display: 'flex !important',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24,

  '.icon': {
    width: 20,
    height: 20,
  },
};

export const inviteContainer = (theme) => ({
  position: 'relative',

  '.coinText': {
    color: theme.infoDark,
    position: 'absolute',
    top: '3.25rem',
    right: '1.2rem',
  },

  '.title': {
    marginBottom: 32,
  },
});

export const secondaryContainer = (theme) => ({
  backgroundColor: theme.secondary,
  padding: '32px 40px',
  borderRadius: 6,
  marginBottom: 24,

  '.centerContainer': {
    textAlign: 'center',

    '.title': {
      marginBottom: 16,
    },
  },
});

export const inputContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'relative',
  marginBottom: 8,

  '& > input': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 12,
    backgroundColor: theme.white,
  },

  '.icon': {
    width: 20,
    height: 20,
    cursor: 'pointer',
    backgroundColor: theme.secondaryLight,
    color: theme.highlight,
    borderRadius: 25,
    padding: 4,
    marginRight: 8,
  },
});

export const title = {
  marginBottom: 8,
};

export const flexContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: theme.white,
  minHeight: '4.8rem',
  padding: '0.7em 0.85em',
  border: `1px solid ${theme.gray100}`,
  borderLeft: 'none',

  '& > div': {
    textTransform: 'uppercase',
    color: theme.infoDark,
  },
});
