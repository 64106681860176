import moment from 'moment';
import { isArray } from 'lodash-es';
import { walletType } from 'enums';
import defaultAvatarLogo from 'assets/images/default-avatar.png';

export const myProfileModel = (data) => ({
  ...data,
  pictureUrl: data.pictureUrl ?? defaultAvatarLogo,
  picThumbnailUrl: data.picThumbnailUrl ?? defaultAvatarLogo,
  dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
  idExpiration: moment(data.idExpiration).format('YYYY-MM-DD'),
});

export const getWalletForCurrentAccount = ({ wallets, splitsBalances }) => {
  const { availableSplits: splits, maxSplits } = splitsBalances ?? {};
  const availableSplits = maxSplits < splits ? maxSplits : splits;

  return (
    wallets?.map((item) =>
      item.walletType?.toLowerCase() === walletType.TOKEN ? { ...item, ...splitsBalances, availableSplits } : item,
    ) ?? []
  );
};

export const myKycModel = (data) =>
  isArray(data?.items)
    ? {
        items: data.items.sort((a, b) => (moment(a.createdOn).isBefore(b.createdOn) ? 1 : -1)),
      }
    : null;
