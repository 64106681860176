import { isNil } from 'lodash-es';
import { registerProfile, login } from '@oneecosystem/authenticate';
import {
  Button,
  PhoneInput,
  Form,
  Row,
  Col,
  Input,
  inputValidation,
  Dropdown,
  useUser,
  Paper,
  showError,
} from 'components';
import { useCountries } from 'stores';
import { ReactComponent as DefaultCountryImage } from 'assets/images/default-country-image.svg';
import {
  actionButton,
  titleStyles,
  countryContainer,
  countryImage,
  countryListLabel,
  container,
  paperStyles,
} from './styles';

const UserProfileFrom = ({ history }) => {
  // console.log(history);
  const { isAuthenticated, onRegistrationProgress } = useUser();
  const countries = useCountries();

  const countryListOptions = countries.allWithoutRestricted?.map((el) => ({
    ...el,
    value: el.name,
    label: (
      <div css={countryContainer}>
        {!isNil(el.flag) ? (
          <div>{el.flag}</div>
        ) : (
          <div css={countryImage}>
            <DefaultCountryImage />
          </div>
        )}
        <span css={countryListLabel}>{el.name}</span>
      </div>
    ),
  }));

  const handleCrateUserProfile = async (data) => {
    // console.log(data);
    const [, err] = await await registerProfile(data);
    if (err) showError(err);
    window.location = login();
    onRegistrationProgress('/register-new-account');
    history.push('/register-new-account');
  };

  return (
    <div css={container}>
      <div className="container">
        <p className="label">Step 2 of 3</p>
        <h2 className="title">Update Profile Details</h2>
        <Paper
          header={
            <div css={paperStyles}>
              <h5 css={titleStyles}>Personal Details</h5>
              <Form onSubmit={handleCrateUserProfile}>
                <Row sm={12} horizontalGap={16} verticalGap={8}>
                  <Col>
                    <Input
                      formId="firstName"
                      label="First name"
                      required
                      validate={(value) =>
                        inputValidation('required')(value) ||
                        inputValidation('lowercase')(value) ||
                        inputValidation('uppercase')(value) ||
                        ((value.length < 1 || value.length > 100) && {
                          msg: 'First name must be at least 1 and max 100 characters long.',
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <Input
                      formId="lastName"
                      label="Last name"
                      required
                      validate={(value) =>
                        inputValidation('required')(value) ||
                        inputValidation('lowercase')(value) ||
                        inputValidation('uppercase')(value) ||
                        ((value.length < 1 || value.length > 100) && {
                          msg: 'Last name must be at least 1 and max 100 characters long.',
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <Dropdown
                      withSearch
                      required
                      formId="country"
                      label={isAuthenticated ? 'Country' : 'Residence of'}
                      options={countryListOptions}
                      mapValue={(val) => val?.value}
                      validate={inputValidation('required')}
                      displayKey="label"
                      renderOption={(val) => (
                        <div css={countryContainer}>
                          {!isNil(val.flag) ? (
                            <div>{val.flag}</div>
                          ) : (
                            <div css={countryImage}>
                              <DefaultCountryImage />
                            </div>
                          )}
                          <span css={countryListLabel}>{val.name}</span>
                        </div>
                      )}
                    />
                  </Col>
                  <Col>
                    <PhoneInput
                      required
                      formId="phone"
                      label="Mobile phone (it must be unique)"
                      validate={
                        (value) =>
                          // {
                          // console.log(value);
                          // console.log(inputValidation('required')(value?.input));
                          // console.log(inputValidation('required')(value?.phone));

                          // return
                          // not work with required fields
                          // inputValidation('required')(value?.input) ||
                          // inputValidation('required')(value?.phone) ||
                          (value?.input?.length < 6 || value?.input?.length > 30) && {
                            msg: 'Phone number must be at least 6 and max 30 characters long.',
                          }
                        // }
                      }
                    />
                  </Col>

                  <Col md={12}>
                    {({ onSubmit }) => (
                      <Button css={actionButton} onClick={onSubmit}>
                        Next
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default UserProfileFrom;
