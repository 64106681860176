import { useState } from 'react';
import { logout } from '@oneecosystem/authenticate';
import { Image, Button } from 'components/UI';
import { useUserStore } from 'stores';
import avatar from 'assets/images/default-avatar.png';
import { imageContainer, container, profile } from './styles';

const UserNavigation = () => {
  const [openContainer, setOpenContainer] = useState(false);
  const user = useUserStore();
  const shopUrl = 'https://portal.oneecosystem.eu/shop';

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpenContainer(false);
  };

  return (
    <div role="button" tabIndex={0} onClick={() => setOpenContainer(true)} onBlur={hideContainer}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()}>
        <Image
          src={user?.picThumbnailUrl ?? user?.pictureUrl ?? avatar}
          css={imageContainer(30)}
          width={30}
          height={30}
        />
      </div>

      {openContainer && (
        <div css={container}>
          <div role="button" tabIndex={0} css={profile}>
            <Image
              src={user?.picThumbnailUrl ?? user?.pictureUrl ?? avatar}
              width={64}
              height={64}
              css={imageContainer(32, 12, 16)}
            />

            <p className="name">
              {/* {`${user?.firstName} ${user?.lastName}`} */}
              Elica Kehaiova
            </p>
            <p className="grayColor">{/* {`# ${user?.currentAccount?.id}`} */}# 1234</p>
            <Button type="link" small className="editLink">
              Edit
            </Button>
          </div>

          <div className="planContainer">
            <div>
              <p className="grayColor">Current plan</p>
              <p className="package">{user?.currentAccount?.package?.name}</p>
            </div>
            <Button type="link" linkTo={shopUrl} small className="editLink">
              Upgrade
            </Button>
          </div>
          <Button small type="link" linkTo="/send-invitation">
            + Invite Friends
          </Button>

          <Button small type="default" linkTo={logout()} className="button">
            Sign out
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserNavigation;
