import { Children, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash-es';
import { useNavigationControls } from '../NavigationContext';
import MenuList from './MenuList';
import Content from './Content';
import { mainContainer } from './styles';

const SideNav = ({
  children: initChildren = [],
  routes = [],
  notFoundComponent,
  homeRoute,
  topNavigation,
  footer,
  closeBreakPoint = 992,
}) => {
  const children = Children.toArray(initChildren).filter(Boolean);
  const { changeAllRoutes } = useNavigationControls();

  useEffect(() => {
    extractAllRoutes();
  }, []);

  const extractAllRoutes = () => {
    const allRoutesList = [...routes];

    const extractNestedRoutes = (elements) =>
      elements.forEach((el) => {
        const menu = { ...(el?.props ?? el) };
        if (isArray(menu?.subMenus)) {
          menu.redirectsTo = menu.subMenus[0]?.url;
          allRoutesList.push(menu);
          return extractNestedRoutes(menu.subMenus);
        }
        return allRoutesList.push(menu);
      });

    extractNestedRoutes(children);
    changeAllRoutes(allRoutesList ?? []);
  };

  return (
    <main css={mainContainer}>
      <MenuList sideNavChildrens={children} footer={footer} homeRoute={homeRoute} closeBreakPoint={closeBreakPoint} />
      <Content notFoundComponent={notFoundComponent} homeRoute={homeRoute} topNavigation={topNavigation} />
    </main>
  );
};

SideNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  routes: PropTypes.array,
  notFoundComponent: PropTypes.func,
  homeRoute: PropTypes.string,
  topNavigation: PropTypes.any,
  footer: PropTypes.any,
  closeBreakPoint: PropTypes.number,
};

export default SideNav;
