import PropTypes from 'prop-types';
import { Paper, Button, Form, Row, Col, Input, inputValidation, showApiError, showSuccess } from 'components/UI';
import { MaskText } from 'components';
import { paymentMethodTypes } from 'enums';
import { newPaymentMethod, upgradePaymentMethod } from 'services';
import { headerContainer, rowContainer } from './styles';

const { ChinaUnionPay } = paymentMethodTypes;

const BankPaymentCard = (props) => {
  const { edit, editChinaPay, paymentMethods, handleEdit, tableRef } = props;

  const handleSubmit = async (values) => {
    const isEdit = paymentMethods?.[ChinaUnionPay];

    const [, err] = isEdit
      ? await upgradePaymentMethod(ChinaUnionPay, values)
      : await newPaymentMethod(ChinaUnionPay, values);

    if (err) return showApiError(err);

    // All success add/update payment method
    showSuccess(`You have successfully ${isEdit ? 'updated' : 'add'} payment method.`);

    handleEdit((prev) => ({
      editChinaPay: false,
      paymentMethods: {
        ...prev.paymentMethods,
        [ChinaUnionPay]: values,
      },
    }));

    tableRef.current.getData();
  };

  return (
    <Paper
      header={() => (
        <div css={headerContainer}>
          <h5>China Union Pay</h5>
          {!edit?.editChinaPay && (
            <Button type="link" small onClick={editChinaPay} className="edit-button">
              Edit
            </Button>
          )}
        </div>
      )}
    >
      <Form
        values={{
          bank: paymentMethods?.[ChinaUnionPay]?.bank,
          bankZipCode: paymentMethods?.[ChinaUnionPay]?.bankZipCode,
          bankCity: paymentMethods?.[ChinaUnionPay]?.bankCity,
          bankBranch: paymentMethods?.[ChinaUnionPay]?.bankBranch,
          holderName: paymentMethods?.[ChinaUnionPay]?.holderName,
          cardNumber: paymentMethods?.[ChinaUnionPay]?.cardNumber ?? '',
        }}
        onSubmit={handleSubmit}
      >
        <Row gap={4} css={rowContainer}>
          <Col sm={6}>
            <p className="label">Bank</p>
          </Col>
          <Col sm={6}>
            {edit?.editChinaPay ? (
              <Input required formId="bank" validate={inputValidation('required', 'lettersNumbersPunctuation')} />
            ) : (
              <p className="value">{paymentMethods?.[ChinaUnionPay]?.bank ?? 'N/A'} </p>
            )}
          </Col>
          <Col sm={6}>
            <p className="label">Bank Zip Code</p>
          </Col>
          <Col sm={6}>
            {edit?.editChinaPay ? (
              <Input
                required
                formId="bankZipCode"
                validate={inputValidation('required', 'lettersNumbersPunctuation')}
              />
            ) : (
              <p className="value">{paymentMethods?.[ChinaUnionPay]?.bankZipCode ?? 'N/A'}</p>
            )}
          </Col>
          <Col sm={6}>
            <p className="label">Bank City</p>
          </Col>
          <Col sm={6}>
            {edit?.editChinaPay ? (
              <Input required formId="bankCity" validate={inputValidation('required', 'lettersNumbersPunctuation')} />
            ) : (
              <p className="value">{paymentMethods?.[ChinaUnionPay]?.bankCity ?? 'N/A'}</p>
            )}
          </Col>
          <Col sm={6}>
            <p className="label">Bank Branch</p>
          </Col>
          <Col sm={6}>
            {edit?.editChinaPay ? (
              <Input required formId="bankBranch" validate={inputValidation('required')} />
            ) : (
              <p className="value">{paymentMethods?.[ChinaUnionPay]?.bankBranch ?? 'N/A'}</p>
            )}
          </Col>
          <Col sm={6}>
            <p className="label">Card Holder Name</p>
          </Col>
          <Col sm={6}>
            {edit?.editChinaPay ? (
              <Input required formId="holderName" validate={inputValidation('required')} />
            ) : (
              <p className="value">{paymentMethods?.[ChinaUnionPay]?.holderName ?? 'N/A'}</p>
            )}
          </Col>
          <Col sm={6}>
            <p className="label">Card Number</p>
          </Col>
          <Col sm={6}>
            {edit?.editChinaPay ? (
              <Input required formId="cardNumber" validate={inputValidation('required')} />
            ) : (
              <MaskText value={paymentMethods?.[ChinaUnionPay]?.bic} type="cardNumber" />
            )}
          </Col>
          {edit?.editChinaPay && (
            <Col displayFlex md={12} justify="flex-end">
              {({ onSubmit }) => (
                <>
                  <Button small type="link" onClick={editChinaPay} margin="0 1em 0 0">
                    Cancel
                  </Button>
                  <Button small onClick={onSubmit}>
                    Save
                  </Button>
                </>
              )}
            </Col>
          )}
        </Row>
      </Form>
    </Paper>
  );
};

BankPaymentCard.propTypes = {
  edit: PropTypes.object,
  editChinaPay: PropTypes.func,
  paymentMethods: PropTypes.object,
  handleEdit: PropTypes.func,
  tableRef: PropTypes.object,
};

export default BankPaymentCard;
