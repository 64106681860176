import { Row, Col, Skeleton } from 'components/UI';
import { packageCardContainer } from './styles';

const Loading = ({ items }) =>
  Array(items ?? 10)
    .fill()
    .map((el, i) => (
      <Col gap={16} xl={3} lg={4} sm={6} xs={12} key={i}>
        <div css={packageCardContainer}>
          <div className="packageCardTop">
            <Row align="center">
              <Col sm="auto">
                <Skeleton height={24} />
                <Skeleton width={80} height={24} />
              </Col>
              <Col sm="auto">
                <Skeleton width={110} height={110} circle />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    ));

export default Loading;
