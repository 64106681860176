export const phoneInputContainer = (hasValidation) => ({
  display: 'flex',
  width: '100%',
  marginBottom: hasValidation ? 6 : 0,
});

export const phoneInputCountrySelect = {
  flex: '1 0 100px',
  marginRight: 8,
};

export const countryContainer = {
  display: 'flex',
  padding: 8,
};

export const countryListLabel = {
  marginLeft: 12,
};

export const countryImage = {
  marginRight: 12,
  width: 20,
  height: 20,
};
