import PropTypes from 'prop-types';
import { isArray } from 'lodash-es';
import { Tooltip, Icon } from 'components';
import { tooltipListWrap, tooltipListItem, tableMoreLabel } from './styles';

const TableMoreColumn = ({ list, position = 'bottom-start' }) =>
  isArray(list) ? (
    <>
      <p>{list[0]}</p>
      {list.length > 1 && (
        <Tooltip
          noArrow
          onClick
          placement={position}
          backgroundColor="white"
          color="gray50"
          padding={0}
          border={(t) => `1px solid ${t.gray100}`}
          content={
            <div css={tooltipListWrap}>
              {list.slice(1).map((el, i) => (
                <p key={i} css={tooltipListItem}>
                  {el}
                </p>
              ))}
            </div>
          }
        >
          <p css={tableMoreLabel}>
            <span>+ {list.length - 1} more</span> <Icon iconName="la la-angle-down" />
          </p>
        </Tooltip>
      )}
    </>
  ) : null;

TableMoreColumn.propTypes = {
  list: PropTypes.array,
  position: PropTypes.string,
};

export default TableMoreColumn;
