/* eslint-disable no-param-reassign */
import { forwardRef, useImperativeHandle, useRef } from 'react';

const autoSubmitScript = '<script>window.onload = function() { downloadForm.submit(); }</script>';
const generateFormNode = (action, children) =>
  `<form name='downloadForm' action='${action}' method='POST'>${children}</form>`;
const iframeSrc = (string) => `data:text/html;charset=utf-8,${escape(string)}`;

const appendChildIframe = (firstIframeNode, secondIframeNode) => {
  firstIframeNode.appendChild(secondIframeNode);
};

const addIframeStyles = (iframe) => {
  iframe.height = window.innerHeight;
  iframe.width = window.innerWidth;
  iframe.style.position = 'fixed';
  iframe.style.top = '0';
  iframe.style.left = '0';
  iframe.style.zIndex = '32000';
  iframe.style.border = 'none';
  iframe.style.backgroundColor = '#fff';
};

const Verify3dsPayment = forwardRef((props, reference) => {
  const iframeContainerRef = useRef();

  useImperativeHandle(reference, () => ({
    verifyPayment: (params) => verifyPayment(params),
    completeVerification: () => {
      iframeContainerRef.current.innerHTML = '';
    },
  }));

  const verifyPayment = (response) => {
    const redirectUrl = response.threeds_response.url;

    if (response.threeds_response?.preconditions?.length) {
      const firstIframeNode = document.createElement('iframe');
      const secondIframeNode = document.createElement('iframe');

      const [firstPrediction] = response.threeds_response.preconditions;
      const [firstParams] = firstPrediction.parameters;

      const inputNode = `<input type='hidden' name='${firstParams.name}' value='${firstParams.value}' />`;
      firstIframeNode.src = iframeSrc(`${generateFormNode(firstPrediction.url, inputNode)}${autoSubmitScript}`);
      // secondIframeNode.src =
      //   response.threeds_response?.status === '00'
      //     ? `${response.threeds_response.url}?id=${response.threeds_response.id}&order_number=${response.threeds_response.order_number}`
      //     : iframeSrc(`${generateFormNode(redirectUrl, inputNode)}${autoSubmitScript}`);
      secondIframeNode.src = iframeSrc(`${generateFormNode(redirectUrl, inputNode)}${autoSubmitScript}`);
      addIframeStyles(secondIframeNode);
      iframeContainerRef.current.appendChild(firstIframeNode);

      firstIframeNode.onload = appendChildIframe(iframeContainerRef.current, secondIframeNode);
      firstIframeNode.style.visibility = 'hidden';
      firstIframeNode.style.position = 'absolute';
    } else if (response.threeds_response?.status === '00') {
      const firstIframeNode = document.createElement('iframe');
      addIframeStyles(firstIframeNode);
      firstIframeNode.src = `${response.threeds_response.url}?id=${response.threeds_response.id}&order_number=${response.threeds_response.order_number}`;
      iframeContainerRef.current.appendChild(firstIframeNode);
    } else {
      /* This is fallback to threeds 1.0 */
      const iframeNode = document.createElement('iframe');
      addIframeStyles(iframeNode);
      iframeNode.style.backgroundColor = '#fff';

      const formNode = document.createElement('form');
      formNode.action = redirectUrl;
      formNode.method = 'POST';
      formNode.name = 'downloadForm';

      for (let i = 0; i < response.threeds_response.parameters.length; i++) {
        const inputField = document.createElement('input');
        inputField.type = 'hidden';
        inputField.name = response.threeds_response.parameters[i].name;
        inputField.value = response.threeds_response.parameters[i].value;
        formNode.appendChild(inputField);
      }

      iframeNode.src = iframeSrc(`${formNode.outerHTML}${autoSubmitScript}`);
      iframeContainerRef.current.appendChild(iframeNode);
    }
  };

  return <div ref={iframeContainerRef} />;
});

export default Verify3dsPayment;
