import SendInvitation from './ProfileOverview/SendInvitation';
import ModuleDetailPage from './Modules/ModuleDetailPage';
import SubscriptionDetailsPage from './Subscriptions/SubscriptionDetailsPage';

export const PrivateRoutes = {
  overview: {
    internal: [
      {
        url: '/send-invitation',
        component: SendInvitation,
      },
    ],
  },
  modules: {
    internal: [
      {
        url: '/modules/details/:id',
        component: ModuleDetailPage,
      },
    ],
  },
  subscriptions: {
    internal: [
      {
        url: '/subscriptions/details/:id',
        component: SubscriptionDetailsPage,
      },
    ],
  },
};
