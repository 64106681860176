import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { Icon } from 'components/UI';
import { backButtonContainer } from './styles';

const BackButton = ({ className, onBack }) => {
  const history = useHistory();

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onBack && isFunction(onBack) ? onBack : history.goBack}
      css={backButtonContainer}
      {...(className && { className })}
    >
      <Icon iconName="la la-arrow-left" size={20} />
      Go Back
    </div>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func,
};

export default BackButton;
