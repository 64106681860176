import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

const UserContext = React.createContext({});
export const useUser = () => useContext(UserContext);

const storageKey = 'token';
const storageRegistrationProgressKey = 'registrationProgress';
const firstLogin = 'firstLogin';

export const UserProvider = (props) => {
  const { children } = props;
  const [user, setUser] = useState({
    isAuthenticated: localStorage.getItem(storageKey),
    registrationProgressPath: localStorage.getItem(storageRegistrationProgressKey),
    isFirstLogin: localStorage.getItem(firstLogin),
  });

  const logout = () => {
    setUser((prev) => ({ ...prev, isAuthenticated: false }));
    localStorage.removeItem(storageKey);
  };

  const login = () => {
    setUser((prev) => ({ ...prev, isAuthenticated: true, isFirstLogin: true }));
    localStorage.setItem(storageKey, true);
    localStorage.setItem(firstLogin, true);
  };

  const onRegistrationProgress = (path) => {
    setUser((prev) => ({ ...prev, registrationProgressPath: path }));
    localStorage.setItem(storageRegistrationProgressKey, path);
  };

  return (
    <UserContext.Provider
      value={{
        ...user,
        setUser,
        login,
        logout,
        onRegistrationProgress,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
  renderRedirect: PropTypes.func,
  renderLogout: PropTypes.func,
};
