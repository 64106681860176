import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Image } from 'components/UI';
import starterLogo from 'assets/images/starter.png';
import { packageCardContainer } from './styles';

const Module = (props) => {
  const { item = {} } = props;
  const { name, price, catalogueItemId } = item;

  return (
    <div css={packageCardContainer}>
      <Link
        to={{
          pathname: `/modules/details/${catalogueItemId}`,
          state: { ...item },
        }}
      >
        <div className="packageCardTop">
          <Row align="center">
            <Col xs={12} sm={7}>
              <h5>
                {name} <br />€{price.toFixed(2)}
              </h5>
            </Col>
            <Col xs={12} sm={5}>
              <Image
                src={item?.picThumbnailUrl ?? item?.pictureUrl ?? starterLogo}
                className="image"
                alt="img-networkbonus"
              />
            </Col>
          </Row>
        </div>
      </Link>
    </div>
  );
};

Module.propTypes = {
  item: PropTypes.any,
};

export default Module;
