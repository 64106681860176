import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash-es';
import {
  Paper,
  Button,
  Form,
  Row,
  Col,
  Input,
  inputValidation,
  showApiError,
  showSuccess,
  Dropdown,
  showError,
} from 'components/UI';
import { MaskText } from 'components';
import { paymentMethodTypes } from 'enums';
import { newPaymentMethod, upgradePaymentMethod, getAllCountries } from 'services';
import { ReactComponent as DefaultCountryImage } from 'assets/images/default-country-image.svg';
import { headerContainer, rowContainer, countryContainer, countryImage, countryListLabel } from './styles';

const { BankDetails } = paymentMethodTypes;

const BankPaymentCard = (props) => {
  const { edit, editBank, paymentMethods, handleEdit, tableRef } = props;
  const [countries, setCountries] = useState();

  useEffect(() => {
    fetchAllCountries();
  }, []);

  const fetchAllCountries = async () => {
    const [res, err] = await getAllCountries();
    err ? showError(err) : setCountries(res);
  };

  const handleSubmit = async (values) => {
    const isEdit = paymentMethods?.[BankDetails];

    const [, err] = isEdit
      ? await upgradePaymentMethod(BankDetails, values)
      : await newPaymentMethod(BankDetails, values);

    if (err) return showApiError(err);

    // All success add/update payment method
    showSuccess(`You have successfully ${isEdit ? 'updated' : 'add'} payment method.`);

    handleEdit((prev) => ({
      editBank: false,
      paymentMethods: {
        ...prev.paymentMethods,
        [BankDetails]: values,
      },
    }));

    tableRef.current.getData();
  };

  const countryListOptions = countries?.map((el) => ({
    ...el,
    value: el.name,
    label: (
      <div css={countryContainer}>
        {!isNil(el.flag) ? (
          <div>{el.flag}</div>
        ) : (
          <div css={countryImage}>
            <DefaultCountryImage />
          </div>
        )}
        <span css={countryListLabel}>{el.name}</span>
      </div>
    ),
  }));

  return (
    <Paper
      header={() => (
        <div css={headerContainer}>
          <h5>Bank Details</h5>
          {!edit?.editBank && (
            <Button type="link" small onClick={editBank} className="edit-button">
              Edit
            </Button>
          )}
        </div>
      )}
    >
      <Form
        values={{
          accountName: paymentMethods?.[BankDetails]?.accountName,
          bankName: paymentMethods?.[BankDetails]?.bankName,
          bankCountry: paymentMethods?.[BankDetails]?.bankCountry,
          ibanAccount: paymentMethods?.[BankDetails]?.ibanAccount ?? '',
          bic: paymentMethods?.[BankDetails]?.bic ?? '',
        }}
        onSubmit={handleSubmit}
      >
        <Row gap={4} css={rowContainer}>
          <Col sm={6}>
            <p className="label">Account Holder Name</p>
          </Col>
          <Col sm={6}>
            {edit?.editBank ? (
              <Input
                required
                formId="accountName"
                validate={inputValidation('required', 'lettersNumbersPunctuation')}
              />
            ) : (
              <p className="value">{paymentMethods?.[BankDetails]?.accountName}</p>
            )}
          </Col>
          <Col sm={6}>
            <p className="label">Bank Name</p>
          </Col>
          <Col sm={6}>
            {edit?.editBank ? (
              <Input required formId="bankName" validate={inputValidation('required', 'lettersNumbersPunctuation')} />
            ) : (
              <p className="value">{paymentMethods?.[BankDetails]?.bankName}</p>
            )}
          </Col>
          <Col sm={6}>
            <p className="label">Bank Country</p>
          </Col>
          <Col sm={6}>
            {edit?.editBank ? (
              <Dropdown
                withSearch
                required
                formId="bankCountry"
                options={countryListOptions}
                mapValue={(val) => val?.value}
                validate={inputValidation('required')}
                displayKey="label"
                renderOption={(val) => (
                  <div css={countryContainer}>
                    {!isNil(val.flag) ? (
                      <div>{val.flag}</div>
                    ) : (
                      <div css={countryImage}>
                        <DefaultCountryImage />
                      </div>
                    )}
                    <span css={countryListLabel}>{val.name}</span>
                  </div>
                )}
              />
            ) : (
              <p className="value">{paymentMethods?.[BankDetails]?.bankCountry}</p>
            )}
          </Col>
          <Col sm={6}>
            <p className="label">IBAN Account</p>
          </Col>
          <Col sm={6}>
            {edit?.editBank ? (
              <Input required formId="ibanAccount" validate={inputValidation('required')} />
            ) : (
              <MaskText value={paymentMethods?.[BankDetails]?.ibanAccount} type="iban" />
            )}
          </Col>
          <Col sm={6}>
            <p className="label">BIC / SWIFT Code</p>
          </Col>
          <Col sm={6}>
            {edit?.editBank ? (
              <Input required formId="bic" validate={inputValidation('required')} />
            ) : (
              <MaskText value={paymentMethods?.[BankDetails]?.bic} type="bicSwift" />
            )}
          </Col>
          {edit?.editBank && (
            <Col displayFlex md={12} justify="flex-end">
              {({ onSubmit }) => (
                <>
                  <Button small type="link" onClick={editBank} margin="0 1em 0 0">
                    Cancel
                  </Button>
                  <Button small onClick={onSubmit}>
                    Save
                  </Button>
                </>
              )}
            </Col>
          )}
        </Row>
      </Form>
    </Paper>
  );
};

BankPaymentCard.propTypes = {
  edit: PropTypes.object,
  editBank: PropTypes.func,
  paymentMethods: PropTypes.object,
  handleEdit: PropTypes.func,
  tableRef: PropTypes.object,
};

export default BankPaymentCard;
