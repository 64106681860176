import { updatePassword } from '@oneecosystem/authenticate';
import { Button, Paper } from 'components/UI';
import { ReactComponent as PadlockIcn } from 'assets/images/icn-big-padlock-wt-bg.svg';
import { passwordContainer } from './styles';

const Password = () => (
  <div css={passwordContainer}>
    <h3>Profile Password</h3>
    <hr className="horizontal-line" />

    <Paper className="paperContainer">
      <div className="password">
        <PadlockIcn className="icon" />
        <p>Change Profile Password</p>
      </div>
      <hr className="horizontal-line" />

      <Button linkTo={updatePassword()} className="passwordButton">
        Change Password
      </Button>
    </Paper>
  </div>
);

export default Password;
