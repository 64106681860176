export const dateFormat = 'DD-MM-YYYY';
export const timeFormat = 'HH:mm:ss';
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const daysOfWeek = {
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
  sunday: 'sunday',
};
