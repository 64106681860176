import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isFunction, isPlainObject, isString, startCase, get, isNil } from 'lodash-es';
import { CheckButton } from '../Button';
import { tableRow, tableCell, tableCellEmpty } from './styles';

const NullItem = () => <div css={tableCellEmpty} />;

export const displayValue = (column, row) => {
  const value = get(row, column.value.split('.'));

  switch (true) {
    case isPlainObject(column.mapValue):
      return column.mapValue[value.toLowerCase()];
    case isString(column.dateTimeFormat):
      return moment(value).isValid() ? moment(value).format(column.dateTimeFormat) : <NullItem />;
    case column.startCase:
      return startCase(value);
    default:
      return !isNil(value) && String(value).length ? String(value) : <NullItem />;
  }
};

const TableBody = (props) => {
  const { data, columns, selectRow, hasSelection, renderRow, onRowClick, rowLink } = props;

  const RowComp = isFunction(rowLink) ? Link : 'div';

  return data.map((row, i) =>
    isFunction(row.render) || isFunction(renderRow) ? (
      (row.render ?? renderRow)({
        row,
        NullItem,
        columns,
        rowClassName: tableRow,
        columnClassName: tableCell,
        displayValue,
      })
    ) : (
      <RowComp
        key={`table-row-${i}`}
        css={tableRow(isFunction(rowLink) || isFunction(onRowClick), row?._table?.isSelected)}
        {...(isFunction(rowLink) && rowLink(row))}
        {...(isFunction(onRowClick) && { onClick: () => onRowClick(row) })}
      >
        {hasSelection && (
          <div role="presentation" css={tableCell({ width: 40, render: true })} onClick={(e) => e.stopPropagation()}>
            <CheckButton onChange={(value) => selectRow(value, row)} value={row?._table?.isSelected} />
          </div>
        )}
        {columns.map((column, j) => (
          <div key={`table-column${i}-${j}`} css={tableCell(column)}>
            {isFunction(column.render) ? column?.render(row, NullItem) : displayValue(column, row)}
          </div>
        ))}
      </RowComp>
    ),
  );
};

TableBody.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  selectRow: PropTypes.func,
  hasSelection: PropTypes.bool,
  renderRow: PropTypes.func,
};

export default TableBody;
