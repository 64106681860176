import PropTypes from 'prop-types';
import { isArray } from 'lodash-es';
import { Link } from 'react-router-dom';
import { Button, Tooltip, Icon } from 'components/UI';
import { tooltipListItem, tableActionsLabel } from './styles';

// eslint-disable-next-line max-len
const externalLinkExpression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i;

const TableActions = ({ mainAction, actionsList, position = 'left' }) => (
  <>
    {mainAction && <Button small margin="0 8px 0 0" {...mainAction} />}
    {isArray(actionsList) && (
      <Tooltip
        noArrow
        onClick
        singleLine
        placement={position}
        backgroundColor="white"
        color="gray50"
        padding={0}
        border={(t) => `1px solid ${t.gray100}`}
        content={
          <ul>
            {actionsList.filter(Boolean).map(({ linkTo, icon, label, styles, ...restProps }) => {
              const isExternalLink = externalLinkExpression.test(linkTo?.pathname);
              const Component = linkTo ? (isExternalLink ? 'a' : Link) : 'li';

              return (
                <Component
                  key={label}
                  {...(!isExternalLink && !linkTo && { role: 'button', tabIndex: 0 })}
                  {...(linkTo && !isExternalLink && { to: linkTo })}
                  {...(isExternalLink && { href: linkTo?.pathname, target: '_blank' })}
                  css={tooltipListItem(styles)}
                  {...restProps}
                >
                  {icon && <Icon margin="0 0.5em 0 0" size={20} {...icon} />}
                  <span>{label}</span>
                </Component>
              );
            })}
          </ul>
        }
      >
        <span css={tableActionsLabel}>...</span>
      </Tooltip>
    )}
  </>
);

TableActions.propTypes = {
  mainAction: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  actionsList: PropTypes.array,
  position: PropTypes.string,
};

export default TableActions;
