import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import BodyOverflow from '../BodyOverflow';
import { Icon } from '../Icon';
import { modalContainer, modalScrollContainer, modalContentContainer } from './styles';

const Modal = forwardRef((props, ref) => {
  const { children, show = false, withoutClose, closeIcon, onClose, onOpen, className } = props;
  const [showModal, setShowModal] = useState(show);

  useImperativeHandle(ref, () => ({ open: () => open(), close: () => close() }));

  useEffect(() => {
    if (withoutClose) return;

    const handleKeyDown = (e) => (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) && close();

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    show ? document.body.style.setProperty('overflow', 'hidden') : document.body.style.removeProperty('overflow');
    setShowModal(show);
  }, [show]);

  const close = () => {
    isFunction(onClose) && onClose();
    setShowModal(false);
  };

  const open = () => {
    isFunction(onOpen) && onOpen();
    setShowModal(true);
  };

  return (
    showModal && (
      <BodyOverflow fixed fullScreen css={modalContainer} zIndex={1000} onClick={close}>
        <section css={modalScrollContainer(props)} {...(className && { className })}>
          <div role="presentation" css={modalContentContainer(props)} onClick={(e) => e.stopPropagation()}>
            {!withoutClose && closeIcon && (
              <Icon iconName="las la-times" size={20} position="absolute" top="2rem" right="2rem" onClick={close} />
            )}
            {children}
          </div>
        </section>
      </BodyOverflow>
    )
  );
});

Modal.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool,
  withoutClose: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  className: PropTypes.string,
  closeIcon: PropTypes.any,
};

export default Modal;
