import { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash-es';
import { Modal, Row, Col, Button, showApiError, Image } from 'components/UI';
import { getConfigParam } from 'services';
import { ReactComponent as CloseIcn } from 'assets/images/icn-close.svg';
import { ReactComponent as AllowedIcn } from 'assets/images/icn-checklist-checked.svg';
import { ReactComponent as NotAllowedIcn } from 'assets/images/icn-not-allowed.svg';
import starterLogo from 'assets/images/starter.png';
import { packageInfoDataFirstCol, packageInfoDataSecondCol } from './config';
import { redeemContainer, actionContainer, packageDetailsInfoBoxContainer, bonusBoxContainer } from './styles';

const ConfirmRedeemDialog = forwardRef((props, ref) => {
  const { onRedeemCode, packageByGiftCode } = props;
  const [config, setConfig] = useState(null);
  const modalRef = useRef(null);

  let hasMatchingBonus;

  useImperativeHandle(ref, () => ({
    showModal: () => modalRef.current?.open(),
    closeModal: () => modalRef.current?.close(),
  }));

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [resConfig, errorConfig] = await getConfigParam('ExchangeRate', 'number');

    if (errorConfig) return showApiError(errorConfig);
    setConfig(resConfig);
  };

  if (packageByGiftCode) {
    const { hasMatch1Bonus, hasMatch2Bonus, hasMatch3Bonus, hasMatch4Bonus } = packageByGiftCode;

    hasMatchingBonus = hasMatch1Bonus || hasMatch2Bonus || hasMatch3Bonus || hasMatch4Bonus;
  }

  return (
    <Modal large onClose={modalRef.current?.close()} closeIcon={<CloseIcn />} css={redeemContainer}>
      <h5 className="title">Redeem Gift Code</h5>

      <header className="bodyImageContainer">
        <Row horizontalGap={16} align="center">
          <Col xs={3} lg={3}>
            <Image
              src={packageByGiftCode?.picThumbnailUrl ?? packageByGiftCode?.pictureUrl ?? starterLogo}
              className="headerImage"
              alt="header-image"
            />
          </Col>
          <Col xs={6} lg={6}>
            <p className="packageDetailsName">{packageByGiftCode?.name}</p>
            <h1 className="packageDetailsPrice">€ {packageByGiftCode?.price}</h1>
            <p className="packageDetailsDescription">
              <b>Description:</b>
              {packageByGiftCode?.description}
            </p>
          </Col>
          <Col xs={3} lg={3}>
            <div className="packageDetailsImmediateContainer">
              {packageByGiftCode?.immediateMining ? (
                <AllowedIcn className="image" />
              ) : (
                <NotAllowedIcn className="image" />
              )}
              <p>Automining is {!packageByGiftCode?.immediateMining && 'not '} Allowed</p>
            </div>
          </Col>
        </Row>
      </header>

      <section className="bodyContent">
        <Row horizontalGap={16}>
          <Col xs={12} lg={7}>
            <div css={packageDetailsInfoBoxContainer}>
              {packageInfoDataFirstCol(packageByGiftCode, config).map((el, ind) => (
                <div className="infoBoxItem" key={ind}>
                  <div className="infoBoxItemContainer">
                    <div className="boxIcon">{el.image}</div>
                    <div>
                      <h4 className="infoBoxItemTextContainerTitle">{el.title}</h4>
                      <p className="infoBoxItemTextContainerSubtitle">{el.subtitle}</p>
                    </div>
                  </div>

                  <h3 className="infoBoxItemTextContainerAmount">{el.amount}</h3>
                </div>
              ))}
            </div>
          </Col>
          <Col xs={12} lg={5}>
            <div css={bonusBoxContainer}>
              {packageInfoDataSecondCol(packageByGiftCode, hasMatchingBonus).map((el, ind) => (
                <div className="bonusItemContainer" key={ind}>
                  <div className="bonusRowContainer">
                    <div className="image">{el.image}</div>
                    {isNil(el.level) ? (
                      <h5 className="infoBoxItemTextContainerTitle">{el.title}</h5>
                    ) : (
                      <h4 className="infoBoxItemTextContainerLevel">{el.level}</h4>
                    )}
                  </div>
                  <div>
                    {!isNil(el.value) ? (
                      <p className="infoBoxItemTextContainerValue">{el.value}</p>
                    ) : (
                      <h3 className="infoBoxItemTextContainerAmount">{el.amount}</h3>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </section>

      <section css={actionContainer}>
        <Button small type="secondary" onClick={modalRef.current?.close()}>
          Cancel
        </Button>
        <Button small onClick={onRedeemCode}>
          Redeem
        </Button>
      </section>
    </Modal>
  );
});

ConfirmRedeemDialog.propTypes = {
  onRedeemCode: PropTypes.func,
  packageByGiftCode: PropTypes.object,
};

export default ConfirmRedeemDialog;
