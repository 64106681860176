/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { flattenDeep, isFunction } from 'lodash-es';

const validators = {
  required: {
    regExp: /\S/,
    msg: 'Required field',
  },
  email: {
    regExp: /^$|^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    msg: 'Invalid Email',
  },
  username: {
    regExp: /^$|^[A-Za-z0-9_+\-.!#$'^`~@]{1,25}$/,
    msg: 'Invalid username',
  },
  password: {
    regExp: /(?=.*[!?/'`“”~|@#$%^&*()\\\-_=+{};:,<.>[\]])/,
    msg: 'Passwords must have at least one non alphanumeric character',
  },
  passwordLength: {
    regExp: /^$|^.{8,}$/,
    msg: 'The Password must be at least 8 characters long.',
  },
  phone: {
    regExp: /^$|^\+[0-9]{1,6}\s[0-9]{6,}$/,
    msg: 'Invalid phone number',
  },
  postCode: {
    regExp: /^$|^[0-9]{6}$/,
    msg: 'Invalid post code',
  },
  price: {
    regExp: /^$|^(?:[1-9][0-9]{0,})\.[0-9]{1,2}|[1-9][0-9]*$/,
    msg: 'Invalid number',
  },
  positiveInt: {
    regExp: /^$|^[1-9][0-9]*$/,
    msg: 'Invalid number',
  },
  validHex: {
    regExp: /^$|^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i,
    msg: 'Invalid HEX color',
  },
  googleMaps: {
    regExp: /^$|^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
    msg: 'Invalid latitude/longitude',
  },
  lowercase: {
    regExp: /[a-z]+/,
    msg: 'Must have at least one lowercase ("a"-"z")',
  },
  uppercase: {
    regExp: /[A-Z]+/,
    msg: 'Must have at least one uppercase ("A"-"Z")',
  },
  lettersNumbersPunctuation: {
    regExp: /^[a-zA-Z0-9-.,\s]*$/,
    msg: 'Must have at letters, numbers and punctuations',
  },
  lettersNumbersDash: {
    regExp: /^[a-zA-Z0-9\s-]*$/,
    msg: 'Must have at letters, numbers and dash',
  },
  onlyNumbers: { regExp: /^[0-9]*$/, msg: 'Must have only numbers' },
  creditCard: { regExp: /^[4|5]/, msg: 'Supports only Visa and MasterCard' },
  cvvcode: { regExp: /^[0-9]{0,3}$/, msg: 'Invalid cvv code' },
};

export default (...inputValidators) => (value) => {
  const setOfValidators = flattenDeep(inputValidators);

  for (const validator of setOfValidators) {
    const validatorName = validator?.name ?? validator;
    const render = validator?.render;
    const { regExp, msg } = validators[validatorName];
    // console.log(regExp, value);
    // console.log(`${value ?? ''}`);
    // console.log(regExp.test(`${value ?? ''}`));
    // Bypass null/undefined because the regExp api is stupid and if you pass it null/undefined it will convert them to string
    if (!regExp.test(`${value ?? ''}`)) {
      return {
        msg,
        ...(isFunction(render) && { render }),
      };
    }
  }

  return null;
};
