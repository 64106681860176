import { Image } from 'components/UI';
import { mainContainer } from './styles';
import image from 'assets/icons/alert-warning-input.svg';

const NotFound = () => (
  <div css={mainContainer}>
    <Image src={image} alt="404 page" width="600px" />
  </div>
);

export default NotFound;
