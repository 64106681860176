import { isNil } from 'lodash-es';
import { Skeleton } from 'components';
import { ReactComponent as BvIcn } from 'assets/images/icn-briefcase.svg';
import { ReactComponent as TokensIcn } from 'assets/images/icn-token.svg';
import { ReactComponent as SplitIcn } from 'assets/images/icn-split.svg';
import { ReactComponent as DifficultyIcn } from 'assets/images/icn-difficulty.svg';
import { ReactComponent as AllowedIcn } from 'assets/images/icn-checklist-checked.svg';
import { ReactComponent as NotAllowedIcn } from 'assets/images/icn-not-allowed.svg';
import { ReactComponent as BurnSolid } from 'assets/images/burn-solid.svg';

export const packageInfoDataCol = (packageData, config) => [
  {
    image: <BvIcn />,
    title: 'Business Volume',
    subtitle: 'What`s the Business volume',
    amount: packageData?.businessVolume,
  },
  {
    image: <TokensIcn />,
    title: 'Promotional Tokens',
    subtitle: 'How many Promotional Tokens you get?',
    amount: packageData?.tokens,
  },
  {
    image: <TokensIcn />,
    title: 'Academy Tokens',
    subtitle: 'How many Academy Tokens you get?',
    amount: packageData?.academyTokens ?? 0,
  },
  {
    image: <SplitIcn />,
    title: 'Splits',
    subtitle: 'The amount of splits for your Promotional Tokens',
    amount: packageData?.split,
  },
  {
    image: <SplitIcn />,
    title: 'Max Splits',
    subtitle: 'The amount of maximum splits for your Promotional Tokens',
    amount: packageData?.maxSplits ?? 0,
  },
  {
    image: <SplitIcn />,
    title: 'Promo Splits',
    subtitle: 'The amount of promo splits for your Promotional Tokens',
    amount: packageData?.promoSplits,
  },
  {
    image: <DifficultyIcn />,
    title: 'Difficulty rate',
    subtitle: 'Preferential difficulty rate',
    amount:
      !isNil(config) && !isNil(packageData?.difficulty) ? (
        config - packageData?.difficulty
      ) : (
        <Skeleton width={30} height={20} />
      ),
  },
  {
    image: <DifficultyIcn />,
    title: 'Exchange rate',
    subtitle: 'Preferential exchange rate',
    amount:
      !isNil(config) && !isNil(packageData?.exchangeReduction) ? (
        config - packageData?.exchangeReduction
      ) : (
        <Skeleton width={30} height={20} />
      ),
  },
  {
    image: <BurnSolid />,
    title: 'Gas',
    subtitle: 'What`s the Gas volume',
    amount: packageData?.gasAmount,
  },
];

export const packageInfoDataSecondCol = (packageData, hasMatchingBonus) => [
  {
    image: packageData?.hasNetworkBonus ? <AllowedIcn /> : <NotAllowedIcn />,
    title: 'Network',
    level: null,
    value: packageData?.hasNetworkBonus ? 'Yes' : 'No',
  },
  {
    image: hasMatchingBonus ? <AllowedIcn /> : <NotAllowedIcn />,
    title: 'Matching',
    level: null,
    value: hasMatchingBonus ? 'Yes' : 'No',
  },
  {
    image: null,
    title: null,
    level: '1st Level',
    value: packageData?.hasMatch1Bonus ? '10%' : 'N/A',
  },
  {
    image: null,
    title: null,
    level: '2nd Level',
    value: packageData?.hasMatch2Bonus ? '10%' : 'N/A',
  },
  {
    image: null,
    title: null,
    level: '3rd Level',
    value: packageData?.hasMatch3Bonus ? '20%' : 'N/A',
  },
  {
    image: null,
    title: null,
    level: '4th Level',
    value: packageData?.hasMatch4Bonus ? '25%' : 'N/A',
  },
];
