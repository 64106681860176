import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash-es';
import hash from 'object-hash';
import { Row, Col, Input, Dropdown } from 'components/UI';
import { filterOptions } from './config';
import { packageFilterContainer } from './styles';

const FilterBar = (props) => {
  const { items, setPackages } = props;
  const filterValuesRef = useRef({
    sort: filterOptions[0].value,
  });

  useEffect(() => {
    if (!items?.length) return;

    const {
      current: { sort, filter },
    } = filterValuesRef;

    sort && handleSortChange({ value: sort });
    filter && handleFilterChange(filter);
  }, [hash({ items })]);

  const handleSortChange = ({ value }) => {
    filterValuesRef.current.sort = value;
    setPackages(sortBy(items, value));
  };

  const handleFilterChange = (value) => {
    filterValuesRef.current.filter = value;
    setPackages(items.filter((el) => el.name.toLowerCase().includes(value?.toLowerCase())));
  };

  return (
    <div css={packageFilterContainer}>
      <Row gap={16} align="center" justify="space-between">
        <Col xs={12} sm="auto">
          <Input
            leftIcon={{ iconName: 'las la-search' }}
            placeholder="Search Modules"
            onChange={handleFilterChange}
            className="searchFilter"
          />
        </Col>
        <Col xs={12} sm="auto">
          <Dropdown
            noClear
            className="dropdown"
            value={filterOptions[0]}
            options={filterOptions}
            onChange={handleSortChange}
          />
        </Col>
      </Row>
    </div>
  );
};

FilterBar.propTypes = {
  items: PropTypes.array,
  setPackages: PropTypes.func,
};

export default FilterBar;
