import { useState } from 'react';
import {
  isNil,
  //  omit
} from 'lodash-es';
import {
  Button,
  CheckButton,
  Form,
  Row,
  Col,
  Input,
  inputValidation,
  useUser,
  //  showApiError,
  Paper,
} from 'components';
import { copyToClipboard, generateRandomPassword } from 'utils';
// import { createUser } from 'services';
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg';
import {
  container,
  actionButton,
  horizontalLine,
  titleStyles,
  textStyle,
  linkStyles,
  passwordText,
  passwordSubtitle,
  passwordColumn,
  paperStyles,
} from './styles';

const IdentityProfile = ({ history }) => {
  const { isAuthenticated, onRegistrationProgress } = useUser();
  const [password, setPassword] = useState(null);

  const handleGeneratePassword = () => setPassword(generateRandomPassword());

  const handleCopyPassword = (e) => {
    e.preventDefault();
    copyToClipboard(password, 'Successfully copied password.');
  };

  const handleCreateIdentityProfile = async () => {
    // const omittedData = omit(data, 'imaEnabled', 'newsletterEnabled', 'ageCompliant', 'confirmPrivacy');
    // console.log(omittedData);
    // const [, err] = await createUser(data);
    // if (err) showApiError(err);
    onRegistrationProgress('/register-user-profile');
    history.push('/register-user-profile');
  };

  return (
    <div css={container}>
      <div className="container">
        <p className="label">Step 1 of 3</p>
        <h2 className="title">Create your Profile</h2>
        <Paper
          header={
            <div css={paperStyles}>
              <h5 css={titleStyles}>Profile Details</h5>
              <Form onSubmit={handleCreateIdentityProfile} values={{ password }}>
                <Row sm={12} horizontalGap={16} verticalGap={8}>
                  <Col>
                    <Input
                      formId="email"
                      label="Email address"
                      required
                      validate={inputValidation('email', 'required')}
                    />
                  </Col>
                  {isAuthenticated && <p css={passwordText}>Password (Type of generate) * </p>}
                  {isAuthenticated && (
                    <p css={passwordSubtitle}>The password will be sent to the person with a link to change it</p>
                  )}
                  {isAuthenticated && (
                    <Col css={passwordColumn}>
                      <Input
                        disabled={isNil(password)}
                        required
                        formId="password"
                        placeholder="Password"
                        validate={inputValidation('required', 'passwordLength', 'lowercase', 'uppercase')}
                      />
                      <Button
                        type="default"
                        disabled={isNil(password)}
                        onClick={handleCopyPassword}
                        className="copy-button">
                        <CopyIcon className="copy-icon" />
                        Copy
                      </Button>
                      <Button type="secondary" onClick={handleGeneratePassword}>
                        Generate
                      </Button>
                    </Col>
                  )}
                  {!isAuthenticated && (
                    <Col>
                      <Input
                        required
                        type="password"
                        formId="password"
                        label="Password"
                        validate={inputValidation('required', 'passwordLength', 'lowercase', 'uppercase')}
                      />
                    </Col>
                  )}
                  {!isAuthenticated && (
                    <Col md={12}>
                      <Input
                        required
                        type="password"
                        formId="confirmPassword"
                        label="Confirm password"
                        validate={(value, allValues) =>
                          inputValidation('required')(value) ||
                          (value !== allValues.password && {
                            msg: 'Password and Confirm Password didn`t match.',
                          })
                        }
                      />
                    </Col>
                  )}
                  {!isAuthenticated && <h6 css={textStyle}>Please select applicable:</h6>}
                  {!isAuthenticated && (
                    <Col>
                      <CheckButton formId="imaEnabled">I want to become a registered IMA</CheckButton>
                    </Col>
                  )}
                  {!isAuthenticated && (
                    <Col>
                      <CheckButton formId="newsletterEnabled">
                        Send me updates, special offers and training by email
                      </CheckButton>
                    </Col>
                  )}
                  {!isAuthenticated && (
                    <Col>
                      <CheckButton formId="ageCompliant" required onError={inputValidation('required')}>
                        I am 18 years of age or older
                      </CheckButton>
                    </Col>
                  )}
                  {!isAuthenticated && (
                    <Col>
                      <div css={horizontalLine} />
                    </Col>
                  )}
                  {!isAuthenticated && (
                    <Col>
                      <CheckButton formId="confirmPrivacy" required onError={inputValidation('required')}>
                        <div>
                          I have read and agree to the
                          <a href="https://portal.oneecosystem.eu/terms" css={linkStyles}>
                            Terms & Conditions
                          </a>
                          and
                          <a href="https://portal.oneecosystem.eu/privacy-policy" css={linkStyles}>
                            Privacy Policy
                          </a>
                        </div>
                      </CheckButton>
                    </Col>
                  )}
                  <Col md={12}>
                    {({ onSubmit }) => (
                      <Button css={actionButton} onClick={onSubmit}>
                        Next
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default IdentityProfile;
