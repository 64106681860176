import PropTypes from 'prop-types';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { UserNavigation, useUser, AppNavigation, Button, useNavigationControls } from 'components';
import { ReactComponent as AddUserIcon } from 'assets/images/user-plus.svg';
import { navBarHeaderContainer, navBarLeftSideContainer, navBarRightSideContainer } from './styles';

const NavBar = () => {
  const { onRegistrationProgress } = useUser();
  const { allRoutes } = useNavigationControls();
  const location = useLocation();
  const matchedRoute = allRoutes.find((el) => matchPath(location.pathname, el.url));
  const history = useHistory();

  return (
    <header css={navBarHeaderContainer}>
      <div css={navBarLeftSideContainer}>
        <h5>{matchedRoute?.label}</h5>
      </div>

      <div css={navBarRightSideContainer}>
        <Button
          small
          type="link"
          onClick={() => {
            onRegistrationProgress('/register-identity-user');
            history.push('/register-identity-user');
          }}
        >
          <AddUserIcon className="icon" /> Add Member
        </Button>
        <AppNavigation />
        <UserNavigation />
      </div>
    </header>
  );
};

NavBar.propTypes = {
  logoLink: PropTypes.string,
};

export default NavBar;
