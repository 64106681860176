import { Route } from 'react-router-dom';
import { IdentityProfile, UserProfile, CreateAccount, SuccessRegistration, GuestFooter, GuestNavBar } from 'components';

const Registration = ({ history }) => {
  const onSuccessPage = history.location.pathname === '/register-success';

  return (
    <>
      {!onSuccessPage && <GuestNavBar />}
      <Route exact path="/register-identity-user" component={IdentityProfile} />
      <Route exact path="/register-user-profile" component={UserProfile} />
      <Route exact path="/register-new-account" component={CreateAccount} />
      <Route exact path="/register-success" component={SuccessRegistration} />
      {!onSuccessPage && <GuestFooter />}
    </>
  );
};

export default Registration;
