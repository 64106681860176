import { useState, useEffect } from 'react';
import { Row, Col, showError, NoResults } from 'components';
import { SubscriptionCard, LoadingSubscriptionCard } from 'components/screens/Subscriptions';
import { getMySubscriptions } from 'services';
import { useUserStore, useSubscriptions } from 'stores';
import starterLogo from 'assets/images/starter.png';
import { subscriptionsPageContainer } from './styles';

const Subscriptions = () => {
  const user = useUserStore();
  const currentAccountId = user?.currentAccount?.id;
  const allSubscriptions = useSubscriptions();
  const [subscriptions, setSubscriptions] = useState(allSubscriptions);

  useEffect(() => {
    fetchMySubscriptions();
  }, []);

  const fetchMySubscriptions = async () => {
    const [res, err] = await getMySubscriptions(currentAccountId);
    if (err) showError(err);
    setSubscriptions(
      res.map((el) => ({
        ...el,
        logo: el.picThumbnailUrl ?? el.pictureUrl ?? starterLogo,
      })),
    );
  };

  return (
    <div css={subscriptionsPageContainer}>
      <h3 className="title">My Subscriptions</h3>

      <Row gap={16}>
        {subscriptions ? (
          subscriptions.length ? (
            subscriptions.map((el, i) => (
              <Col xl={4} lg={4} sm={6} xs={12} key={`${el.subscriptionCatalogueItemId}-${i}`}>
                <SubscriptionCard item={el} />
              </Col>
            ))
          ) : (
            <Col xs={12}>
              <NoResults emptyMessage="There are no subscriptions from this search" />
            </Col>
          )
        ) : (
          <LoadingSubscriptionCard items={3} />
        )}
      </Row>
    </div>
  );
};

export default Subscriptions;
