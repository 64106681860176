import { ColorBatch } from 'components/UI';
import { dateTime } from 'components/screens';

export const withdrawalMethodsColumnsDefinition = [
  {
    name: 'Created Date',
    render: dateTime('createdOn'),
    width: '160px',
  },
  {
    name: 'Method Type',
    value: 'type',
    width: '110px',
  },
  {
    name: 'Previous Address',
    render: ({ previousWithdrawalMethodValue, NAItem }) =>
      JSON.parse(previousWithdrawalMethodValue)?.addressNumber ?? NAItem,
  },
  {
    name: 'Current Address',
    render: ({ value, NAItem }) => JSON.parse(value)?.addressNumber ?? NAItem,
  },
  {
    name: 'Active',
    render: (row) => <ColorBatch type={row.active ? 'green' : 'red'}>{row.active ? 'Yes' : 'No'}</ColorBatch>,
    width: '70px',
  },
];
