export const countryContainer = {
  display: 'flex',
  padding: 8,
};

export const countryListLabel = {
  marginLeft: 12,
};

export const countryImage = {
  marginRight: 12,
  width: 20,
  height: 20,
};

export const statusVerified = (isVerified) => (theme) => ({
  width: 'max-content',
  borderRadius: 16,
  fontSize: 15,
  fontWeight: 500,
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
  color: isVerified ? theme.successDark : theme.errorDark,
  backgroundColor: isVerified ? theme.successLight : theme.errorLight,

  '& > svg': {
    marginRight: 8,
    width: isVerified ? 18 : 16,
  },
});

export const uploadedDocumentsBoxContainer = (status) => (theme) => {
  const colorMap = {
    approved: theme.success,
    pending: theme.infoDark,
    rejected: theme.error,
  };

  return {
    '.container': {
      marginBottom: 32,

      '.image': {
        maxWidth: '50%',
        maxHeight: 150,
        marginBottom: 24,
      },

      '.info': {
        color: theme.gray400,

        '& > b': {
          marginRight: 8,
        },
      },

      '.commentsContainer': {
        color: theme.gray200,
        fontSize: 12,
        lineHeight: 1.75,
      },

      '.comment': {
        color: colorMap[status],
      },
    },
  };
};

export const documentStatus = (status) => (theme) => {
  const statusMap = {
    approved: {
      color: theme.success,
      backgroundColor: theme.successLight,
    },
    pending: {
      color: theme.infoDark,
      backgroundColor: theme.infoLight,
    },
    rejected: {
      color: theme.error,
      backgroundColor: theme.errorLight,
    },
  };

  return {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
    padding: '8px 12px',
    marginBottom: 12,
    ...statusMap[status],
    width: 'max-content',

    '& > svg': {
      marginRight: 8,
      width: 17,
      height: 17,
    },

    '.statusName': {
      textTransform: 'capitalize',
      fontSize: 15,
      fontWeight: 600,
      lineHeight: 1.4,
    },
  };
};

export const modalContainer = {
  width: '100%',
  maxWidth: 1000,
};

export const uploadModalBody = (hasWarningMassage) => (theme) => ({
  padding: '24px',

  ...(hasWarningMassage && {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),

  '.container ': {
    marginBottom: 48,
    display: 'flex',
    flexDirection: 'row',

    '& > h4': {
      marginBottom: 16,
    },

    '& > p': {
      color: theme.gray300,
    },

    '.image': {
      marginRight: 24,
      '& > svg': {
        width: 150,
        height: 150,
      },
    },
  },
});

export const modalWarningWrapper = (theme) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '.warningContent': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.warningTitle': {
      marginBottom: 32,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& > svg': {
        width: 180,
        height: 180,
        marginBottom: 24,
      },

      '.boldTextSuccess': {
        color: theme.success,
        fontSize: 32,
      },
    },

    '.warningMessage': {
      color: theme.gray300,
      marginBottom: 32,

      '.boldTextError': {
        color: theme.error,
        fontSize: 16,
      },
    },
  },

  '.warningButtonsContainer': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const verificationBox = (theme) => ({
  padding: 40,
  border: `1px solid ${theme.whiteGray}`,
  boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.14)',
  position: 'relative',
  cursor: 'pointer',
  marginBottom: 24,

  '.icon': {
    width: 100,
    height: 100,
  },

  '.titleContainer': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,

    '& > h4': {
      marginRight: 12,
    },

    '& > span': {
      color: theme.gray300,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  },

  '.description': {
    display: 'flex',
    alignItems: ' flex-start',

    '& > p': {
      marginRight: 12,
      color: theme.gray400,
      marginBottom: 16,
    },
  },

  '.payInfo': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > h4': {
      marginRight: 16,
    },

    '& > span': {
      color: theme.gray300,
      textTransform: 'capitalize',
      fontWeight: 400,
      fontStyle: 'italic',
    },
  },

  '.desc': {
    marginRight: 12,
    color: theme.gray400,
    marginBottom: 16,
  },

  '.noteInfo': {
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: theme.gray800,
    fontSize: 12,
    marginBottom: 8,
  },
});

export const recommended = (theme) => ({
  border: `1px solid ${theme.success}`,
  position: 'relative',
  '&:before': {
    content: '"Recommended"',
    color: theme.white,
    fontSize: 10,
    backgroundColor: theme.success,
    padding: '4px 8px',
    position: 'absolute',
    top: -12,
    left: 32,
    textTransform: 'uppercase',
  },
});

export const informationSection = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 48,

  '& > div': {
    display: 'flex',
    alignItems: 'center',

    '& > h3': {
      marginRight: 24,
    },

    '.image': {
      width: '100%',
      maxWidth: 250,
      height: 'auto',
      marginRight: 16,
    },
  },

  '& > p': {
    fontSize: 12,
    color: theme.gray400,
    lineHeight: 1.5,
  },
});

export const expMassage = (theme) => ({
  color: theme.gray300,
  fontSize: 10,
  fontStyle: 'italic',
});

export const statusIndicatorContainer = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  '.statusIndicatorContent': {
    textAlign: 'center',
    padding: 40,

    '& > h4': {
      fontSize: 28,
      fontWeight: 600,
      marginBottom: 8,
    },

    '& > p': {
      fontSize: 14,
    },

    '.icon': {
      width: 136,
      marginBottom: 24,
      padding: 48,
      backgroundImage: `linear-gradient(to bottom, ${theme.white}, #edeff8)`,
      borderRadius: '50%',
      display: 'inline-flex',
    },
  },
});

export const uploadDocsModalContainer = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 1200,
  padding: 48,

  '.title': {
    marginBottom: 32,
    paddingBottom: 24,
    borderBottom: `1px solid ${theme.gray100}`,
  },

  '.modalBodyLarge': {
    padding: '12px 32px',

    '.spanStyle': {
      fontFamily: 'arial, helvetica, sans-serif',
      fontSize: '12pt',
      color: theme.gray600,
    },

    '.redText': {
      color: theme.error,
      fontWeight: 600,
    },

    '.blackText': {
      color: theme.error,
      fontWeight: 600,
    },

    '.underlineText': {
      textDecoration: 'underline',
      color: theme.error,
      fontWeight: 600,
    },

    '.size': {
      fontSize: '14pt',
    },

    '.font': {
      fontFamily: 'arial, helvetica, sans-serif',
    },

    '.leftAlign': {
      textAlign: 'left',
    },

    '.table': {
      width: 960,
    },

    '.underlineWithoutColor': {
      textDecoration: 'underline',
      fontWeight: 600,
    },

    '.decorationFontSize': {
      textDecoration: 'underline',
      fontFamily: 'arial, helvetica, sans-serif',
      fontSize: '12pt',
    },
  },
});
