import qs from 'query-string';
import { isArray } from 'lodash-es';
import { postReq, getReq, putReq } from './axios/makeRequest';
import { myProfileModel, getWalletForCurrentAccount, myKycModel } from './models';

const baseUrl = `${apiUrls.onelife}/profile`;

export const createProfile = (data) => postReq(`${baseUrl}/new`, data);

export const getMyProfile = async () => {
  const [data, err] = await getReq(`${baseUrl}/v2/my`);
  return [data && myProfileModel(data), err];
};

export const editMyProfile = (data) => putReq(`${baseUrl}/update`, data);

export const createAccount = (data) => postReq(`${baseUrl}/accounts`, data);

export const switchAccount = (id) => putReq(`${baseUrl}/setcurrent`, { id });

export const getCurrentAccountWallets = async () => {
  const [res, err] = await getReq(`${baseUrl}/v2/currentAccount`);
  return [getWalletForCurrentAccount(res ?? {}), err];
};

export const getAllNicknames = (data) => {
  const params = qs.stringify({ Nickname: data });
  return getReq(`${baseUrl}/accounts/getAccountsByNickname?${params}`);
};

export const getMyKyc = async () => {
  const [res, err] = await getReq(`${baseUrl}/kyc/my`);
  return [myKycModel(res), err];
};

export const downloadKycImage = (fileName) =>
  postReq(`${baseUrl}/kyc/download`, { fileName }, { responseType: 'blob' });

export const createKyc = (data) => {
  const form = new FormData();

  const appendParams = (key, value) => {
    const keysWithProfilePrefix = [
      'country',
      'dateOfBirth',
      'address',
      'zip',
      'countryOfBirth',
      'city',
      'cityOfBirth',
      'firstName',
      'lastName',
    ];
    form.append(keysWithProfilePrefix.indexOf(key) !== -1 ? `profile.${key}` : key, value);
  };

  Object.keys(data).forEach((key) => {
    if (isArray(data[key])) {
      data[key].forEach((el) => {
        appendParams(key, el);
      });
    } else appendParams(key, data[key]);
  });

  // All VIP documents should have vipFlowType set to manual
  data.isVip && form.append('vipFlowType', 'manual');

  return postReq(`${baseUrl}/kyc/upload`, form, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const changeAccountPlacement = (data) => putReq(`${baseUrl}/accounts`, data);

export const emailInvitation = (data) => postReq(`${baseUrl}/account/invite`, data);

export const updateSecurityPin = (data) => putReq(`${baseUrl}/MobileAccess/update`, data);

export const getMyMobileSettings = () => getReq(`${baseUrl}/MobileAccess/my`);

export const getAccountsList = ({ pageIndex, pageSize }) => {
  const params = qs.stringify({ pageIndex, pageSize }, { arrayFormat: 'index' });
  return getReq(`${baseUrl}/accounts/list?${params}`);
};
