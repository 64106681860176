export const tableContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.white,
  borderRadius: '0.5em',
  padding: '1.15em',
});

export const filterBarContainer = {
  position: 'relative',
};

export const tableHead = (theme) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  padding: '0 1.5em',
  backgroundColor: theme.gray800,
  borderRadius: 25,
});

export const tableRow = (hasOnClick, selectRow) => (theme) => [
  {
    display: 'flex',
    alignItems: 'center',
    color: theme.textLightPrimary,
    transition: 'background-color .3s ease-in-out',
    borderBottom: `1px solid ${theme.gray100}`,
    padding: '0 1.5em',

    '&:last-of-type': {
      borderBottom: 'none',
    },

    '&:hover': {
      backgroundColor: theme.gray100,
    },
  },
  hasOnClick && {
    cursor: 'pointer',
  },
  selectRow && {
    backgroundColor: theme.gray100,
    '&:hover': {
      backgroundColor: theme.gray100,
    },
  },
];

export const tableCell = ({ width, flex, sm, md, lg, xl }) => [
  {
    flex: flex ?? (width ? `0 0 ${width}` : '1 0 60px'),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1.5em 0.7em',
    outline: 'none',
    wordBreak: 'break-word',
  },
  sm && {
    '@media (max-width: 575px)': {
      display: 'none',
    },
  },
  md && {
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  lg && {
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  xl && {
    '@media (max-width: 1199px)': {
      display: 'none',
    },
  },
];

export const tableHeadCell = (options) => (theme) => [
  ...tableCell(options),
  {
    color: theme.white,
    padding: '1em 0.7em',
  },
  options?.sortable && {
    cursor: 'default',
    '& b': {
      cursor: 'pointer',
    },
  },
];

export const tableHeadIcon = (isAsc, isDesc) => ({
  fontSize: 18,
  opacity: isAsc || isDesc ? 1 : 0,
  transform: `rotate(${isDesc ? 0 : 180}deg)`,
  transition: 'transform 300ms',
});

export const tableCellContent = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '& span': {
    display: 'inline',
  },
};

export const tableCellEmpty = (theme) => ({
  width: 32,
  height: 4,
  backgroundColor: theme.gray100,
  borderRadius: 12,
});

export const paginationContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
};

export const shownResultsIndicator = (theme) => ({
  color: theme.gray200,
  width: 80,
  textAlign: 'center',
  fontSize: 11,
  marginRight: 8,
});

export const paginationButton = (disabled, isActive, small) => (theme) => [
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    width: small ? 20 : 28,
    height: small ? 20 : 28,
    margin: '0 0.6em',
    color: theme.gray200,
    border: '1px solid transparent',
    borderRadius: '50%',

    '&:hover': {
      color: theme.gray700,
      backgroundColor: theme.gray100,
    },
  },
  disabled && {
    cursor: 'not-allowed',
    color: theme.gray200,

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.gray200,
    },
  },
  isActive && {
    borderColor: theme.gray700,
    color: theme.gray700,

    '&:hover': {
      color: theme.gray700,
    },
  },
];

export const tableMessageContainer = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8rem 3rem',
  flexDirection: 'column',

  '& > .table-message-icon': {
    marginBottom: '2.2rem',
  },

  '& > .table-message-title': {
    fontWeight: 500,
    marginBottom: 12,
  },

  '& > .table-message-subtitle': {
    color: theme.gray200,
  },
});

export const pageSizeDropdown = {
  width: 120,
  marginLeft: 14,
};
