import { commonStyles } from '../styles';

export const paperContainer = (props) => (theme) => {
  const { noPadding } = props;

  return [
    {
      backgroundColor: theme.white,
      boxShadow: theme.boxShadow,
      borderRadius: 8,
      padding: noPadding ? 0 : '2.5em',
      position: 'relative',
    },
    ...commonStyles(props),
  ];
};

export const paperContentContainer = ({ autoHeigh }) => ({
  height: autoHeigh ? 'auto' : '100%',
});

export const paperHeaderContainer = ({ noPadding }) => [
  {
    marginBottom: '1.5em',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  noPadding && {
    padding: '1.25em',
  },
];
