export const inputField = {
  display: 'none',
};

export const uploadWrap = (hasValidation, { multiple, disabled, small }) => (theme) => [
  {
    color: theme.gray700,
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    marginBottom: hasValidation ? 6 : 0,
    mimHeight: small ? '3.6rem' : '4.4rem',
    padding: multiple ? 0 : '0.8em 1em',
    background: 'transparent',
    border: multiple ? 'none' : `1px solid ${theme.gray100}`,

    '& img': {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: '4.4rem',
    },

    '.button': {
      color: theme.infoDark,
    },
  },
  disabled && {
    borderColor: theme.gray100,
    color: theme.gray100,
    cursor: 'not-allowed',
  },
];

export const itemImageWrap = (theme) => ({
  backgroundColor: theme.white,
  padding: '0.4em',
  borderRadius: 6,
});

export const fileInputContent = ({ multiple }) => (theme) => ({
  width: '100%',
  padding: multiple ? '0.8em 1em' : 0,
  borderRadius: multiple ? 6 : 0,
  background: 'transparent',
  border: multiple ? `1px solid ${theme.gray100}` : 'none',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  marginTop: multiple ? 8 : 0,
  flexDirection: 'row-reverse',
});

export const fileInputLabel = ({ small }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  padding: small ? '0.35em 0.85em' : '0.7em 0.85em',
  textOverflow: 'ellipsis',
});
