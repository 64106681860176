export const navBarHeaderContainer = (theme) => ({
  backgroundColor: theme.whiteGrayWhite,
  borderBottom: `1px solid ${theme.gray50}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1em 2em',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
});

export const navBarLeftSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  whiteSpace: 'nowrap',

  '& > *': {
    margin: '0 2rem 0 0',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
};

export const navBarRightSideContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',

  '& > *': {
    marginRight: 6,
    cursor: 'pointer',

    '&:last-child': {
      marginRight: 0,
    },
  },

  '.icon': {
    width: 20,
    height: 20,
    marginRight: 4,
  },
};
