import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { showApiError, Button } from 'components';
import {
  ProfileKycDocument,
  ProfileUploadDocsModal,
  ProfileKycInformation,
  ProfileStatusLabel,
} from 'components/screens/KycVerification';
import { initializeUser } from 'stores';
import { KYCType } from 'enums';
import { getMyKyc } from 'services';
import { ReactComponent as IdPassIcn } from 'assets/images/icn-id-wt-bg.svg';
import {
  expireCommentContainer,
  kycContainer,
  kycTitle,
  uploadedDocumentsContainer,
  noDocumentsContainer,
} from './styles';

const compareDocsCreation = (doc1, doc2, type) => {
  const isSameType = doc2?.type?.toLowerCase() === type.toLowerCase();
  if (!doc1) return isSameType ? doc2 : doc1;

  return isSameType && moment(doc2.createdOn).isAfter(moment(doc1.createdOn)) ? doc2 : doc1;
};

const KycVerification = () => {
  const [uploadDocumentType, setUploadDocumentType] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState(null);
  const [expiredComment, setExpiredComment] = useState(null);
  const uploadDocsModalRef = useRef(false);
  const infoRef = useRef(false);
  // console.log(infoRef);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    const [data, err] = await getMyKyc();

    if (err) return showApiError(err);
    if (!data?.items?.length) return;

    let idProof = null;
    let address = null;
    const all = [];
    let expComment = null;

    data.items.forEach((el) => {
      all.push(el);
      idProof = compareDocsCreation(idProof, el, KYCType.idProof);
      address = compareDocsCreation(address, el, KYCType.addressProof);
      expComment =
        !expComment &&
        el.state === 'Approved' &&
        moment(el.expirationDate).isBetween(moment(), moment().add(31, 'days'), 'day') &&
        el.type;
    });

    setUploadedDocuments({ idProof, addressProof: address, all });
    setExpiredComment(expComment);
  };

  const handleStartVerification = () => {
    setUploadDocumentType([KYCType.idProof, KYCType.addressProof]);
    uploadDocsModalRef.current.open();
  };

  const handleUploadNewDocument = () => {
    // Call initializeUser to fetch new user data with new status
    initializeUser();
    fetchDocuments();
  };

  const openModal = (docType) => {
    setUploadDocumentType([docType]);
    uploadDocsModalRef.current.open();
  };

  const { idProof, addressProof } = uploadedDocuments ?? {};

  return (
    <div css={kycContainer}>
      {expiredComment && (
        <div css={expireCommentContainer}>
          <h4>{expiredComment} document is expired, please upload the renewed copy of your document.</h4>
        </div>
      )}

      <div className="titleContainer">
        <div css={kycTitle}>
          <h3>Verify Your Identity</h3>
          <ProfileStatusLabel />
        </div>

        <div>
          Before submitting please read the following &nbsp;
          <Button type="link" small className="linkText" onClick={() => infoRef.current?.open()}>
            KYC Process Information
          </Button>
        </div>
      </div>

      {uploadedDocuments ? (
        <div css={uploadedDocumentsContainer}>
          <div className="uploadedDocumentWrapper">
            <div className="uploadedDocumentBox">
              <h4>ID / Passport Documents</h4>
              <p>
                Enables quick and secure enrolment of customers with high accuracy and complete identity fraud
                protection.
              </p>
            </div>
            <ProfileKycDocument document={idProof} onClick={() => openModal(KYCType.idProof)} />
          </div>
          <div className="uploadedDocumentWrapper">
            <div className="uploadedDocumentBox">
              <h4>Proof of Address Documents</h4>
              <p>Must be issued within the last 3 months and should state the name & address clearly.</p>
            </div>
            <ProfileKycDocument document={addressProof} onClick={() => openModal(KYCType.addressProof)} />
          </div>
        </div>
      ) : (
        <div css={noDocumentsContainer}>
          <IdPassIcn className="noDocumentsImage" />
          <h4>Hey! Your profile is not verified yet.</h4>
          <Button onClick={handleStartVerification}>Start the Verification Now</Button>
        </div>
      )}

      <ProfileUploadDocsModal
        ref={uploadDocsModalRef}
        documentType={uploadDocumentType}
        nonPendingDocumentTypes={[
          idProof?.state !== 'Pending' && KYCType.idProof,
          addressProof?.state !== 'Pending' && KYCType.addressProof,
        ].filter(Boolean)}
        onUploadDocs={handleUploadNewDocument}
      />

      <ProfileKycInformation ref={infoRef} />
    </div>
  );
};

export default KycVerification;
