import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toNumber } from 'lodash-es';
import { Row, Col, Form, Button, Input, showApiError, showSuccess, inputValidation, Paper } from 'components/UI';
import { emailInvitation, getConfigParam } from 'services';
import { inviteContainer } from './styles';

const positiveDoublePattern = (val) => /^\d{0,2}(\.\d{0,2}){0,1}$/.test(val);

const SendInvitationForm = (props) => {
  const { currentAccountId } = props;
  const [maxCoins, setMaxCoins] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [res, err] = await getConfigParam('CoinInvitations', 'json');
    err ? showApiError(err) : setMaxCoins(res?.maxCoins);
  };

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      accountId: currentAccountId,
    };

    const [, err] = await emailInvitation(data);

    if (err) return showApiError(err);
    showSuccess('You have successfully send invitation.');
    history.push('/overview');
  };

  return (
    <Paper css={inviteContainer}>
      <h5 className="title">Send Personalized Invite Message</h5>
      <Form onSubmit={handleSubmit}>
        <Row gap={8}>
          <Col xs={12} lg={6}>
            <Input
              required
              formId="recipientEmail"
              label="Email address"
              validate={inputValidation('email', 'required')}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Input
              required
              number
              formId="giftCoinsAmount"
              label="Amount of Coins"
              rightIcon={<p className="coinText">COIN</p>}
              placeholder="0.0"
              pattern={positiveDoublePattern}
              validate={(value) =>
                inputValidation('required')(value) ||
                inputValidation('positiveInt')(value) ||
                (value > toNumber(maxCoins) && {
                  msg: `Max coins to send is € ${toNumber(maxCoins)}`,
                }) ||
                (value <= 0 && {
                  msg: 'The amount of coins must be larger of 0!',
                })
              }
            />
          </Col>
          <Col xs={12}>
            <Input
              required
              type="text"
              label="Subject Line"
              formId="emailSubject"
              validate={inputValidation('lettersNumbersPunctuation', 'required')}
            />
          </Col>
          <Col xs={12}>
            <Input
              required
              rows={10}
              type="textarea"
              label="Email Text"
              formId="emailBody"
              validate={inputValidation('required')}
            />
          </Col>
          <Col md={12}>{({ onSubmit }) => <Button onClick={onSubmit}> Send Message</Button>}</Col>
        </Row>
      </Form>
    </Paper>
  );
};

SendInvitationForm.propTypes = {
  currentAccountId: PropTypes.number,
};

export default SendInvitationForm;
