import PropTypes from 'prop-types';
import { startCase } from 'lodash-es';
import { colorStatus } from './styles';

const ColorBatch = (props) => {
  const { children, withoutStartCase, className } = props;

  return (
    <span css={colorStatus(props)} {...(className && { className })}>
      {withoutStartCase ? children : startCase(children)}
    </span>
  );
};

ColorBatch.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  withoutStartCase: PropTypes.bool,
  className: PropTypes.string,
};

export default ColorBatch;
