import PropTypes from 'prop-types';
import { Row, Col } from 'components/UI';
import { SubscriptionItem, PaymentMethodsCard } from 'components/screens/Subscriptions';
import { forexDurationPrices } from './config';
import { oneForexContainer } from './styles';

const OneForexSubscriptionPage = (props) => {
  const { subscriptions = [], paymentMethods } = props;

  return (
    <div css={oneForexContainer}>
      {subscriptions.map((catItem) => (
        <Row gap={32} key={`${catItem.systemName}${catItem.id}`} align="flex-start">
          <Col sm={12} md={7} lg={7}>
            <Row gap={32} className="rowItem">
              {catItem.subscriptionPlans.map((plan, index) => (
                <Col xs={12} sm={4} key={`${plan.vendor}-${plan.name}-${index}`} className="subscriptionContainerItem">
                  <SubscriptionItem item={plan} />
                </Col>
              ))}
              <Col xs={12} sm={8} className="subscriptionContainerItem">
                <PaymentMethodsCard paymentMethods={paymentMethods} catalogueItemId={catItem.id} />
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={5} lg={5} className="upcomingSubscriptionsColumn">
            <div className="upcomingSubscriptions">
              <div className="labelContainer">
                <p className="label">In 3 months</p>
              </div>
              <Row gap={24}>
                {forexDurationPrices.map((el, ind) => (
                  <Col key={ind} xs={12} sm={6} md={4} lg={4} className="columnInfo">
                    <h3 className="price">{`€${el.price}`}</h3>
                    <p className="duration">{`/ ${el.duration}`}</p>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

OneForexSubscriptionPage.propTypes = {
  subscriptions: PropTypes.array,
  paymentMethods: PropTypes.object,
};

export default OneForexSubscriptionPage;
