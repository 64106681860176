import { getReq, postReq, putReq } from './axios/makeRequest';
import { myPaymentMethodsModel, updatePaymentMethodsModel } from './models';

const baseUrl = `${apiUrls.onelife}/wallets/withdrawals/methods`;

export const myPaymentMethod = async () => {
  const [data, err] = await getReq(`${baseUrl}/my`, {
    params: { pageSize: 50, pageIndex: 0, activeOnly: true },
  });

  return [data && myPaymentMethodsModel(data), err];
};

export const newPaymentMethod = (type, value) => postReq(`${baseUrl}/new`, updatePaymentMethodsModel(type, value));

export const upgradePaymentMethod = (type, value) =>
  putReq(`${baseUrl}/update`, updatePaymentMethodsModel(type, value));

export const myPaymentMethodsHistory = (params) => getReq(`${baseUrl}/my`, { params });
