import PropTypes from 'prop-types';
import { Paper, Button, Form, Row, Col, Input, inputValidation, showApiError, showSuccess } from 'components/UI';
import { MaskText } from 'components';
import { paymentMethodTypes } from 'enums';
import { newPaymentMethod, upgradePaymentMethod } from 'services';
import { headerContainer, rowContainer } from './styles';

const { Bitcoin } = paymentMethodTypes;

const BitcoinPaymentCard = (props) => {
  const { edit, editBitcoin, paymentMethods, handleEdit, tableRef } = props;

  const handleSubmit = async (values) => {
    const isEdit = paymentMethods?.[Bitcoin];

    const [, err] = isEdit ? await upgradePaymentMethod(Bitcoin, values) : await newPaymentMethod(Bitcoin, values);

    if (err) return showApiError(err);

    // All success add/update payment method
    showSuccess(`You have successfully ${isEdit ? 'updated' : 'add'} payment method.`);

    handleEdit((prev) => ({
      editBitcoin: false,
      paymentMethods: {
        ...prev.paymentMethods,
        [Bitcoin]: values,
      },
    }));

    tableRef.current.getData();
  };

  return (
    <Paper
      header={() => (
        <div css={headerContainer}>
          <h5>Bitcoin</h5>
          {!edit?.editBitcoin && (
            <Button type="link" small onClick={editBitcoin} className="edit-button">
              Edit
            </Button>
          )}
        </div>
      )}
    >
      <Form
        values={{
          addressNumber: paymentMethods?.[Bitcoin]?.addressNumber ?? '',
        }}
        onSubmit={handleSubmit}
      >
        <Row gap={4} css={rowContainer}>
          <Col sm={6}>
            <p className="label">Address Number</p>
          </Col>
          <Col sm={6}>
            {edit?.editBitcoin ? (
              <Input
                required
                formId="addressNumber"
                validate={inputValidation('required', 'lettersNumbersPunctuation')}
              />
            ) : (
              <MaskText value={paymentMethods?.[Bitcoin]?.addressNumber} type="accountNumber" />
            )}
          </Col>
          {edit?.editBitcoin && (
            <Col displayFlex md={12} justify="flex-end">
              {({ onSubmit }) => (
                <>
                  <Button small type="link" onClick={editBitcoin} margin="0 1em 0 0">
                    Cancel
                  </Button>
                  <Button small onClick={onSubmit}>
                    Save
                  </Button>
                </>
              )}
            </Col>
          )}
        </Row>
      </Form>
    </Paper>
  );
};

BitcoinPaymentCard.propTypes = {
  edit: PropTypes.object,
  editBitcoin: PropTypes.func,
  paymentMethods: PropTypes.object,
  handleEdit: PropTypes.func,
  tableRef: PropTypes.object,
};

export default BitcoinPaymentCard;
