import PropTypes from 'prop-types';
import { Row, Col, Button, Input, Tooltip, Paper } from 'components/UI';
import { copyToClipboard } from 'utils';
import { ReactComponent as QuestionIcn } from 'assets/images/icn-question-gray.svg';
import { ReactComponent as LinkIcon } from 'assets/images/link.svg';
import { spaceContainer, secondaryContainer, inputContainer, title, flexContainer } from './styles';

const InvitationCards = (props) => {
  const { currentAccountId } = props;
  const inviteLink = `${window.location.origin}/register-identity-user?sponsorAccountId=${currentAccountId}`;

  const handleCopyLink = () => {
    copyToClipboard(inviteLink, 'Successfully copied your invite link.');
  };

  return (
    <div>
      <div css={secondaryContainer}>
        <div className="centerContainer">
          <div css={spaceContainer}>
            <h5>Invite to join or Sign up</h5>
            <Tooltip content="Add your new members" placement="right" backgroundColor="gray100" color="black">
              <QuestionIcn className="icon" />
            </Tooltip>
          </div>
          <p className="title">Signup Members & Merchants manually</p>
        </div>
        <Row gap={16}>
          <Col displayFlex md={12} lg={6}>
            <Button small linkTo="/register-identity-user">
              <span>Member Sign-up</span>
            </Button>
          </Col>
          <Col displayFlex md={12} lg={6}>
            <Button small linkTo="/register-identity-user">
              <span>Merchant Sign-up</span>
            </Button>
          </Col>
        </Row>
      </div>

      <Paper header="Signup links">
        <div>
          <p css={title}>For members</p>
          <Input
            css={inputContainer}
            disabled
            value={inviteLink}
            rightIcon={
              <div css={flexContainer}>
                <LinkIcon className="icon" onClick={handleCopyLink} />
                <div role="button" tabIndex={0} onClick={handleCopyLink}>
                  copy
                </div>
              </div>
            }
          />
        </div>
        <div>
          <p css={title}>For merchants</p>
          <Input
            css={inputContainer}
            disabled
            value={inviteLink}
            rightIcon={
              <div css={flexContainer}>
                <LinkIcon
                  className="icon"
                  onClick={() => copyToClipboard(inviteLink, 'Successfully copied your Public Profile.')}
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => copyToClipboard(inviteLink, 'Successfully copied your Public Profile.')}
                >
                  copy
                </div>
              </div>
            }
          />
        </div>
      </Paper>
    </div>
  );
};

InvitationCards.propTypes = {
  currentAccountId: PropTypes.number,
};

export default InvitationCards;
