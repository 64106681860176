export const actionButton = {
  width: '100%',
};

export const horizontalLine = (theme) => ({
  borderBottom: `1px solid ${theme.gray100}`,
});

export const titleStyles = {
  marginBottom: 24,
};

export const textStyle = {
  marginBottom: 0,
  padding: '0 16px',
};

export const linkStyles = (theme) => ({
  margin: '0 8px',
  color: theme.secondaryDark,
});

export const countryContainer = {
  display: 'flex',
  padding: 8,
};

export const countryListLabel = {
  marginLeft: 12,
};

export const countryImage = {
  marginRight: 12,
  width: 20,
  height: 20,
};

export const passwordText = (theme) => ({
  color: theme.gray,
  padding: '0 16px',
  fontSize: 13,
  marginBottom: 8,
});

export const passwordSubtitle = (theme) => ({
  fontSize: 12,
  color: theme.gray,
  padding: '0 16px',
  fontWeight: 400,
});

export const passwordColumn = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',

  '& .copy-button': {
    color: theme.secondaryDark,
    fontWeight: 400,
    marginRight: 8,

    '& > svg': {
      width: 20,
      height: 20,
      marginRight: 8,
    },
  },
});

export const columnContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 16px',

  '& .title': {
    marginBottom: 8,
  },

  '& .subtitle': {
    color: theme.gray,
    fontWeight: 400,
    fontSize: 13,
    marginBottom: 8,
  },
});

export const successContainer = (theme) => ({
  backgroundColor: theme.gray800,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.white,

  '& .content-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .logo': {
      marginTop: 32,
    },
  },

  '& .success-icon': {
    width: 200,
    height: 200,
    marginTop: 100,
    marginBottom: 24,
  },

  '& .text': {
    width: 500,
    marginTop: 24,
    marginBottom: 24,
  },

  '& .footer': {
    color: theme.gray,
  },
});

export const container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: 'auto',
  padding: 48,

  '& .container': {
    maxWidth: 600,
    minWidth: 350,

    '& .tick-icon-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 48,

      '& .tick-icon': {
        width: 35,
        height: 35,
        marginRight: 16,
      },
    },
  },

  '& .label': {
    fontSize: 10,
  },

  '& .title': {
    marginBottom: 16,
  },
};

export const paperStyles = {
  marginBottom: 0,
};
