export const navBarHeaderContainer = (theme) => ({
  backgroundColor: theme.gray800,
  borderBottom: `1px solid ${theme.gray50}`,
  color: theme.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1em 2em',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
});

export const navBarRightSideContainer = (theme) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',

  '& > *': {
    marginRight: 4,
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },

  '& .nickname-styles': {
    marginRight: 12,
  },

  '& .action-button': {
    color: theme.white,

    '&: hover': {
      borderBottomColor: theme.white,
    },
  },
});
