import moment from 'moment';

export const defaultDate = '0001-01-01T00:00:00Z';

export const validDate = (date) => (date === defaultDate ? null : date);

export const endOfTheDay = (inputDate) => inputDate.hours(23).minutes(59).seconds(59);

export const timeToHm = (time) => {
  const splitTime = time.split(':');
  if (splitTime.length === 3) return `${splitTime[0]}:${splitTime[1]}`;
  if (splitTime.length === 1) return `${splitTime[0]}:00`;
  return splitTime;
};

export const validUntil = (fromDate, duration) => moment(fromDate).add(duration, 'months').toString();

export const pluralizeMonths = (duration) => {
  let stringLabel = 'months';
  if (duration === 1) stringLabel = 'month';

  if (duration % 12 === 0) stringLabel = duration / 12 === 1 ? 'year' : 'years';

  return stringLabel;
};
