import { commonStyles } from '../styles';

export default {
  primary: '#dd2b29',
  primaryLight: '#ed4a49',
  primaryDark: '#9c2523',
  secondary: '#CCCEFF',
  secondaryLight: '#dae0f7',
  secondaryDark: '#5371d5',
  secondaryDarker: 'rgba(83, 113, 213, .1)',
  gray: '#5f636f',
  whiteGray: '#e9eaf0',
  whiteGrayWhite: '#F5F5FA',
  lightDark: '#262b37',
  gray50: '#F2F2F2',
  gray100: '#E3E3E3',
  gray150: '#D4D4D4',
  gray200: '#B5B5B5',
  gray300: '#979797',
  gray400: '#787878',
  gray500: '#575757',
  gray600: '#2d2d2d',
  gray700: '#252525',
  gray800: '#1C202C',
  dark: '#242A3D',
  black: '#000000',
  white: '#ffffff',
  whiteDark: '#EFEFEF',
  whiteDarker: ' rgba(255, 255, 255, 0.2)',
  info: '#40AAFB',
  infoLight: '#DAEBFF',
  infoDark: '#0F6EDE',
  warning: '#ffab02',
  warningLight: '#ffc107',
  warningLighter: '#FFF8DD',
  warningDark: '#9e4a03',
  success: '#00c177',
  successDark: '#0e714b',
  successLight: '#e0f2e0',
  error: '#F63C25',
  errorLight: '#f2d8d5',
  errorDark: '#DA1A0E',
  highlight: '#458FFF',
  boxShadowLight: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)',
  boxShadow: '0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%)',
  rootFontSize: 10,
  sm: 576,
  md: 786,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  borderRadiusS: 8,
  borderRadiusM: 16,
  borderRadiusL: 24,
  spaceXXS: 4,
  spaceXS: 8,
  spaceS: 12,
  spaceM: 16,
  spaceL: 20,
  spaceXL: 24,
  spaceXXL: 32,
  spaceXXXL: 48,
  spaceXXXXL: 64,
  lineAwesome: true,
  margin: (value) => ({ margin: value }),
  padding: (value) => ({ padding: value }),
  flex: (value) => ({ flex: value }),
  displayFlex: ({ align, justify, direction, wrap, flow } = {}) => ({
    display: 'flex',
    ...(align && { alignItems: align }),
    ...(justify && { justifyContent: justify }),
    ...(direction && { flexDirection: direction }),
    ...(wrap && { flexWrap: wrap }),
    ...(flow && { flexFlow: flow }),
  }),
  styles: (properties) => commonStyles(properties),
};
