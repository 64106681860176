import { useState } from 'react';
import PropTypes from 'prop-types';
import { omit, isFunction, isNil } from 'lodash-es';
import {
  Row,
  Col,
  Form,
  showApiError,
  Button,
  Input,
  Dropdown,
  inputValidation,
  // showError,
  FileInput,
} from 'components/UI';
import { KYCType } from 'enums';
import { useCountries } from 'stores';
import { createKyc } from 'services';
import { ReactComponent as DefaultCountryImage } from 'assets/images/default-country-image.svg';
import { kycAddressOptions } from './config';
import { countryContainer, countryImage, countryListLabel } from './styles';

const KycModalAddressForm = ({ onSubmit, onFail, onSuccess, onClose, onUploadDocs, vipStatus }) => {
  const [
    // value,
    setValue,
  ] = useState(null);
  const countries = useCountries();

  const countryListOptions = countries?.allWithoutRestricted?.map((el) => ({
    ...el,
    value: el.name,
    label: (
      <div css={countryContainer}>
        {!isNil(el.flag) ? (
          <div>{el.flag}</div>
        ) : (
          <div css={countryImage}>
            <DefaultCountryImage />
          </div>
        )}
        <span css={countryListLabel}>{el.name}</span>
      </div>
    ),
  }));

  // const onDocFilesUpload = async (inputFiles, values, handleChange) => {
  //   const uploadedFiles = Array.from(inputFiles);
  //   const fileExist = values.filter(file =>
  //     uploadedFiles.find(el => el.name === file.name && el.lastModified === file.lastModified),
  //   );

  //   if (fileExist.length) return showError(`Images ${fileExist.map(el => el.name).join(', ')} are already uploaded!`);

  //   if (uploadedFiles.some(el => el.size > 2097152)) return showError('Maximum size per file is 2 MB');

  //   const generatedPictures = await processImages(uploadedFiles, {
  //     parseToFile: true,
  //   });

  //   handleChange({
  //     target: { name: 'files', value: values.concat(generatedPictures) },
  //   });
  // };

  return (
    <Form
      onSubmit={async (values) => {
        isFunction(onSubmit) && onSubmit();
        setValue(values);
        const [, err] = await createKyc({
          type: KYCType.addressProof,
          ...omit(values, 'files'),
          isVip: vipStatus,
          filesContent: values?.files.map((el) => el.picture),
          thumbnailsContent: values?.files.map((el) => el.picThumbnail),
        });

        if (err) {
          isFunction(onFail) && onFail();
          return showApiError(err);
        }

        onUploadDocs();
        isFunction(onSuccess) && onSuccess();
      }}
    >
      <Row gap={16} margin="24px 0">
        <Col xs={12} md={6}>
          <Input
            required
            label="Full Address"
            formId="address"
            placeholder="Full Address"
            validate={(value) =>
              inputValidation('required')(value) ||
              inputValidation('lettersNumbersPunctuation')(value) ||
              ((value.length < 1 || value.length > 200) && {
                msg: 'The address must be at least 1 and at max 200 characters long.',
              })
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <Dropdown
            withSearch
            required
            formId="country"
            label="Country"
            options={countryListOptions}
            mapValue={(val) => val?.value}
            validate={inputValidation('required')}
            displayKey="label"
            renderOption={(val) => (
              <div css={countryContainer}>
                {!isNil(val.flag) ? (
                  <div>{val.flag}</div>
                ) : (
                  <div css={countryImage}>
                    <DefaultCountryImage />
                  </div>
                )}
                <span css={countryListLabel}>{val.name}</span>
              </div>
            )}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            required
            label="Postal Code"
            formId="zip"
            validate={inputValidation('required', 'lettersNumbersDash')}
            placeholder="Post Code"
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            required
            label="City"
            formId="city"
            validate={(value) =>
              inputValidation('required')(value) ||
              inputValidation('lowercase')(value) ||
              inputValidation('uppercase')(value) ||
              ((value.length < 1 || value.length > 100) && {
                msg: 'The city must be at least 1 and at max 100 characters long.',
              })
            }
            placeholder="City"
          />
        </Col>
        <Col xs={12} md={6}>
          <FileInput
            accept="image/*"
            formId="files"
            label="Documents"
            id="files"
            max={2}
            validate={inputValidation('required')}
          />
        </Col>

        <Col xs={12} md={6}>
          <Dropdown
            required
            label="Document Type"
            formId="subType"
            options={kycAddressOptions}
            placeholder="Please Choose"
            validate={inputValidation('required')}
          />
        </Col>
        <Col displayFlex md={12} justify="space-between">
          {({ onSubmit: formSubmit }) => (
            <>
              <Button onClick={formSubmit}> Upload Selected Files</Button>
              <Button type="secondary" onClick={onClose}>
                Cancel
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
};

KycModalAddressForm.propTypes = {
  onClose: PropTypes.func,
  onUploadDocs: PropTypes.func,
  vipStatus: PropTypes.bool,
  onSubmit: PropTypes.func,
  onFail: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default KycModalAddressForm;
