import { commonStyles } from 'components/UI/styles';
import upcomingBackground from 'assets/images/coin-exchange-icon.jpg';

export const subscriptionItem = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,

  '.subscriptionHeading': {
    fontWeight: 800,
    fontSize: 24,
  },

  '.subscriptionName': {
    fontSize: 18,
    fontWeight: 700,
  },

  '.subscriptionDuration': {
    fontSize: 14,
    lineHeight: 1.4,
    color: theme.gray,
  },

  '.subscriptionDate': {
    fontSize: 12,
    color: theme.gray,
    margin: '16px 0 32px 0',
  },

  '.subscriptionActions': {
    border: `2px solid ${theme.lightDark}`,
    borderRadius: '50%',
    padding: 3,
    textAlign: 'center',
    marginBottom: 32,

    '& > svg': {
      width: 30,
      height: 30,
    },
  },
});

export const subscriptionCard = (theme) => ({
  marginBottom: 40,
  position: 'relative',
  backgroundColor: theme.lightDark,
  borderRadius: 10,
  paddingTop: 80,

  '.cardTop': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.whiteGray,
    borderRadius: '0 0 10px 10px',
    border: `2px solid ${theme.lightDark}`,
    minHeight: 165,

    '.subscriptionImage': {
      height: 105,
      width: 105,
      backgroundSize: 'contain',
      position: 'absolute',
      top: 33,
      left: 'calc(100% - 50% - 54px)',
      borderRadius: '50%',
      border: `2px solid ${theme.whiteGray}`,
      backgroundColor: theme.whiteGray,
    },

    '.imageBottom': {
      height: 65,
    },
  },
});

export const oneLifeContainer = (theme) => ({
  '.rowContainer': {
    position: 'relative',
    backgroundColor: theme.white,

    '.columnContainer': {
      borderRight: `2px solid ${theme.gray100}`,
    },

    '.playStoreContainer': {
      display: 'flex',
      alignItems: 'center',

      '.cornerImage': {
        position: 'absolute',
        top: 0,
        height: 200,
        right: 0,
      },

      '.storeAppsContainer': {
        '.image': {
          width: 100,
          height: 'auto',
          marginBottom: 8,
        },

        display: 'flex',
        flexDirection: 'column',
      },
    },

    '.subscriptionData': {
      '.phoneImageContainer': {
        position: 'absolute',
        bottom: 0,
        right: 50,
        zIndex: 0,

        '.image': {
          maxWidth: 440,
          height: '100%',

          '@media (max-width: 992px)': {
            display: 'none',
          },
        },
      },
    },
  },
});

export const subscriptionItemContainer = (props) => (theme) => [
  {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    lineHeight: 1.4,
    borderRadius: 4,
    position: 'relative',

    '.subscriptionDiscount': {
      fontSize: 12,
      fontWeight: 600,
      borderRadius: 25,
      backgroundColor: theme.secondary,
      color: theme.secondaryDark,
      padding: '1px 3px',
      textAlign: 'center',
      width: '80%',
      position: 'absolute',
      top: -10,
      right: '10%',
    },

    '.subscriptionDataName': {
      fontSize: 18,
      fontWeight: 800,
      marginBottom: 4,
    },

    '.price': {
      color: theme.error,
    },

    '.duration': {
      fontSize: 12,
      color: theme.gray,
      marginBottom: 8,
    },

    '.button': {
      width: '100%',
      marginBottom: 16,
    },
    ...commonStyles(props),
  },
];

export const subscriptionPaymentContainer = (theme) => ({
  '.title': {
    fontWeight: 600,
    fontSize: 14,
  },

  '.data': {
    fontSize: 12,
    fontWeight: 400,
    color: theme.gray300,
  },
});

export const loaderContainer = (theme) => ({
  backgroundColor: theme.white,
  padding: 32,
});

export const oneForexContainer = (theme) => ({
  '.rowItem': {
    backgroundColor: theme.white,

    '.subscriptionContainerItem': {
      boxShadow: '0px 6px 8px rgba(224, 225, 229, 0.5)',

      '&:last-child': {
        boxShadow: 'none',
      },
    },
  },

  '.upcomingSubscriptionsColumn': {
    padding: '0 0 0 32px !important',

    '.upcomingSubscriptions': {
      backgroundImage: `url(${upcomingBackground}) !important`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 24,

      '.labelContainer': {
        backgroundColor: theme.whiteDarker,
        borderRadius: 50,
        padding: 4,
        textAlign: 'center',
        width: '30%',
        marginBottom: 24,

        '.label': {
          fontWeight: 600,
          fontSize: 12,
          color: theme.white,
        },
      },

      '.columnInfo': {
        display: 'flex',
        flexDirection: 'column',

        '.price': {
          color: theme.white,
        },

        '.duration': {
          fontWeight: 400,
          fontSize: 11,
          color: theme.gray300,
        },
      },
    },
  },
});

export const oneVoyageContainer = (theme) => ({
  '.rowContainer': {
    backgroundColor: theme.white,

    '.firstContainer': {
      borderBottom: `5px solid ${theme.gray50}`,
    },

    '.borderRight': {
      borderRight: `1px solid ${theme.gray50}`,
    },

    '.borderedColumn': {
      border: `1px solid ${theme.gray50}`,
    },

    '.shadowBox': {
      borderRight: `1px solid ${theme.gray50}`,

      '.item': {
        padding: 12,
      },

      '&:last-child': {
        borderRight: 'none',
      },
    },

    '.benefitsSmall': {
      '.text': {
        color: theme.secondaryDark,
        fontWeight: 500,
      },

      '.data': {
        padding: '30px 24px',
        lineHeight: 1.4,
        height: '100%',
        position: 'relative',

        '.title': {
          position: 'absolute',
          bottom: 0,
          marginBottom: 16,
        },

        '& > svg': {
          width: 16,
          height: 16,
        },
      },
    },

    '.benefitsXs': {
      '.title': {
        fontWeight: 500,
        fontSize: 14,
      },

      '.data': {
        fontSize: 12,
        fontWeight: 400,
        color: theme.gray,
      },
    },
  },
});
