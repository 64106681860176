import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'components/UI';
import { ReactComponent as AppIcon } from 'assets/images/app-icon.svg';
import { appOptions } from './config';
import { container, appIcon } from './styles';

const AppNavigation = () => {
  const [openContainer, setOpenContainer] = useState(false);

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpenContainer(false);
  };

  return (
    <div role="button" tabIndex={0} onClick={() => setOpenContainer(true)} onBlur={hideContainer}>
      <div role="button" tabIndex={0} onClick={(event) => event.preventDefault()}>
        <AppIcon css={appIcon} />
      </div>

      {openContainer && (
        <div css={container}>
          <Row gap={8}>
            {appOptions.map((el, ind) => (
              <Col sm={6} className="appContainer" key={ind}>
                <Button type="link" linkTo={el.url} className="iconButton">
                  {el.icon}
                </Button>
                <Link to={el.url}>{el.text}</Link>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default AppNavigation;
