import { useHistory } from 'react-router-dom';
import { useUserStore } from 'stores';
import { Button, Image, useUser, UserNavigation } from 'components';
import { navBarHeaderContainer, navBarRightSideContainer } from './styles';

const GuestNavBar = () => {
  const { isAuthenticated } = useUser();
  const user = useUserStore();
  const history = useHistory();

  return (
    <header css={navBarHeaderContainer}>
      <div />
      <div tabIndex={0} role="button" onClick={() => history.push('/overview')}>
        <Image
          src="https://onelife.blob.core.windows.net/images/oneEcosystemLogoWhiteText.svg"
          alt="logo"
          height={30}
          width="auto"
        />
      </div>

      {!isAuthenticated && (
        <div css={navBarRightSideContainer}>
          Have an account?
          <Button small type="link" linkTo="/login" className="action-button">
            Login here
          </Button>
        </div>
      )}

      {isAuthenticated && (
        <div css={navBarRightSideContainer}>
          <p className="nickname-styles">{user?.currentAccount?.nickName ?? 'Monkey'}</p>
          <UserNavigation />
        </div>
      )}
    </header>
  );
};

export default GuestNavBar;
