/* eslint-disable react/destructuring-assignment */

import moment from 'moment';
import { defaultDate } from '../../../utils/moment';

export const dateTime = (key) => (props) => {
  const { NAItem } = props;
  if (props[key] === defaultDate) return NAItem;

  const transactionDate = moment.utc(props[key]);
  const dateNow = moment.utc();
  const difference = moment.duration(transactionDate.diff(dateNow));

  return (
    <div className="vk-crypto-date-time-col-format">
      <span>{`${transactionDate.local().format('DD/MM/YYYY, HH:mm:ss')}`}</span>
      <span className="vk-crypto-date-time-min-ago">{difference.humanize(true, { d: 7, w: 4 })}</span>
    </div>
  );
};
