import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Image } from 'components/UI';
import { pluralizeMonths } from 'utils';
import starterLogo from 'assets/images/one-life-logo.png';
import { ReactComponent as DetailsIcn } from 'assets/images/three-dots.svg';
import { subscriptionCard, subscriptionItem } from './styles';

const SubscriptionCard = (props) => {
  const { item = {} } = props;
  const { totalDuration, vendor, validFrom, totalValidUntil, subscriptionCatalogueItemId } = item;

  const durationLabel = `${totalDuration % 12 === 0 ? totalDuration / 12 : totalDuration} ${pluralizeMonths(
    totalDuration,
  )}`;

  return (
    <div css={subscriptionCard}>
      <div className="cardTop">
        <Row justify="center">
          <Col xs={12} sm={12}>
            <Image
              src={item?.picThumbnailUrl ?? item?.pictureUrl ?? starterLogo}
              className="subscriptionImage"
              alt="img-subscription-card"
            />
          </Col>
          <Col xs={12} sm={12}>
            <div className="imageBottom" />
          </Col>
          <Col xs={12} sm={10} css={subscriptionItem}>
            <h3 className="subscriptionHeading">{vendor}</h3>
            <br />
            <div className="subscriptionDuration">{durationLabel}</div>
            <div className="subscriptionDate">
              {`Active from: ${new Date(validFrom).toLocaleDateString()}`}
              <br />
              {`Active until: ${new Date(totalValidUntil).toLocaleDateString()}`}
            </div>
          </Col>
          <Col xs={12} sm={10} css={subscriptionItem}>
            <Link
              to={{
                pathname: `/subscriptions/details/${subscriptionCatalogueItemId}`,
                state: { item },
              }}
            >
              <div className="subscriptionActions">
                <DetailsIcn />
              </div>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

SubscriptionCard.propTypes = {
  item: PropTypes.any,
  onAddToCard: PropTypes.func,
  cashWallet: PropTypes.object,
};

export default SubscriptionCard;
