export const accountsContainer = (theme) => ({
  padding: '32px 48px',

  '.title': {
    marginBottom: 16,
  },

  '.horizontal-line': {
    marginBottom: 32,
    backgroundColor: theme.gray200,
  },

  '.accountActions': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
