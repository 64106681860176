/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { ThemeProvider, Global } from '@emotion/react';
import config from './config';
import { globalCss } from '../styles';

const Theme = ({ children, initGlobalStyles, iconSets = {}, theme: themeProp = null }) => {
  const [theme, setTheme] = useState(themeProp);

  useLayoutEffect(() => {
    setUpTheme();
  }, [themeProp]);

  const setUpTheme = async () => {
    setTheme((prev) => ({ ...prev, ...config, ...themeProp }));
    injectIconFonts({ ...config, ...iconSets });
  };

  const injectIconFonts = (data) => {
    const { fontAwesome, lineAwesome, iconsMind } = data;
    if (fontAwesome) import('../Icon/icon-sets/font-awesome/font-awesome.css');
    if (lineAwesome) import('../Icon/icon-sets/line-awesome/line-awesome.css');
    if (iconsMind) import('../Icon/icon-sets/iconsmind/iconsmind.css');
  };

  // TODO: Put some loader to be shown until fetching theme config
  return (
    theme && (
      <ThemeProvider theme={theme}>
        <Global styles={globalCss(theme)} />
        {isFunction(initGlobalStyles) && theme && <Global styles={initGlobalStyles(theme)} />}
        {children}
      </ThemeProvider>
    )
  );
};

Theme.propTypes = {
  children: PropTypes.node,
  initGlobalStyles: PropTypes.func,
  iconSets: PropTypes.object,
  theme: PropTypes.object,
};

export default Theme;
