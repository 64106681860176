export const subscriptionsPageContainer = (backgroundColor) => (theme) => ({
  padding: '32px 48px',

  '.title': {
    marginBottom: 32,
  },

  '.header': {
    backgroundColor,
    marginBottom: 16,
    marginTop: 32,

    '.image': {
      minWidth: 100,
      maxWidth: 200,
      height: 'auto',
      padding: 16,
    },
  },

  '.vendorSection': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 32,

    '.vendorTitle': {
      fontWeight: 600,
      fontSize: 28,
      color: theme.gray,
      marginBottom: 8,
    },

    '.vendorDescription': {
      color: theme.gray300,
      marginBottom: 72,
    },
  },
});

export const backButtonStyles = (theme) => ({
  color: theme.white,
  backgroundColor: theme.gray300,
  display: 'flex',
  top: 73,
  left: 'calc(100% - 100vw + 240px)',
  width: 100,
});
