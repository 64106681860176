import PropTypes from 'prop-types';
import { Row, Col } from 'components';
import { wrap } from './styles';

const ListHeader = ({ title, actions }) => (
  <div css={wrap}>
    <Row horizontalGap={16} align="center">
      <Col sm={12} md>
        <h4>{title}</h4>
      </Col>
      <Col sm={12} md="auto">
        {actions}
      </Col>
    </Row>
  </div>
);

ListHeader.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.any,
};

export default ListHeader;
