import { sub } from 'exact-math';
import { useStore, createStore, showError } from 'components/UI';
import { getMyPackages, getAllPackages } from 'services';
import starterLogo from 'assets/images/starter.png';

const packagesStore = createStore({
  current:
    {
      giftCode: '189fd57f-007a-45a9-94b1-e86ebe05be20',
      redeemedOn: '2020-11-10T12:00:44.369788',
      refundedOn: '0001-01-01T00:00:00',
      promoTokens: 0,
      academyTokensReward: 0,
      academyTokensRewardedOn: null,
      catalogueItemId: 77,
      name: 'First GAS package',
      description: 'gas package',
      price: 100,
      currency: null,
      tokens: 1,
      academyTokens: 0,
      split: 1,
      maxSplits: 0,
      promoSplits: 2,
      difficulty: 0,
      exchangeReduction: 0,
      businessVolume: 1,
      hasNetworkBonus: true,
      hasMatch1Bonus: true,
      hasMatch2Bonus: true,
      hasMatch3Bonus: true,
      hasMatch4Bonus: true,
      bonusCap: 1,
      immediateMining: true,
      type: 'GasPackage',
      pictureUrl: null,
      picThumbnailUrl: null,
      allSplitsAtOnce: false,
      color: null,
      gasAmount: 1,
    } ?? null,
  items:
    [
      {
        giftCode: '189fd57f-007a-45a9-94b1-e86ebe05be20',
        redeemedOn: '2020-11-10T12:00:44.369788',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 77,
        name: 'First GAS package',
        description: 'gas package',
        price: 100,
        currency: null,
        tokens: 1,
        academyTokens: 0,
        split: 1,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 0,
        exchangeReduction: 0,
        businessVolume: 1,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: true,
        hasMatch4Bonus: true,
        bonusCap: 1,
        immediateMining: true,
        type: 'GasPackage',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 1,
      },
      {
        giftCode: 'b290f952-2d7e-4288-87be-c7542fa1f4b3',
        redeemedOn: '2020-11-10T12:02:33.5328159',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 24,
        name: 'Starter',
        description: 'Starter package',
        price: 0,
        currency: '',
        tokens: 0,
        academyTokens: 0,
        split: 0,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 0,
        exchangeReduction: 0,
        businessVolume: 0,
        hasNetworkBonus: false,
        hasMatch1Bonus: false,
        hasMatch2Bonus: false,
        hasMatch3Bonus: false,
        hasMatch4Bonus: false,
        bonusCap: 0,
        immediateMining: true,
        type: 'Package',
        pictureUrl:
          'https://onelife-profile-cdn.azureedge.net/package/pict_c5580c03-f4bd-4e56-8743-c6ad6153432a_20210215100100877.png',
        picThumbnailUrl:
          'https://onelife-profile-cdn.azureedge.net/package/thumb_c5580c03-f4bd-4e56-8743-c6ad6153432a_20210215100102068.png',
        allSplitsAtOnce: false,
        color: '#f72b07ff',
        gasAmount: 0,
      },
      {
        giftCode: 'a50dc9e3-54b9-4541-a072-bb56b5e0924a',
        redeemedOn: '2020-11-10T12:04:01.1905767',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 2,
        name: 'Diamant',
        description: 'updated with all prev versions',
        price: 9.9,
        currency: 'BGN',
        tokens: 6,
        academyTokens: 0,
        split: 3,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 7,
        exchangeReduction: 0,
        businessVolume: 1,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: false,
        hasMatch4Bonus: false,
        bonusCap: 4,
        immediateMining: true,
        type: 'Package',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 0,
      },
      {
        giftCode: '61bf063e-11f6-48c8-9a75-ec1569ed5f5a',
        redeemedOn: '2020-12-07T13:29:31.9862659',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 2,
        name: 'Diamant',
        description: 'updated with all prev versions',
        price: 9.9,
        currency: 'BGN',
        tokens: 6,
        academyTokens: 0,
        split: 3,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 7,
        exchangeReduction: 0,
        businessVolume: 1,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: false,
        hasMatch4Bonus: false,
        bonusCap: 4,
        immediateMining: true,
        type: 'Package',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 0,
      },
      {
        giftCode: '7b4f2768-f08d-42b5-8901-c0c13db8a8b3',
        redeemedOn: '2020-12-31T12:24:09.5949004',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 17,
        name: 'Kiribati',
        description: 'Kiribati package',
        price: 568.32,
        currency: 'bgn',
        tokens: 2,
        academyTokens: 0,
        split: 5,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 3,
        exchangeReduction: 0,
        businessVolume: 6,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: true,
        hasMatch4Bonus: true,
        bonusCap: 2,
        immediateMining: true,
        type: 'Package',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 0,
      },
      {
        giftCode: '25839b76-b3fd-4895-a14a-4debf3e54718',
        redeemedOn: '2020-12-31T12:29:02.9654966',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 5,
        name: 'Rookie2',
        description: 'Rookie package',
        price: 678,
        currency: 'bgn',
        tokens: 3,
        academyTokens: 0,
        split: 5,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 8,
        exchangeReduction: 0,
        businessVolume: 2,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: false,
        hasMatch3Bonus: false,
        hasMatch4Bonus: false,
        bonusCap: 4,
        immediateMining: true,
        type: 'Package',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 0,
      },
      {
        giftCode: 'c53ed682-4302-46ee-858a-b5450b3c19e1',
        redeemedOn: '2020-12-31T12:34:39.5428578',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 9,
        name: 'Bulgaria',
        description: 'Bulgaria package',
        price: 780,
        currency: 'bgn',
        tokens: 3,
        academyTokens: 0,
        split: 2,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 5,
        exchangeReduction: 0,
        businessVolume: 4,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: true,
        hasMatch4Bonus: true,
        bonusCap: 7,
        immediateMining: true,
        type: 'Package',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 0,
      },
      {
        giftCode: '8e284929-8dfe-457f-ae08-d9a02336ed82',
        redeemedOn: '2020-12-31T12:37:05.2684048',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 10,
        name: 'Romania',
        description: 'Romania package',
        price: 870.9,
        currency: 'bgn',
        tokens: 3,
        academyTokens: 0,
        split: 2,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 4,
        exchangeReduction: 0,
        businessVolume: 5,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: true,
        hasMatch4Bonus: false,
        bonusCap: 1,
        immediateMining: true,
        type: 'Package',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 0,
      },
      {
        giftCode: 'f29c8a45-70f3-4b5c-b89b-b31ef1dfd01b',
        redeemedOn: '2020-12-31T13:00:57.3108393',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 15,
        name: 'Czech Republic',
        description: 'Czech Republic package',
        price: 909.67,
        currency: 'bgn',
        tokens: 2,
        academyTokens: 0,
        split: 5,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 1,
        exchangeReduction: 0,
        businessVolume: 6,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: false,
        hasMatch4Bonus: false,
        bonusCap: 2,
        immediateMining: true,
        type: 'Package',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: '#c5f029ff',
        gasAmount: 0,
      },
      {
        giftCode: 'dc237eb8-894b-434a-8055-cb0f2e0a48b0',
        redeemedOn: '2021-06-21T06:09:09.9682338',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 79,
        name: 'new gas package',
        description: 'gas',
        price: 10,
        currency: null,
        tokens: 1,
        academyTokens: 0,
        split: 0,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 0,
        exchangeReduction: 0,
        businessVolume: 0,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: true,
        hasMatch4Bonus: true,
        bonusCap: 1,
        immediateMining: true,
        type: 'GasPackage',
        pictureUrl: null,
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 0,
      },
      {
        giftCode: '82d7dad7-bc41-4981-804c-eb8e219d002d',
        redeemedOn: '2022-04-18T12:20:56.6659114',
        refundedOn: '0001-01-01T00:00:00',
        promoTokens: 0,
        academyTokensReward: 0,
        academyTokensRewardedOn: null,
        catalogueItemId: 35,
        name: 'Immediate Mining',
        description: 'Immediate Mining',
        price: 1000,
        currency: 'EUR',
        tokens: 10,
        academyTokens: 0,
        split: 10,
        maxSplits: 0,
        promoSplits: 2,
        difficulty: 0,
        exchangeReduction: 0,
        businessVolume: 1000,
        hasNetworkBonus: true,
        hasMatch1Bonus: true,
        hasMatch2Bonus: true,
        hasMatch3Bonus: true,
        hasMatch4Bonus: true,
        bonusCap: 10000,
        immediateMining: true,
        type: 'Package',
        pictureUrl:
          'https://onelife-profile-cdn.azureedge.net/package/pict_bfdeb22b5e9a41abad30de192000223e_20200609133010723.png',
        picThumbnailUrl: null,
        allSplitsAtOnce: false,
        color: null,
        gasAmount: 0,
      },
    ] ?? null,
});

const findCurrentPackage = (packages) => [...packages].sort((a, b) => sub(b.price, a.price)).shift() ?? {};

export const initMyPackages = async () => {
  const [res, err] = getMyPackages();
  if (err) showError(err);

  const all = res.map((el) => ({
    ...el,
    logo: el.picThumbnailUrl ?? el.pictureUrl ?? starterLogo,
  }));

  packagesStore.setState({ current: findCurrentPackage(all), items: all });
};

export const addNewPackage = (item) => {
  const newPackage = {
    ...item,
    logo: item.picThumbnailUrl ?? item.pictureUrl ?? starterLogo,
  };

  packagesStore.setState((prev) => ({
    current: findCurrentPackage(prev.concat(newPackage)),
    items: prev.concat(newPackage),
  }));
};

export const allPackages = () => {
  const [res, err] = getAllPackages();
  if (err) showError(err);

  const activeVisible = res.filter((el) => el.active && el.visible);

  packagesStore.setState({ current: null, items: activeVisible });
};

export const usePackages = (...args) => useStore(packagesStore, ...args);
