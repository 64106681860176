export const ibanMask = (value) => {
  if (!value) return 'N/A';

  const maskedValue = value.replace(
    /(\+?\d{3})(\d+)(\d{2})/g,
    (match, start, middle, end) => start + '*'.repeat(middle.length) + end,
  );
  return maskedValue;
};

export const bicSwiftMask = (value) => {
  const maskedValue = value.slice(5, value.length).replace(/^/, '**/**/');
  return maskedValue;
};

export const accountNumberMask = (value) => {
  const maskedValue = value.replace(
    /(\+?\w{1})(\w+)(\w{1})/g,
    (match, start, middle, end) => start + '*'.repeat(middle.length) + end,
  );
  return maskedValue;
};

export const cardNumberMask = (value) => {
  const maskedValue = value.replace(
    /(\+?\w{1})(\w+)(\w{1})/g,
    (match, start, middle, end) => start + '*'.repeat(middle.length) + end,
  );
  return maskedValue;
};
