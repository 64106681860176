import { css } from '@emotion/react';

export default (theme) => css`
.rdt {
  font-size: 1.4rem;

  .rdtPicker {
    td.rdtActive, td.rdtActive:hover {
      background-color: ${theme.primary} 
    }

    td.rdtToday:before {
      border-bottom-color: ${theme.primary}
    }
  }
}

.DateRangePickerInput {
  border-radius: 20px !important;
 
  .DateInput {
    border-radius: 20px;

    .DateInput_input {
      border-radius: 20px;
      border-bottom: none;
      color: ${theme.gray150};

      &:focus {
        color: ${theme.gray700};
      }
    }

    .DateInput_input__small {
      padding: 7px;
    }
  }

  .CalendarDay__default {
    color: ${theme.gray700}

    &:hover {
      border-color: ${theme.primary};
      background-color: ${theme.primaryLight};
      color: ${theme.white};
    }
  }

  .CalendarDay__selected_span {
    color: ${theme.white};
    background-color: ${theme.primaryLight};
    border-color: ${theme.primary};
    &:hover {
      background-color: ${theme.primary};
      border-color: ${theme.primary};
    }
  }

  .CalendarDay__blocked_out_of_range {
    cursor: not-allowed;

    &:hover {
      background-color: ${theme.white};
      border-color: ${theme.gray200};
    }
  }

  .CalendarDay__hovered_span {
    color: ${theme.white};
    background-color: ${theme.primaryLight};
    border-color: ${theme.primary};
  }

  .CalendarDay__selected {
    color: ${theme.white};
    background-color: ${theme.errorDark};
    border-color: ${theme.primary};

    &:hover {
      background-color: ${theme.errorDark};
      border-color: ${theme.primary};
    }
  }

  .CalendarMonth_caption_1 {
    font-size: 1.1rem;
  }

  .CalendarMonth_table_1{
    margin-top: 6px;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    &:hover::before {
      border-right-color: ${theme.primary};
    }
    &::before {
      border-right-color: ${theme.primaryLight};
    }
  }
}`;
