export const headerContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 24,
  borderBottom: `1px solid ${theme.gray100}`,

  '& .edit-button': {
    border: 'none',
    color: theme.secondaryDark,
  },
});

export const rowContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& .label': {
    color: theme.gray200,
  },

  '& .value': {
    color: theme.gray400,
  },
});

export const countryContainer = {
  display: 'flex',
  padding: 8,
};

export const countryListLabel = {
  marginLeft: 12,
};

export const countryImage = {
  marginRight: 12,
  width: 20,
  height: 20,
};
