import { useRef } from 'react';
import PropTypes from 'prop-types';
import { rippleBaseStyles, animationOn, onFocus } from './styles';

const Ripple = (props) => {
  const { children, className, focusColor, flat, guideId } = props;

  const rippleClassName = useRef();
  const onFocusClassName = useRef();
  const guideIdProp = guideId ? { 'data-guide-id': guideId } : {};

  const handleMouseDown = (e) => {
    e.preventDefault();
    const { width, height, left, top } = e.currentTarget.getBoundingClientRect();
    const size = Math.max(width, height);

    const x = flat ? 0 : parseInt(e.clientX - left - size / 2, 10);
    const y = flat ? 0 : parseInt(e.clientY - top - size / 2, 10);
    rippleClassName.current = animationOn(x, y, size);
    e.currentTarget.classList.add(rippleClassName.current.name);
    e.currentTarget.focus();
  };

  const handleMouseUp = (e) => {
    e.preventDefault();
    const elementClass = e.currentTarget.classList;
    setTimeout(() => {
      elementClass.remove(rippleClassName.current.name);
    }, 180);
  };

  const handleFocus = (e) => {
    e.preventDefault();
    onFocusClassName.current = onFocus(focusColor);
    e.currentTarget.classList.add(onFocusClassName.current);
  };

  const handleBlur = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove(onFocusClassName.current);
  };

  return (
    <div
      role="presentation"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...(className && { className })}
      css={rippleBaseStyles(props)}
      {...guideIdProp}
    >
      {children}
    </div>
  );
};

Ripple.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  backColor: PropTypes.string,
  focusColor: PropTypes.string,
  flat: PropTypes.bool,
  guideId: PropTypes.string,
};

export default Ripple;
