import { useState, useEffect } from 'react';
import { isNil } from 'lodash-es';
import { Paper, showApiError, showSuccess, ActionInput } from 'components';
import { updateSecurityPin, getMyMobileSettings } from 'services';
import { ReactComponent as SecurityPinIcn } from 'assets/images/icn-big-security-pin.svg';
import { pinContainer } from './styles';

const onlyNumberPattern = (val) => /^[0-9]{0,4}$/.test(val);

const SecurityPin = () => {
  const [securityPin, setSecurityPin] = useState(null);

  useEffect(() => {
    fetchSecurityPin();
  }, []);

  const handleGeneratePin = () => setSecurityPin(`${Math.random()}`.split('.')[1].substring(0, 4));

  const fetchSecurityPin = async () => {
    const [res, err] = await getMyMobileSettings();

    err ? showApiError(err) : setSecurityPin(res?.mobilePin ?? '');
  };

  const handleSaveSecurityPin = async (text) => {
    const [, err] = await updateSecurityPin({
      mobilePin: text,
    });

    err ? showApiError(err) : showSuccess('You have successfully updated your security pin.');
  };

  return (
    <div css={pinContainer}>
      <h3>Security Pin</h3>
      <hr className="horizontal-line" />

      <Paper className="paperContainer">
        <div className="pinCode">
          <SecurityPinIcn className="icon" />
          <p>Generate Security Pin</p>
        </div>
        <hr className="horizontal-line" />

        <ActionInput
          className="vk-security-pin-link-input"
          inputProps={{
            value: securityPin,
            handleChange: setSecurityPin,
            disabled: isNil(securityPin),
            pattern: onlyNumberPattern,
          }}
          buttonProps={{
            children: !securityPin ? 'GENERATE CODE' : 'SAVE',
            disabled: isNil(securityPin) || (securityPin && !/^[0-9]{4}$/.test(securityPin)),
            type: !securityPin ? 'primary' : 'default',
          }}
          onClick={!securityPin ? handleGeneratePin : handleSaveSecurityPin}
        />
      </Paper>
    </div>
  );
};

export default SecurityPin;
