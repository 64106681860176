export const KYCType = {
  idProof: 'idProof',
  addressProof: 'addressProof',
};

export const KYCSubType = {
  idProof: {
    IDCard: 'card',
    Passport: 'passport',
    InternationalPassport: 'internationalPassport',
    DrivingLicense: 'driverLicence',
  },
  addressProof: {
    GasBill: 'gasBill',
    WaterBill: 'waterBill',
    ElectricityBill: 'electricityBill',
    BankStatement: 'bankStatement',
    MunicipalityGovStatement: 'municipalityGovStatement',
    ResidentialAgencyStatement: 'residentialAgencyStatement',
    NationalIdDocument: 'nationalIdDocument',
    RentalAgreement: 'rentalAgreement',
    PhoneBill: 'phoneBill',
  },
};

export const FLOW_TYPE = {
  REGULAR: 'regular',
  XKYC: 'xkyc',
  PAID: 'paid',
};

export const STATUS_TYPES = {
  WAIT: 'waiting',
  FAIL: 'failed',
  SUCCESS: 'succeeded',
};
