import { profileStatus, treePlacement } from 'enums';
import { label } from './styles';

export const profileData = (userData) => [
  {
    label: 'Email address',
    value: userData?.email,
  },
  {
    label: 'Phone number',
    value: userData?.phone,
  },
  {
    label: 'Address',
    value: (
      <div css={label}>
        <p>{`${userData?.country}, ${userData?.city} ${userData?.zip}`}</p> <p>{userData?.address}</p>
      </div>
    ),
  },
];

export const profileStatusColorMap = {
  [profileStatus.New]: {
    text: 'New',
    color: 'orange',
  },
  [profileStatus.Pending]: {
    text: 'Pending',
    color: 'blue',
  },
  [profileStatus.Active]: {
    text: 'Active',
    color: 'green',
  },
  [profileStatus.Frozen]: {
    text: 'Frozen',
    color: 'warning',
  },
  [profileStatus.Terminated]: {
    text: 'Terminated',
    color: 'red',
  },
};

export const memberPlacements = [
  {
    label: 'Alternatively left / right',
    value: treePlacement.auto,
  },
  {
    label: 'Left',
    value: treePlacement.left,
  },
  {
    label: 'Right',
    value: treePlacement.right,
  },
];
