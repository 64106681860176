import { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, showApiError, showSuccess } from 'components/UI';
import { updateAutoMine } from 'services';
import { ReactComponent as CloseIcn } from 'assets/images/icn-close.svg';
import { ReactComponent as QuestionIcn } from 'assets/images/red-question.svg';
import { useMiningModalContainer } from './styles';

const UseMiningModal = forwardRef((props, ref) => {
  const { onSubmit } = props;
  const modalRef = useRef();

  useImperativeHandle(ref, () => ({
    showModal: modalRef.current.open(),
  }));

  const handleSubmitUseAutomine = async (useAutomine) => {
    const [, err] = await updateAutoMine({ useAutomine });

    onSubmit();
    err ? showApiError(err) : showSuccess('You have successfully changed your Automine.');
    modalRef.current.close();
  };

  return (
    <Modal ref={modalRef} css={useMiningModalContainer} onClose={modalRef.current?.close()} closeIcon={<CloseIcn />}>
      <h4 className="title">Enable auto mining</h4>

      <div className="useMiningModalBody">
        <QuestionIcn className="icon" />

        <p className="bodyText">
          If you Accept to use Auto mining, your tokens will be sent for mining and your balance will stay zero. The
          token will be taken into calculations during the Split run.
        </p>
        <p className="bodyText">
          If you choose to decline your tokens will stay in the wallet and you can submit them at a later stage.
        </p>

        <div className="bodyActions">
          <Button small type="secondary" onClick={() => handleSubmitUseAutomine(false)}>
            Decline
          </Button>
          <Button small onClick={() => handleSubmitUseAutomine(true)}>
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  );
});

UseMiningModal.propTypes = {
  onSubmit: PropTypes.func,
};

export default UseMiningModal;
