export const restrictedCountries = [
  { id: 2 },
  { id: 5 },
  { id: 8 },
  { id: 9 },
  { id: 25 },
  { id: 31 },
  { id: 33 },
  { id: 42 },
  { id: 47 },
  { id: 48 },
  { id: 53 },
  { id: 72 },
  { id: 73 },
  { id: 78 },
  { id: 79 },
  { id: 85 },
  { id: 87 },
  { id: 89 },
  { id: 90 },
  { id: 92 },
  { id: 97 },
  { id: 98 },
  { id: 108 },
  { id: 113 },
  { id: 141 },
  { id: 144 },
  { id: 164 },
  { id: 165 },
  { id: 166 },
  { id: 177 },
  { id: 180 },
  { id: 182 },
  { id: 186 },
  { id: 187 },
  { id: 190 },
  { id: 191 },
  { id: 208 },
  { id: 214 },
  { id: 215 },
  { id: 225 },
  { id: 231 },
  { id: 237 },
  { id: 238 },
  { id: 244 },
  { id: 245 },
  { id: 246 },
  { id: 247 },
];
