import { forgotPassword, login } from '@oneecosystem/authenticate';
import { Row, Col, Button, fadeInAnimation } from 'components/UI';
// import { useUser } from 'components/shared/UserContext';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LogoText } from 'assets/images/logo-text.svg';
import { forgotStyles, publicContentContainer } from '../styles';

const Login = () => {
  // const { loginUser } = useUser();
  const getYear = new Date().getFullYear();

  return (
    <Row css={publicContentContainer}>
      <Col xs={12} sm={6} lg={6} className="actionColumn">
        <Button linkTo={login()} className="actionButton">
          Sign In
        </Button>
        <div className="footerContainer">
          <p>
            &copy; OneEcosystem &nbsp;
            {getYear}. &nbsp; <span>All rights reserved.</span>
          </p>

          <a href={forgotPassword()} css={forgotStyles}>
            Forgot password
          </a>
        </div>
      </Col>
      <Col xs={12} sm={6} lg={6} className="pictureContainer">
        <div className="container">
          <div className="logoContainer">
            <Logo className="logo" />
            <LogoText className="logoText" css={fadeInAnimation()} />
          </div>
          <h2 className="title">Welcome to One Ecosystem</h2>
          <p className="subtitle">
            OneEcosystem is as revolutionary business concept that aims to bring numerous opportunities to those who
            want to take charge of their life.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
