import { useEffect } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { exchangeCodeForToken } from '@oneecosystem/authenticate';
import { Spinner } from 'components/UI';
import { initializeUser } from 'stores';

const RedirectCallback = ({ history, location }) => {
  const { code } = qs.parse(location.search) || {};

  useEffect(() => {
    handleSuccessLogin();
  }, []);

  const handleSuccessLogin = async () => {
    await exchangeCodeForToken(code);

    initializeUser();
    history.push('/dashboard');
  };

  return <Spinner />;
};

RedirectCallback.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default RedirectCallback;
