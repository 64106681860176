export const maskContainer = (visible) => (theme) => ({
  display: 'flex',
  alignItems: 'center',
  color: visible ? theme.gray300 : theme.gray,

  '& .eye-icon': {
    marginRight: 8,
    opacity: visible ? 1 : 0.5,
    cursor: 'pointer',
    width: 20,
    height: 20,

    '&:hover': {
      transition: 'opacity 0.3s ease',
      opacity: visible ? 0.7 : 1,
    },
  },
});
