import { useStore, createStore, showError } from 'components/UI';
import { getAllSubscriptions } from 'services';
import starterLogo from 'assets/images/starter.png';

const subscriptionsStore = createStore(
  [
    {
      accountId: 2104,
      subscriptionCatalogueItemId: 19,
      subscriptionPlanId: 26,
      name: 'Test methods',
      price: 1,
      currency: 'EUR',
      businessVolume: 1,
      duration: 1,
      systemName: 'OneApp-1672908857172',
      visible: true,
      active: true,
      description: 'Test subscription catalogue item with methods',
      pictureUrl: null,
      picThumbnailUrl: null,
      color: null,
      validFrom: '2023-02-07T13:37:37.4618767',
      vendor: 'OneApp',
    },
    {
      accountId: 2104,
      subscriptionCatalogueItemId: 12,
      subscriptionPlanId: 19,
      name: 'Test',
      price: 1,
      currency: null,
      businessVolume: 10,
      duration: 6,
      systemName: 'OneApp-1671795578352',
      visible: true,
      active: true,
      description: 'Test',
      pictureUrl: null,
      picThumbnailUrl: null,
      color: null,
      validFrom: '2023-02-07T13:37:37.4660874',
      vendor: 'OneForex',
    },
    {
      accountId: 2104,
      subscriptionCatalogueItemId: 2,
      subscriptionPlanId: 5,
      name: 'Basic',
      price: 50,
      currency: null,
      businessVolume: 25,
      duration: 1,
      systemName: 'OneVoyage',
      visible: true,
      active: true,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.1',
      pictureUrl: null,
      picThumbnailUrl: null,
      color: null,
      validFrom: '2023-02-07T13:36:29.6700655',
      vendor: 'OneVoyage',
    },
  ] ?? null,
);

export const allSubscriptions = async () => {
  const [res, err] = await getAllSubscriptions();
  if (err) showError(err);

  const all = res.map((el) => ({
    ...el,
    logo: el.picThumbnailUrl ?? el.pictureUrl ?? starterLogo,
  }));

  subscriptionsStore.setState({ items: all });
};

export const addNewSubscription = (item) => {
  const newSubscription = {
    ...item,
    logo: item.picThumbnailUrl ?? item.pictureUrl ?? starterLogo,
  };

  subscriptionsStore.setState((prev) => ({
    items: prev.concat(newSubscription),
  }));
};

export const useSubscriptions = (...args) => useStore(subscriptionsStore, ...args);
