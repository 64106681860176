import { walletType, currencyType } from 'enums';

const {
  COIN,
  DSCP,
  // REMOVE TAG HIDE ALL COINSAFE FUNCTIONALITY
  // COINSAFE,
  CASHSAFE,
  CASH,
  TRADING,
  TOKEN,
  BV,
  GAS,
} = walletType;

const { EURO, EURO_LETTERS, TKN, COIN: COINCURRENCY, BVP, GAS: GASCURRENCY, DSCP: DSCPCURRENCY } = currencyType;

export const currencyByType = (value, currency, letter) => {
  const amount = value || 0;
  switch (true) {
    case currency === COIN:
      // REMOVE TAG HIDE ALL COINSAFE FUNCTIONALITY
      // || currency === COINSAFE
      return `${amount} ${COINCURRENCY}`;
    case currency === CASH || currency === TRADING || currency === CASHSAFE:
      return letter ? `${amount} ${EURO_LETTERS}` : `${EURO} ${amount}`;
    case currency === TOKEN:
      return `${amount} ${TKN}`;
    case currency === GAS:
      return `${amount} ${GASCURRENCY}`;
    case currency === BV:
      return `${amount} ${BVP}`;
    case currency === DSCP:
      return `${amount} ${DSCPCURRENCY}`;
    default:
      return amount;
  }
};
