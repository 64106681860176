import { useState, useEffect, useRef } from 'react';
import hash from 'object-hash';
import { Row, Col, Button, Input, showApiError, showSuccess, inputValidation, NoResults } from 'components';
import { walletType } from 'enums';
import { useUserStore, usePackages, refreshCurrentAccountWallets, addNewPackage } from 'stores';
import { redeemPackage, getPackageByGiftCode } from 'services';
import {
  ConfirmRedeemDialog,
  LoadingModule,
  LuckyWheel,
  Module,
  FilterBar,
  UseMiningModal,
} from 'components/screens/Modules';
import { shopPageContainer } from './styles';

const Modules = () => {
  const myPackages = usePackages();
  const user = useUserStore();
  const [packages, setPackages] = useState(myPackages.items);
  const [giftCode, setGiftCode] = useState('');
  const [winPrice, setWinPrice] = useState(null);
  const [packageByGiftCode, setPackageByGiftCode] = useState({});
  const useMiningModalRef = useRef();
  const confirmModalRef = useRef();
  const tokenWallet = currentAccount?.wallets?.find((el) => el.walletType.toLowerCase() === walletType.TOKEN) || {};
  const currentAccount = user?.currentAccount;

  useEffect(() => {
    hash({ myPackages }) !== hash({ myPackages: packages }) && setPackages(myPackages?.items);
  }, [hash({ myPackages })]);

  const handleGiftCodeChange = (event) => setGiftCode(event.target.value);

  const fetchPackageByGiftCode = async () => {
    const [res, err] = await getPackageByGiftCode(giftCode.trim());
    err ? showApiError(err) : setPackageByGiftCode(res);
  };

  const redeemCode = async () => {
    const [data, err] = await redeemPackage({
      accountId: currentAccount?.id,
      giftCode: giftCode.trim(),
    });

    if (err) return showApiError(err);

    showSuccess('You have successfully redeem module.');
    setGiftCode('');

    if (data) {
      addNewPackage(data);
      data.campaignBonusCategory && setWinPrice(data);
      data.immediateMining && useMiningModalRef.current.showModal();
      confirmModalRef.current && confirmModalRef.current.closeModal();
    }
  };

  return (
    <div css={shopPageContainer}>
      {!winPrice && (
        <div className="pageHeader">
          <Row gap={32} justify="space-between">
            <Col xs={12} sm="auto">
              <h3>My Modules</h3>
            </Col>
            <Col xs={12} sm="auto">
              <div className="redeemForm">
                <Input
                  className="input"
                  value={giftCode}
                  placeholder="Redeem Gift code"
                  validate={inputValidation('required', 'lettersNumbersDash')}
                  handleChange={handleGiftCodeChange}
                />
                <Button
                  disabled={!giftCode.length}
                  onClick={() => {
                    fetchPackageByGiftCode();
                    confirmModalRef.current.showModal();
                  }}
                >
                  Redeem
                </Button>
                <ConfirmRedeemDialog
                  ref={confirmModalRef}
                  onRedeemCode={redeemCode}
                  packageByGiftCode={packageByGiftCode}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
      <UseMiningModal
        ref={useMiningModalRef}
        hasAutoMine={tokenWallet?.useAutomine}
        onSubmit={refreshCurrentAccountWallets}
      />

      {!winPrice && <FilterBar items={myPackages?.items} setPackages={setPackages} />}

      <Row gap={16}>
        {winPrice ? (
          <Col xs={12} sm="auto">
            <LuckyWheel
              onBack={() => setWinPrice(null)}
              reward={winPrice?.campaignBonusCategory}
              packageName={winPrice?.name}
            />
          </Col>
        ) : packages ? (
          packages?.length ? (
            packages?.map((el, i) => (
              <Col xl={3} lg={4} sm={6} xs={12} key={`${el.catalogueItemId}-${i}`}>
                <Module item={el} />
              </Col>
            ))
          ) : (
            <Col xs={12}>
              <NoResults emptyMessage="There are no packages from this search" />
            </Col>
          )
        ) : (
          <LoadingModule />
        )}
      </Row>
    </div>
  );
};

export default Modules;
