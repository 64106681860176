export const expireCommentContainer = (theme) => ({
  backgroundColor: theme.errorLight,
  padding: '16px 0',
  position: 'sticky',
  top: 54,
  zIndex: 100,
  width: 'calc(100% + 80px)',
  margin: '0 -40px',
  textAlign: 'center',

  '& > h4': {
    fontSize: 14,
  },
});

export const kycContainer = (theme) => ({
  padding: '32px 48px',

  '.linkText': {
    color: theme.infoDark,
    fontWeight: 600,
  },

  '.titleContainer': {
    marginBottom: 40,
  },
});

export const kycTitle = {
  marginBottom: 16,
  display: 'flex',
  flexDirection: 'row',

  '& > h3': {
    marginRight: 16,
  },
};

export const uploadedDocumentsContainer = (theme) => ({
  '.uploadedDocumentWrapper': {
    border: `1px solid ${theme.gray100}`,
    borderBottom: 'none',
    marginBottom: 48,
    backgroundColor: theme.white,
    padding: 40,

    '.uploadedDocumentBox': {
      '& > h4': {
        marginBottom: 24,
      },

      '& > p': {
        color: theme.gray300,
        marginBottom: 16,
      },
    },
  },
});

export const noDocumentsContainer = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  '.noDocumentsImage': {
    marginBottom: 16,
    width: 200,
    height: 200,
  },

  '& > h4': {
    marginBottom: 24,
  },
};
