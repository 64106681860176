import PropTypes from 'prop-types';
import { useUser } from '../UserContext';
import { guestFooterContainer } from './styles';

const GuestFooter = (props) => {
  const { className } = props;
  const { isAuthenticated } = useUser();
  const getYear = new Date().getFullYear();

  return (
    <div css={guestFooterContainer} {...(className && { className })}>
      {!isAuthenticated && (
        <p>
          &copy; OneEcosystem &nbsp;
          {getYear}. &nbsp; <span>All rights reserved. One account for all One Ecosystem products</span>
        </p>
      )}
      {isAuthenticated && (
        <p>
          &copy; OneEcosystem &nbsp;
          {getYear}. &nbsp; <span>All rights reserved. One account for all One Ecosystem products</span> &nbsp;
          <a href="https://portal.oneecosystem.eu/terms" className="terms-privacy">
            Terms & Conditions
          </a>{' '}
          &nbsp;
          <a href="https://portal.oneecosystem.eu/privacy-policy" className="terms-privacy">
            Privacy Policy
          </a>
        </p>
      )}
    </div>
  );
};

GuestFooter.propTypes = {
  className: PropTypes.string,
};

export default GuestFooter;
