import qs from 'query-string';
import { isArray } from 'lodash-es';
import { getReq, putReq } from './axios/makeRequest';

const baseUrl = `${apiUrls.onelife}/wallets`;

export const getAllWallets = (ids) => {
  const params = qs.stringify({ accountIds: isArray(ids) ? ids : [ids] }, { arrayFormat: 'index' });

  return getReq(`${baseUrl}/v2/my?${params}`);
};

export const updateAutoMine = (data) => putReq(`${baseUrl}/updateCurrentAutoMine?${qs.stringify(data)}`);
