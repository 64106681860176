import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isArray, orderBy } from 'lodash-es';
import { Row, Col, Button, showApiError, Skeleton, Image } from 'components/UI';
import { downloadKycImage } from 'services';
import { kycDocumentsMap } from './config';
import { uploadedDocumentsBoxContainer, documentStatus } from './styles';

const ProfileKycDocument = (props) => {
  const { document, onClick } = props;
  const [image, setImage] = useState(null);
  const { state, subType, createdOn, expirationDate, fileLinks, thumbnailLinks, comments } = document ?? {};
  const docState = state?.toLowerCase() ?? 'rejected';
  const docLastComment = orderBy(comments ?? [], 'date', 'desc')[0];

  useEffect(() => {
    fileLinks && fetchImage();
  }, [fileLinks && fileLinks[0]]);

  const fetchImage = async () => {
    const original = isArray(fileLinks) && fileLinks[0];
    const thumb = isArray(thumbnailLinks) && thumbnailLinks[0];
    const [res, err] = await downloadKycImage(thumb || original);
    if (err) return showApiError(err);
    setImage(URL.createObjectURL(new Blob([res])));
  };

  return (
    <div css={docState && uploadedDocumentsBoxContainer(docState)}>
      {!document ? (
        <Row gap={16} className="container">
          <Col sm={12} md={3} xl={3}>
            {image ? (
              <Image className="image" src={image} alt="" />
            ) : (
              <Skeleton style={{ width: '100%', paddingTop: '56.25%' }} />
            )}
          </Col>
          <Col sm={12} md={3} xl={3}>
            <p className="info">
              <b>Type:</b> {subType}
            </p>
            <p className="info">
              <b>Uploaded:</b> {createdOn && moment(createdOn).format('DD/MM/YYYY')}
            </p>
            <p className="info">
              <b>Expires:</b> {expirationDate && moment(expirationDate).format('DD/MM/YYYY')}
            </p>
          </Col>
          <Col sm={12} md={6} xl={6}>
            <div css={docState && documentStatus(docState)}>
              {kycDocumentsMap[docState]?.icon}
              <span className="statusName">{kycDocumentsMap[docState]?.label}</span>
            </div>

            {docLastComment && docState === 'rejected' && (
              <div>
                <p className="commentsContainerInfo">{moment(docLastComment.date).format('DD/MM/YYYY HH:mm:ss')}</p>
                <p className="comment">{docLastComment.comment}</p>
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <h4>You do not have any documents.</h4>
      )}

      <Button onClick={onClick}>Upload New Document</Button>
    </div>
  );
};

ProfileKycDocument.propTypes = {
  document: PropTypes.object,
  onClick: PropTypes.func,
};

export default ProfileKycDocument;
