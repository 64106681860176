import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { formatShortText } from 'utils';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import Image from '../../Image';
import InputContainer from '../InputContainer';
import { useFileInput } from './useFileInput';
import { inputField, uploadWrap, fileInputContent, fileInputLabel, itemImageWrap } from './styles';

const FileInput = (props) => {
  const { id, required, validate, label, accept, multiple, orderable, inputRef } = props;

  const { value, isTouched, error, onBlur, onRemoveItem, onDragOver, onDragStart, onDrop, ...inputAttr } = useFileInput(
    props,
  );

  const hasValidation = isFunction(validate) || required;

  const ContentContainer = ({ children, id: itemId }) => (
    <div
      {...(orderable &&
        multiple && {
          id: itemId,
          draggable: true,
          onDragOver: (e) => onDragOver(e),
          onDragStart: (e) => onDragStart(e),
          onDrop: (e) => onDrop(e),
        })}
      css={fileInputContent(props)}
    >
      {children}
    </div>
  );
  const contentLabel = (name) => <p css={fileInputLabel(props)}>{formatShortText(name, 25)}</p>;
  const contentImage = (image) => (
    <div css={itemImageWrap}>
      <Image src={image} width={40} height={40} background size="contain" />
    </div>
  );
  const removeIcon = (el) => (
    <div role="button" tabIndex={0} onClick={() => onRemoveItem(el)}>
      <Icon iconName="la la-trash" color="error" size={22} />
    </div>
  );

  return (
    <InputContainer {...props} error={error} isTouched={isTouched}>
      <div css={uploadWrap(hasValidation, props)}>
        {(!value || multiple) && (
          <label htmlFor={id}>
            <Button type="link" onClick={() => window.addEventListener('focus', onBlur)} className="button">
              Upload {label}
            </Button>
          </label>
        )}
        <input ref={inputRef} id={id} type="file" css={inputField} accept={accept} {...inputAttr} />
        {!!value &&
          (multiple ? (
            value.map((el) => (
              <ContentContainer id={el?.__id} key={el?.__id}>
                {removeIcon(el)}
                {contentLabel(el?.name)}
                {contentImage(el?.data ?? el?.url)}
              </ContentContainer>
            ))
          ) : (
            <ContentContainer id={value?.__id}>
              {removeIcon(value)}
              {contentLabel(value?.name)}
              {contentImage(value?.data)}
            </ContentContainer>
          ))}
      </div>
    </InputContainer>
  );
};

FileInput.propTypes = {
  inputRef: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  validate: PropTypes.func,
  pattern: PropTypes.func,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  formId: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  orderable: PropTypes.bool,
};

const InputRef = forwardRef((props, ref) => <FileInput {...props} componentRef={ref} />);

InputRef.displayName = 'FileInput';

export default InputRef;
