import { postReq, getReq } from './axios/makeRequest';
import {
  getAllActivePackagesModel,
  getAllActiveSubscriptionsModel,
  getAccountGroupedSubscriptions,
  getAllSubscriptionsParams,
  getPaymentMethodsByIdsParams,
} from './models';

const baseUrl = `${apiUrls.onelife}/packages`;

export const redeemPackage = (data) => postReq(`${baseUrl}/Packages/redeem`, data);

export const getPackageByGiftCode = (giftCode) => getReq(`${baseUrl}/Packages/getByGiftCode/${giftCode}`);

export const getMyPackages = () => getReq(`${baseUrl}/Packages/my`);

export const getPackageById = (id) => getReq(`${baseUrl}/Packages/${id}`);

export const getAllPackages = async (params) => {
  const [data, err] = await getReq(`${baseUrl}/Packages/list`, { params });
  return [data && getAllActivePackagesModel(data), err];
};

export const getAllSubscriptions = async (options) => {
  const [data, err] = await getReq(`${baseUrl}/Subscriptions/catalogueItem/list?${getAllSubscriptionsParams(options)}`);
  return [data && getAllActiveSubscriptionsModel(data), err];
};

export const getMySubscriptions = async (accountId) => {
  const [data, err] = await getReq(`${baseUrl}/Subscriptions/account/${accountId}`);
  return [data && getAccountGroupedSubscriptions(data.subscriptions), err];
};

export const getPaymentMethodsByIds = (ids, itemType) =>
  getReq(`${baseUrl}/PaymentMethods/byCatItems?${getPaymentMethodsByIdsParams(ids, itemType)}`);

export const getSubscriptionById = (id) => getReq(`${baseUrl}/Subscriptions/catalogueItem/${id}`);
