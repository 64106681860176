export const treePlacement = {
  auto: 0,
  left: 1,
  right: 2,
};

export const accountStatus = {
  Active: 'Active',
  Frozen: 'Frozen',
  Terminated: 'Terminated',
  Inactive: 'Inactive',
};
