import { createRoot } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Theme, LoadIcons, UserProvider } from 'components';
import BaseApp from './screens/App/App';
import initGlobalStyles from './assets/css/globalCSS';

const App = () => (
  <Theme initGlobalStyles={initGlobalStyles}>
    <Router>
      <UserProvider>
        <BaseApp />
      </UserProvider>

      <LoadIcons />
    </Router>
  </Theme>
);

const container = document.getElementById('my-oneecosystem-root');
const root = createRoot(container);
root.render(<App />);
