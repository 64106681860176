const MENU_WIDTH = 220;
const MENU_SMALL_WIDTH = 50;

export const mainContainer = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
};

export const sideNavContainer = (isOpen) => (theme) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.gray800,
  width: isOpen ? MENU_WIDTH : MENU_SMALL_WIDTH,
  flexShrink: 0,
  transition: 'width .3s ease-in-out',
});

export const sideNavHeader = (isOpen) => (theme) => ({
  padding: '1.5rem 1.2rem 2rem',
  overflowX: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '6.5rem',

  '&:hover': !isOpen && {
    '& > .logo-container': {
      display: 'none',
    },
    '& > .collapse-container': {
      display: 'flex',
    },
  },

  '& .logo-container': {
    overflowX: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    minWidth: '2.8rem',
  },
  '& .logo': {
    width: '2.8rem',
    minWidth: '2.8rem',
  },
  '& .logo-text': {
    marginLeft: '1.2rem',
    width: '12.0rem',
    ...(!isOpen && { display: 'none' }),
  },
  '& .collapse-container': {
    color: theme.whiteDark,
    backgroundColor: theme.gray800,
    width: '2.8rem',
    height: '2.8rem',
    cursor: 'pointer',
    borderRadius: 6,
    display: isOpen ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      display: 'flex',
      backgroundColor: theme.gray500,
      '& .icon path': {
        fill: theme.white,
      },
    },

    '& .icon': {
      width: '1.4rem',
      transform: `rotate(${isOpen ? 0 : -180}deg)`,
      transition: 'transform .3s ease-in-out',

      '& path': {
        fill: theme.gray300,
      },
    },
  },
});

export const menuListContainer = (theme) => [
  {
    overflow: 'hidden auto',
    height: '100%',
    padding: '0 0 1.2em 0',
    position: 'relative',
    boxShadow: theme.boxShadow,
    transition: 'width .3s ease-in-out',
    zIndex: 1000,
  },
];

export const subMenuList = (theme) => ({
  backgroundColor: theme.gray800,
  color: theme.gray300,
  borderRadius: 4,
  minWidth: 120,
});

export const subMenusTreeContainer = {
  padding: '0 1rem 0 3.2rem',
};

export const contentContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto hidden',
};

export const sideNavContent = {
  flex: 1,
  overflow: 'auto',
};

export const tooltipContainer = {
  display: 'block',
};

export const tooltipContent = {
  '& .tooltip-content': {
    padding: '0 0 0 0.2em',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
};

export const menuItem = (theme) => ({
  color: theme.gray300,
  backgroundColor: 'transparent',
  padding: '1em 0.9em',
  margin: '0 0.5rem',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
  borderRadius: 4,
  marginBottom: '0.2rem',
  lineHeight: 1,
  whiteSpace: 'nowrap',

  '&:hover': {
    color: theme.white,
    backgroundColor: theme.gray700,
  },

  '&:focus': {
    outline: 'none',
  },

  '& i': {
    marginRight: '0.5em',
    color: 'inherit',
  },

  '&.active': {
    color: theme.whiteDark,
    backgroundColor: theme.secondaryDarker,
    '&::before': {
      content: "''",
      backgroundColor: theme.primary,
      width: '0.3rem',
      height: 'calc(100% - 0.6rem)',
      position: 'absolute',
      left: '-0.5rem',
      borderRadius: 2,
    },
  },
});

export const menuItemContent = {
  display: 'flex',
  alignItems: 'center',
};

export const menuSubItem = (theme) => ({
  color: theme.whiteDark,
  backgroundColor: 'transparent',
  padding: '1em 0.8em',
  cursor: 'pointer',
  borderRadius: 4,
  display: 'block',
  whiteSpace: 'nowrap',

  '&:hover': {
    color: theme.white,
    backgroundColor: theme.gray700,
  },

  '&.active': {
    color: theme.white,
    fontWeight: 500,
  },
});

export const menuItemText = {
  display: 'block',
};

export const menuItemRightIcon = (theme) => ({
  color: theme.white,
  fontSize: 11,
});
