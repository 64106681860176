/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState, useRef } from 'react';
// import { Image } from 'components/UI';
// import luckyWheelBackgroundLogo from 'assets/images/lucky-wheel-background.png';
import luckyWheelPointerLogo from 'assets/images/lucky-wheel-pointer.png';
import luckyWheelGoldPlateLogo from 'assets/images/lucky-wheel-gold-plate.png';
import luckyWheelSectorsLogo from 'assets/images/lucky-wheel-sectors.png';
import { luckyWheelContainer } from './styles';

const WheelComponent = ({ winningSegment, onFinished }) => {
  const sectorsRef = useRef(null);
  const [rotateAngel, setRotateAngel] = useState(null);

  useEffect(() => {
    sectorsRef.current.addEventListener('transitionend', onFinished);

    return () => {
      sectorsRef.current.removeEventListener('transitionend', onFinished);
    };
  }, []);

  const calculateRotateAngel = () => {
    if (rotateAngel) return;
    const randomAngel = Math.floor(Math.random() * 80 + 5);
    setRotateAngel(4680 + winningSegment * 90 + randomAngel);
  };

  return (
    <div css={luckyWheelContainer}>
      <div className="luckyWheelContent">
        <img className="luckyWheelPointer" src={luckyWheelPointerLogo} alt="Wheel Pointer" />
        <img className="luckyWheelGoldPlate" src={luckyWheelGoldPlateLogo} alt="Wheel Gold Plate" />
        <img
          ref={sectorsRef}
          className="luckyWheelSectors"
          src={luckyWheelSectorsLogo}
          alt="Wheel Sectors"
          onClick={calculateRotateAngel}
          style={{
            transform: `rotate(${rotateAngel ?? 0}deg)`,
            cursor: !rotateAngel ? 'pointer' : 'not-allowed',
          }}
        />
      </div>
    </div>
  );
};

export default WheelComponent;
