import { toNumber, toString } from 'lodash-es';
import { stringToBoolean } from 'utils';
import { postReq, getReq } from './axios/makeRequest';

const baseUrl = `${apiUrls.onelife}/config`;

export const getConfigParam = async (key, type) => {
  const [res, err] = await getReq(`${baseUrl}/get`, { params: { key } });

  const checkRes = () => {
    switch (type) {
      case 'bool':
        return stringToBoolean(res.value);
      case 'string':
        return toString(res.value);
      case 'json':
        return JSON.parse(res.value);
      case 'number':
        return toNumber(res.value);
      default:
        return res;
    }
  };

  return [checkRes(res), err];
};

export const trackGeoLocation = () => postReq(`${baseUrl}/Geolocation/createGeolocationLog`);
