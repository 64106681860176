import { isFunction, omit } from 'lodash-es';
import { useStore, createStore } from 'components/UI';
import { getMyProfile, switchAccount, editMyProfile } from 'services';

const userStore = createStore(
  {
    userId: '8b729861-c542-4c2c-81c9-b4fc45a3c6bb',
    sponsorAccountId: 0,
    status: 'Active',
    firstName: 'Elica',
    lastName: 'Kehaiova',
    pictureUrl:
      'https://onelife-profile-cdn.azureedge.net/profile/pict_a96378a1-ae1c-449f-99a9-a0dae496cbfc_20210707092522642.png',
    picThumbnailUrl:
      'https://onelife-profile-cdn.azureedge.net/profile/thumb_a96378a1-ae1c-449f-99a9-a0dae496cbfc_20210707092523271.png',
    country: 'Afghanistan',
    city: 'nqma',
    zip: '1234',
    address: 'Sofia',
    dateOfBirth: '1997-01-18T00:00:00',
    cityOfBirth: 'Smolqn',
    countryOfBirth: 'Albania',
    idExpiration: '2023-05-27T00:00:00',
    idNumber: '1234',
    idType: 'Passport',
    addressExpiration: '0001-01-01T00:00:00',
    addressNumber: null,
    addressType: 'GasBill',
    imaEnabled: true,
    newsletterEnabled: true,
    ageCompliant: true,
    comments: [],
    kycs: [],
    email: 'elica.kehaiova@abv.bg',
    phone: '+359 1111112322',
    userName: 'elicaK',
    mobilePin: '7777',
    hasMobileAccess: true,
    eagleRankId: null,
    eagleRankStars: 0,
    currentAccount: {
      id: 2105,
      rank: '',
      rankPicture: '',
      topRank: 'Sapphire',
      treePlacement: 'Auto',
      isCurrent: true,
      createdOn: '2020-10-05T10:55:45.5277511',
      package: {
        id: 66,
        name: 'Immediate Mining',
        pictureUrl:
          'https://onelife-profile-cdn.azureedge.net/package/pict_bfdeb22b5e9a41abad30de192000223e_20200609133010723.png',
        picThumbnailUrl: null,
      },
      sponsorUserId: '8b729861-c542-4c2c-81c9-b4fc45a3c6bb',
      sponsorFullName: 'Elica Kehaiova',
      sponsorNickName: 'elicaK',
      uplinkId: 2104,
      nickName: 'Monkey',
      status: 'Active',
      cashWalletBalance: null,
      cashWalletBlockedBalance: null,
      coinWalletBalance: null,
      coinWalletBlockedBalance: null,
    },
    accounts: [
      {
        id: 2104,
        nickName: 'elicaK',
        isCurrent: false,
        rank: '',
        topRank: 'Sapphire',
        status: 'Active',
      },
      {
        id: 2105,
        nickName: 'Monkey',
        isCurrent: true,
        rank: '',
        topRank: 'Sapphire',
        status: 'Active',
      },
      {
        id: 2106,
        nickName: 'Boo',
        isCurrent: false,
        rank: '',
        topRank: 'Ruby',
        status: 'Active',
      },
      {
        id: 2107,
        nickName: 'Eli',
        isCurrent: false,
        rank: 'Emerald',
        topRank: 'Emerald',
        status: 'Inactive',
      },
      {
        id: 2108,
        nickName: 'kehaiova',
        isCurrent: false,
        rank: '',
        topRank: 'Diamond',
        status: 'Active',
      },
      {
        id: 3498,
        nickName: 'Mon',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3499,
        nickName: 'niki',
        isCurrent: false,
        rank: 'Black Diamond',
        topRank: 'BlackDiamond',
        status: 'Inactive',
      },
      {
        id: 3500,
        nickName: 'nikito',
        isCurrent: false,
        rank: 'Crown Diamond',
        topRank: 'CrownDiamond',
        status: 'Inactive',
      },
      {
        id: 3501,
        nickName: 'oneMonkey',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3504,
        nickName: 'kehayova',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3505,
        nickName: 'monkeyMen',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3507,
        nickName: 'sponsor2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3508,
        nickName: 'sponsor3',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Active',
      },
      {
        id: 3509,
        nickName: 'moiAccount',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3510,
        nickName: 'testTreePlacementMen',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3511,
        nickName: '3510textLeg',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3517,
        nickName: 'asdf',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3518,
        nickName: 'asdfgh',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3519,
        nickName: 'asdfg2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3520,
        nickName: 'my2104',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3521,
        nickName: '2107my',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3526,
        nickName: 'threeAccount',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3528,
        nickName: 'fiveAccount',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3530,
        nickName: 'nqmaAccount',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3531,
        nickName: 'sevenAccount',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3532,
        nickName: '8account',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3533,
        nickName: '9acconut',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3535,
        nickName: '2elica',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Active',
      },
      {
        id: 3539,
        nickName: '6elica',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3544,
        nickName: 'kuser88',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3551,
        nickName: 'DiT',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3553,
        nickName: 'Creed',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3556,
        nickName: 'testDT',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3561,
        nickName: 'DiTD',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3563,
        nickName: 'DiMTest',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3677,
        nickName: 'Elitest',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3678,
        nickName: 'elicaTest1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3679,
        nickName: 'elicaTest2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3680,
        nickName: 'elicaTest3',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3681,
        nickName: 'elicaTest4',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3682,
        nickName: 'elicaTest5',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3683,
        nickName: 'elicaTest6',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3684,
        nickName: 'elicaTest7',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3693,
        nickName: 'testeli1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3694,
        nickName: 'testeli2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3695,
        nickName: 'testeli3',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3696,
        nickName: 'testeli4',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3697,
        nickName: 'testeli5',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Active',
      },
      {
        id: 3698,
        nickName: 'testeli6',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3699,
        nickName: 'testeli7',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3722,
        nickName: 'sponsorTest2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3723,
        nickName: 'eliTestSponsor1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3724,
        nickName: 'eliTestSponsor2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3734,
        nickName: 'sponsorTest7',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3735,
        nickName: 'testAccount1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3736,
        nickName: 'testAccount2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3737,
        nickName: 'testAccount3',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3740,
        nickName: 'testAccount4',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3741,
        nickName: 'testAccount5',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3742,
        nickName: 'testAccount6',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3743,
        nickName: 'testAccount7',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3744,
        nickName: 'testAccount8',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3760,
        nickName: 'testNewTree1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3761,
        nickName: 'testNewTree2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3762,
        nickName: 'testNewTree4',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3763,
        nickName: 'testTree1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3861,
        nickName: 'testTree1PosLeft',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3889,
        nickName: 'mmy2104Test1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3890,
        nickName: 'mmy2104Test2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3917,
        nickName: 'elicaKehaiovaTest1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3918,
        nickName: 'elicaKehaiovaTest2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3919,
        nickName: 'elicaKehaiovaTest3',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3920,
        nickName: 'elicaKehaiovaTest4',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3921,
        nickName: 'elicaKehaiovaTest5',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3922,
        nickName: 'elicaKehaiovaTest6',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3923,
        nickName: 'elicaKehaiovaTest7',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3924,
        nickName: 'elicaKehaiovaTest8',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3925,
        nickName: 'elicaKehaiovaTest9',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3926,
        nickName: 'elicaKehaiovaTest10',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3927,
        nickName: 'elicaKehaiovaTest11',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3933,
        nickName: 'testWallet1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3937,
        nickName: 'Vladi',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3938,
        nickName: 'testInvitations',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3939,
        nickName: 'testInvitations2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3940,
        nickName: 'testFlow1',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3941,
        nickName: 'testFlow2',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3942,
        nickName: 'testFlow3',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3943,
        nickName: 'testFlow4',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3944,
        nickName: 'testFlow5',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3945,
        nickName: 'testFlow6',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3946,
        nickName: 'EliTestFlow',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3951,
        nickName: '21monkey',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
      {
        id: 3971,
        nickName: 'elicaTestRookie',
        isCurrent: false,
        rank: '',
        topRank: '',
        status: 'Inactive',
      },
    ],
  } ?? null,
);

export const USER_EVENT_TYPES = {
  INITIALIZE_USER: 'initUser',
  UPDATE_USER: 'updateUser',
};

const callbacks = {
  [USER_EVENT_TYPES.INITIALIZE_USER]: new Set(),
  [USER_EVENT_TYPES.UPDATE_USER]: new Set(),
};

export const subscribeForUserEvent = (type, callback) => isFunction(callback) && callbacks[type].add(callback);

export const initializeUser = async () => {
  const [user, err] = await getMyProfile();
  if (err) return err;

  if (!user) {
    // TODO: Check the error for 404 and message when the user is registered into identity and have no profile
    userStore.setState({ noProfile: true });
    return err;
  }

  if (!user?.accounts?.length) {
    // TODO: Check the response when the user is registered with profile and identity, but without accounts
    userStore.setState({ noAccounts: true });
    return err;
  }

  /*
    Check if the user has no current account or has many current accounts and set only the first to current and the rest to not current
    This scenario can be hit when the admin from portal-admin.oneecosystem.eu migrate accounts from one profile to another
    If the user has one current account and it is migrated to another user it will be left with no current account
    If the user has one current account and some account from other profile is migrated to him he will have 2 accounts which are current
  */
  const allCurrentAccounts = user?.accounts?.filter((el) => el.isCurrent);

  if (!allCurrentAccounts.length || allCurrentAccounts.length > 1) {
    switchAccount(user.accounts[0]?.id);
    user.accounts = user.accounts.map((el, ind) => ({ ...el, isCurrent: !ind }));
    user.currentAccount = { ...user.accounts[0] };
  }

  userStore.setState(omit(user, ['currentAccount', 'accounts']) ?? {});

  callbacks[USER_EVENT_TYPES.INITIALIZE_USER].forEach((callback) => callback(user));
};

export const updateUser = async (user) => {
  const [res, err] = await editMyProfile(user);
  if (err) return err;

  userStore.setState((prev) => ({ ...prev, res }));

  callbacks[USER_EVENT_TYPES.UPDATE_USER].forEach((callback) => callback(res));
};

export const useUserStore = (...args) => useStore(userStore, ...args);
