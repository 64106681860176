import { fromPairs } from 'lodash-es';
import { paymentMethodTypes } from 'enums/paymentMethods';

export const myPaymentMethodsModel = (data) => {
  const resMethods = fromPairs(
    data?.withdrawalMethods.map((el) => [paymentMethodTypes[el.type] ?? el.type, JSON.parse(el.value)]),
  );

  return resMethods;
};

export const updatePaymentMethodsModel = (type, value) => ({
  type,
  value: JSON.stringify(value),
});
