import PropTypes from 'prop-types';
import { ReactComponent as CheckIcn } from 'assets/images/icn-alert-success.svg';
import { subscriptionPaymentContainer } from './styles';

const PaymentMethodsCard = (props) => {
  const { paymentMethods = {}, catalogueItemId } = props;

  return (
    <div css={subscriptionPaymentContainer}>
      <div className="title">Payment methods</div>
      {paymentMethods && paymentMethods[catalogueItemId] && paymentMethods[catalogueItemId].length > 0 ? (
        paymentMethods[catalogueItemId].map((method, index) => (
          <div key={`method-${index}`}>
            <CheckIcn />
            <span className="data"> {method.displayName}</span>
          </div>
        ))
      ) : (
        <span>No payment methods available!</span>
      )}
    </div>
  );
};

PaymentMethodsCard.propTypes = {
  paymentMethods: PropTypes.object,
  catalogueItemId: PropTypes.number,
};

export default PaymentMethodsCard;
