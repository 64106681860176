import { commonStyles } from '../styles';

export const baseIcon = (props) => (theme) => {
  const { color, onClick, size, filter, margin, padding, bold } = props;

  return [
    {
      fontSize: size ?? 24,
      userSelect: 'none',
    },
    color && {
      color: theme[color] ?? color,
    },
    margin && {
      margin: `${margin} !important`,
    },
    padding && {
      padding: `${padding} !important`,
    },
    onClick && {
      cursor: 'pointer',
    },
    filter && {
      filter,
    },
    bold && {
      fontWeight: '900 !important',
    },
    ...commonStyles(props),
  ];
};
