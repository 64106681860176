import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './Login';
import Registration from './Registration';

export const PublicRoutes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/register" component={Registration} />
    <Redirect to="/login" />
  </Switch>
);
