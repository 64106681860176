import mapIcon from 'assets/images/map-bg.svg';

export const publicContentContainer = (theme) => ({
  width: '100%',
  height: 'calc(100vh - 88px)',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',

  '.actionColumn': {
    padding: '0 128px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '.actionButton': {
      width: '100%',
      marginBottom: 32,
    },

    '.footerContainer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },

  '.pictureContainer': {
    backgroundImage: `url(${mapIcon})`,
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.container': {
      padding: '0 112px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '.logoContainer': {
        overflowX: 'hidden',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        minWidth: '4rem',
        marginBottom: 32,

        '& .logo': {
          width: '4rem',
          minWidth: '4rem',
          marginRight: '1.2rem',
        },
        '& .logoText': {
          width: '18.0rem',
        },
      },

      '.title': {
        color: theme.white,
        marginBottom: 16,
      },
      '.subtitle': {
        color: theme.gray200,
        textAlign: 'center',
      },
    },
  },
});

export const forgotStyles = (theme) => ({
  fontSize: 13,
  color: theme.info,
  fontWeight: 400,
});

export const formContainer = {
  padding: 48,
};

export const formInputContainer = {
  marginBottom: 20,
};

export const titleForgot = (theme) => ({
  color: theme.grayDark,
  fontWeight: 600,
  marginBottom: 10,
  fontSize: 20,
});

export const backButton = (theme) => ({
  color: theme.infoDark,
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,
  border: `1px solid ${theme.infoDark}`,
  padding: '0 32px',

  '&: hover': {
    backgroundColor: theme.infoLight,
  },
});

export const submitButtonContainer = {
  display: 'flex',
  justifyContent: 'space-between',
};
