import { Route, Switch } from 'react-router-dom';
import { IdentityProfile, UserProfile, CreateAccount, SuccessRegistration, GuestFooter, GuestNavBar } from 'components';

const Registration = ({ history }) => {
  const onSuccessPage = history.location.pathname === '/register/success';

  return (
    <Switch>
      {!onSuccessPage && <GuestNavBar />}
      <Route exact path="/identity-user" component={IdentityProfile} />
      <Route exact path="/user-profile" component={UserProfile} />
      <Route exact path="/new-account" component={CreateAccount} />
      <Route exact path="/success" component={SuccessRegistration} />
      {!onSuccessPage && <GuestFooter />}
    </Switch>
  );
};

export default Registration;
