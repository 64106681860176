export const orderItemTypes = {
  Package: 'package',
  Subscription: 'subscription',
};

export const vendorNames = {
  OneLife: 'OneApp',
  OneForex: 'OneForex',
  OneVoyage: 'OneVoyage',
};

export const vendorBenefits = {
  BEST_PRICE: '110% Best Price Guarantee',
  HOTEL_POINTS: 'ONE Voyage Hotel points',
  AIRPORT_LOUNGE_ACCESS: 'Airport Lounge access',
  GROUP_RESERVATIONS: 'Concierge for groups reservations',
  TRIP_COINS: 'Trip coins',
};

export const VOYAGE_BENEFIT_TYPES = {
  6: {
    AIRPORT_LOUNGE_ACCESS: '4 times free access',
  },
  12: {
    AIRPORT_LOUNGE_ACCESS: 'Unlimited Access',
  },
};
