import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useNavigationControls } from '../NavigationContext';
import { contentContainer, sideNavContent } from './styles';

const Content = ({ notFoundComponent, homeRoute, topNavigation }) => {
  const { allRoutes } = useNavigationControls();
  const TopNav = topNavigation;
  const [routesAndRedirects, setRoutesAndRedirects] = useState({
    routes: [],
    redirects: [],
  });

  useEffect(() => {
    const routes = [];
    const redirects = [];
    allRoutes.forEach((el) => {
      if (el?.component && el?.url) routes.push(el);
      if (el?.redirectsTo && el?.url) redirects.push(el);
    });
    setRoutesAndRedirects({ routes, redirects });
  }, [allRoutes]);

  return (
    <section css={contentContainer}>
      {TopNav && <TopNav />}
      <article css={sideNavContent}>
        <Switch>
          {routesAndRedirects.routes?.map((route, i) => (
            <Route key={`${route.url}-${i}`} exact={route.exact} path={route.url} component={route.component} />
          ))}
          {routesAndRedirects.redirects?.map((el) => (
            <Redirect key={el.url} exact from={el.url} to={el.redirectsTo} />
          ))}
          {homeRoute && <Redirect exact from="/" to={homeRoute} />}
          {notFoundComponent && <Route component={notFoundComponent} />}
        </Switch>
      </article>
    </section>
  );
};

Content.propTypes = {
  notFoundComponent: PropTypes.func,
  homeRoute: PropTypes.string,
  topNavigation: PropTypes.any,
};

export default Content;
