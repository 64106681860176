import PropTypes from 'prop-types';
import { Button } from 'components/UI';
import { STATUS_TYPES } from 'enums';
import { statusIndicatorMap } from './config';
import { statusIndicatorContainer } from './styles';

const StatusIndicator = ({ onSuccess, onFail, status, message, successButtonText, failureButtonText }) => (
  <div css={statusIndicatorContainer}>
    <div className="statusIndicatorContent">
      <div className="icon">{statusIndicatorMap[status]?.icon}</div>
      <h4>{statusIndicatorMap[status]?.massage}</h4>
      {message && <p>{message}</p>}
    </div>
    {status !== STATUS_TYPES.WAIT && (
      <Button onClick={status === STATUS_TYPES.SUCCESS ? onSuccess : onFail}>
        {status === STATUS_TYPES.SUCCESS ? successButtonText : failureButtonText}
      </Button>
    )}
  </div>
);

StatusIndicator.propTypes = {
  status: PropTypes.string,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  message: PropTypes.string,
  successButtonText: PropTypes.string,
  failureButtonText: PropTypes.string,
};

export default StatusIndicator;
