import { keyframes } from '@emotion/react';

const circleAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const svgAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const loader = (size, { color, fullScreen }) => (theme) => [
  {
    width: size,
    height: size,
    position: 'absolute',
    top: `calc(50% - ${size / 2}px)`,
    left: `calc(50% - ${size / 2}px)`,
    zIndex: 1,
    animation: `${svgAnimation} 2s linear infinite`,

    '& .path': {
      stroke: theme.primary,
      strokeLinecap: 'round',
      strokeWidth: '8px',
      animation: `${circleAnimation} 1.5s ease-in-out infinite`,
    },
  },
  fullScreen && {
    position: 'fixed',
  },
  color && {
    '& .path': {
      stroke: theme[color],
    },
  },
];
