import { isNumber, isString } from 'lodash-es';
import { commonStyles } from '../styles';

const NUMBER_OF_COLUMNS = 12;

export const containerStyle = ({ gap, horizontalGap = 0 }) => ({
  paddingLeft: gap ?? horizontalGap,
  paddingRight: gap ?? horizontalGap,
});

export const row = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
};

export const rowComp = (props) => {
  const { noWrap, gap, horizontalGap, verticalGap } = props;

  return [
    row,
    {
      flexDirection: 'row',
      flexWrap: noWrap ? 'no-wrap' : 'wrap',
    },
    gap && {
      margin: `-${gap}px`,
    },
    horizontalGap && {
      marginLeft: `-${horizontalGap}px`,
      marginRight: `-${horizontalGap}px`,
    },
    verticalGap && {
      marginTop: `-${verticalGap}px`,
      marginBottom: `-${verticalGap}px`,
    },
    ...commonStyles(props),
  ];
};

const baseCol = {
  flexBasis: '100%',
  maxWidth: '100%',
  flexShrink: 0,
  outline: 'none',
};

const breakPointStyles = (type, px, isMax) => ({
  [`@media (${isMax ? 'max' : 'min'}-width: ${px}px)`]:
    type === 'auto'
      ? {
          flex: '0 0 auto',
          maxWidth: 'auto',
        }
      : isNumber(type)
      ? {
          flexBasis: `${100 / (NUMBER_OF_COLUMNS / type)}%`,
          maxWidth: `${100 / (NUMBER_OF_COLUMNS / type)}%`,
        }
      : { flex: '1 0 0%' },
});

export const col = baseCol;

export const colComp = (props) => {
  const { xs, sm, md, lg, xl, gap, verticalGap, horizontalGap } = props;

  return [
    baseCol,
    xs && breakPointStyles(xs, 575, true),
    sm && breakPointStyles(sm, 576),
    md && breakPointStyles(md, 768),
    lg && breakPointStyles(lg, 992),
    xl && breakPointStyles(xl, 1200),
    (isNumber(gap) || isString(gap)) && {
      padding: gap,
    },
    (isNumber(verticalGap) || isString(verticalGap)) && {
      paddingTop: verticalGap,
      paddingBottom: verticalGap,
    },
    (isNumber(horizontalGap) || isString(horizontalGap)) && {
      paddingLeft: horizontalGap,
      paddingRight: horizontalGap,
    },
    ...commonStyles(props),
  ];
};
