import { fromPairs, isUndefined } from 'lodash-es';
import { Skeleton, Button, ColorBatch } from 'components/UI';
import { currencyByType } from 'utils';
import { accountStatusMap } from './config';
import { accountBoxContainer } from './styles';

const loaderProps = {
  width: '100%',
  height: '23px',
  style: { marginBottom: '24px' },
};

const AccountBox = (props) => {
  const { account, isCurrent, wallets, onClick } = props;

  const ballances = fromPairs(wallets.map((el) => [el.walletType, currencyByType(el.balance, el.walletType)]));

  const walletLabels = [
    { label: 'Onecoin', value: ballances.coin },
    { label: 'Cash', value: ballances.cash },
    { label: 'Promotional Tokens', value: ballances.token },
    { label: 'Trading', value: ballances.trading },
    { label: 'Gas', value: ballances.gas },
  ];

  return (
    <div css={accountBoxContainer(isCurrent)}>
      {isCurrent && (
        <div className="accountBoxCurrentLabel">
          <span>Current</span>
        </div>
      )}
      <div className="accountBoxHeader">
        <div className="accountBoxHeaderContainer">
          <p className="accountBoxHeaderId">#{account?.id}</p>
          <ColorBatch type={accountStatusMap[account?.status]?.color}>
            {accountStatusMap[account?.status]?.text}
          </ColorBatch>
        </div>
        {!isUndefined(account?.nickName) ? (
          <h4 className="accountBoxHeaderType">{account?.nickName}</h4>
        ) : (
          <Skeleton height="12px" width="70px" />
        )}
        {ballances.coin ? (
          <h3 className="accountBoxHeaderBalance">{ballances.coin}</h3>
        ) : (
          <Skeleton height="24px" width="70px" />
        )}
      </div>
      <div className="accountBoxBody">
        <p className="accountBoxBodyTitle">Details</p>

        {wallets?.length ? (
          walletLabels.map((walletLabel) => (
            <div className="descriptionWrapper" key={walletLabel.label}>
              <p className="descriptionLabel">{walletLabel.label}</p>
              <p className="descriptionValue">{walletLabel.value}</p>
            </div>
          ))
        ) : (
          <Skeleton count={5} {...loaderProps} />
        )}
        <p className="accountBoxBodyTitle">Sponsor</p>
        {account?.uplinkId ? (
          <div className="descriptionWrapper">
            <p className="descriptionLabel">Account Id</p>
            <p className="descriptionValue">{account?.uplinkId}</p>
          </div>
        ) : (
          <Skeleton {...loaderProps} marginBottom={8} />
        )}
        {account?.sponsorFullName ? (
          <div className="descriptionWrapper">
            <p className="descriptionLabel">Sponsor Name</p>
            <p className="descriptionValue">{account?.sponsorFullName}</p>
          </div>
        ) : (
          <Skeleton {...loaderProps} marginBottom={16} />
        )}
        {!isCurrent && (
          <div className="actionContainer">
            <Button type="secondary" small onClick={onClick} className="button">
              Select
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountBox;
