import { commonStyles } from '../styles';

export const colorStatus = (props) => (theme) => {
  const { type = 'primary' } = props;

  const colorStatusTypeMap = {
    primary: {
      color: theme.white,
      backgroundColor: theme.primary,
    },
    info: {
      color: theme.info,
      backgroundColor: theme.infoLight,
    },
    blue: {
      color: theme.white,
      backgroundColor: theme.info,
    },
    error: {
      color: theme.error,
      backgroundColor: theme.errorLight,
    },
    red: {
      color: theme.white,
      backgroundColor: theme.error,
    },
    success: {
      color: theme.white,
      backgroundColor: theme.successLight,
    },
    green: {
      color: theme.white,
      backgroundColor: theme.success,
    },
    warning: {
      color: theme.white,
      backgroundColor: theme.warningLight,
    },
    orange: {
      color: theme.white,
      backgroundColor: theme.warning,
    },
  };

  return [
    colorStatusTypeMap[type],
    {
      display: 'inline-block',
      fontWeight: 500,
      padding: '4px 12px',
      borderRadius: 4,
      height: '100%',
      fontSize: '1.2rem',
    },
    ...commonStyles(props),
  ];
};
