import { useEffect, useRef, useState } from 'react';
import { Row, Col, Table, showError } from 'components/UI';
import {
  BankPaymentCard,
  BitcoinPaymentCard,
  ChinaUnionPayCard,
  UsdtPaymentCard,
} from 'components/screens/PaymentDetails';
import { withdrawalMethodsColumnsDefinition } from './config';
import { myPaymentMethod, myPaymentMethodsHistory } from 'services';
import { paymentMethodsContainer, tableTitle } from './styles';

const PaymentDetails = () => {
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [edit, setEdit] = useState({
    editBitcoin: false,
    editUsdt: false,
    editBank: false,
    editChinaPay: false,
  });
  const tableRef = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [res, err] = await myPaymentMethod();
    if (err) showError(err);
    setPaymentMethods(
      {
        btc: {
          addressNumber: 'ehoo',
        },
        bankDetails: {
          accountName: 'Elica Kehaiova',
          bankName: 'KBC Bank',
          bankCountry: 'Bulgaria',
          ibanAccount: 'GB29NWBK60161331926819',
          bic: 'dsae2sad',
        },
      } ?? res,
    );
  };

  const handleEditBitcoin = () => setEdit((prev) => ({ editBitcoin: !prev.editBitcoin }));

  const handleEditUsdt = () => setEdit((prev) => ({ editUsdt: !prev.editUsdt }));

  const handleEditBank = () => setEdit((prev) => ({ editBank: !prev.editBank }));

  const handleEditChinaPay = () => setEdit((prev) => ({ editChinaPay: !prev.editChinaPay }));

  return (
    paymentMethods && (
      <div css={paymentMethodsContainer}>
        <Row gap={24}>
          <Col sm={12} xl={3}>
            <BitcoinPaymentCard
              edit={edit}
              editBitcoin={handleEditBitcoin}
              paymentMethods={paymentMethods}
              tableRef={tableRef}
              handleEdit={setEdit}
            />
          </Col>
          <Col sm={12} xl={3}>
            <UsdtPaymentCard
              edit={edit}
              editUsdt={handleEditUsdt}
              paymentMethods={paymentMethods}
              tableRef={tableRef}
              handleEdit={setEdit}
            />
          </Col>
          <Col sm={12} xl={6}>
            <BankPaymentCard
              edit={edit}
              editBank={handleEditBank}
              paymentMethods={paymentMethods}
              tableRef={tableRef}
              handleEdit={setEdit}
            />
          </Col>
          <Col sm={12} xl={6}>
            <ChinaUnionPayCard
              edit={edit}
              editChinaPay={handleEditChinaPay}
              paymentMethods={paymentMethods}
              tableRef={tableRef}
              handleEdit={setEdit}
            />
          </Col>
          <Col sm={12} xl={12}>
            <h3 css={tableTitle}>Withdrawal methods history</h3>
            <Table
              ref={tableRef}
              pageSize={10}
              hasPagination
              getDataMethod={myPaymentMethodsHistory}
              columns={withdrawalMethodsColumnsDefinition}
              emptyMessage="No Withdrawal methods History to Show"
            />
          </Col>
        </Row>
      </div>
    )
  );
};

export default PaymentDetails;
