import { useImperativeHandle, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/UI';
import { ReactComponent as CloseIcn } from 'assets/images/icn-close.svg';
import { uploadDocsModalContainer } from './styles';

const ProfileKycInformation = forwardRef((props, ref) => {
  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  return (
    <Modal ref={modalRef} onClose={modalRef.current?.close()} closeIcon={<CloseIcn />} css={uploadDocsModalContainer}>
      <div>
        <h3 className="title">KYC Process Information</h3>
        <div className="modalBodyLarge">
          <p>
            <span className="spanStyle">
              Please be informed that KYC documents <strong>can&nbsp;</strong>
              be uploaded only after all fields on menu{' '}
              <strong>
                <em>My </em>
                <em>Profile &gt; </em>
                <em>Profile </em>
              </strong>
              &nbsp;are filled in&nbsp;correctly.
            </span>
          </p>
          &nbsp;
          <p>
            <span className="spanStyle">
              Otherwise the following message on menu{' '}
              <em>
                <em>My Profile &gt; KYC </em>
              </em>
              will be displayed in <span className="redText">red</span>:{' '}
              <em>
                <em>“</em>
              </em>
              <strong>
                <span className="redText">
                  <em>
                    <span className="underlineText">
                      <em>Please check and fill in all fields on menu My Profile &gt; Profile</em>
                    </span>
                  </em>
                </span>
              </strong>
              <em>
                <em>”</em>
              </em>
              &nbsp;
            </span>
          </p>
          &nbsp;
          <ul>
            <li>
              <span className="spanStyle">
                If you have not uploaded KYC documents - On menu{' '}
                <strong>
                  <em>
                    <em>My Profile &gt; KYC &gt; Section KYC STATUS message </em>
                  </em>
                </strong>
                will be displayed: “<strong>Currently you do not have uploaded KYC documents</strong>
                <em>
                  <em>”</em>
                </em>
              </span>
            </li>
          </ul>
          &nbsp;
          <p>
            <span className="size">
              <strong>
                <span className="font">
                  In order to comply with our legal and regulatory requirements only the following KYC documents are
                  accepted:
                </span>
              </strong>
            </span>
          </p>
          &nbsp;
          <p className="leftAlign">
            <span className="size">
              <strong>
                <span className="font">Proof of Identity documents:</span>
              </strong>
            </span>
          </p>
          <table className="table">
            <tbody>
              <tr>
                <td width={291}>
                  <p>
                    <strong>
                      <span className="spanStyle">Accepted:</span>
                    </strong>
                  </p>
                  &nbsp;
                  <p>
                    <span className="spanStyle">·&nbsp;National ID card - all sides must be scanned in one file</span>
                  </p>
                  <p>
                    <span className="spanStyle">·&nbsp;National Passport</span>
                  </p>
                  <p>
                    <span className="spanStyle">·&nbsp;International Passport</span>
                  </p>
                  <p>
                    <span className="spanStyle">·&nbsp;Driving Licenses</span>
                  </p>
                  &nbsp; &nbsp;
                  <p>
                    <strong>
                      <span className="spanStyle">
                        <span className="redText">
                          <em>
                            <em>Important</em>
                          </em>
                        </span>
                        <em>
                          <em>:</em>
                        </em>
                        &nbsp;
                        <em>
                          <span className="underlineWithoutColor">
                            <em>The whole page of the Identity document must be scanned</em>
                          </span>
                        </em>
                      </span>
                    </strong>
                  </p>
                </td>
                <td width={352}>
                  <p>
                    <span className="spanStyle">
                      <strong>
                        <span className="blackText">
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <br />
                        </span>
                      </strong>
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      <strong>
                        <span className="blackText">
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <br />
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; NOT
                        </span>{' '}
                        Accepted
                      </strong>
                      :<br />
                      <br />
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Residence permits</span>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Donor ID cards</span>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;TAX ID cards</span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Voter ID cards (*except for Members from&nbsp;Countries
                      in which &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;this is the{' '}
                      <strong>mandatory&nbsp;</strong>
                      <strong>nationally introduced</strong>&nbsp;and &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <strong>accepted</strong>&nbsp;form of personal identification),
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Travel documents,</span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Temporary issued Identification
                      <br />
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; documents,&nbsp;etc.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width={291}>&nbsp;</td>
                <td width={352}>&nbsp;</td>
              </tr>
            </tbody>
          </table>
          <p>
            <span className="spanStyle">&nbsp;</span>
            <strong>
              <span className="spanStyle">Requirements:</span>
            </strong>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="spanStyle">The following details must be clearly visible:</span>
          </p>
          <ul>
            <li>
              <span className="spanStyle">Personal photo</span>
            </li>
            <li>
              <span className="spanStyle">All personal names</span>
            </li>
            <li>
              <span className="spanStyle">Date of birth</span>
            </li>
            <li>
              <span className="spanStyle">Document serial number</span>
            </li>
            <li>
              <span className="spanStyle">
                Expiry date of the document(if available for the respective document) –{' '}
                <em>
                  <em>In case of extended validity, please upload </em>
                </em>
                <em>
                  <em>
                    <strong>in one file</strong>{' '}
                  </em>
                </em>
                <em>
                  <em>the main &nbsp;identity page + the page with the extended validity</em>
                </em>
              </span>
            </li>
            <li>
              <span className="spanStyle">
                Machine readable zone (
                <em>
                  <em>
                    if available - these are the encoded in optical character recognition format lines at the bottom of
                    the identity page of the document
                  </em>
                </em>
                )
              </span>
            </li>
          </ul>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="spanStyle">
              If your Identity Document{' '}
              <strong>
                <span className="underlineWithoutColor">has no expiry date</span>
              </strong>
              &nbsp;then on menu <strong>My Profile &gt; Profile</strong> &gt;{' '}
              <em>
                <em>PASSPORT/ID CARD EXPIRY DATE</em>
              </em>
              &nbsp;
              <strong>
                fill in <span className="underlineWithoutColor">31.12.9999</span>
              </strong>
              &nbsp;in the following format <strong>DD.MM.YYYY</strong>.
            </span>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <strong>
              <span className="spanStyle">
                <span className="redText">
                  <em>
                    <em>Important</em>
                  </em>
                </span>
                <em>
                  <em>:</em>
                </em>
                <em>
                  <em>&nbsp;</em>
                </em>
                <em>
                  <span className="underlineWithoutColor">
                    <em>
                      Only clearly scanned high-resolution images having all details visible will be accepted. KYC
                      documents should not contain visible pixels or dot patterns.{' '}
                    </em>
                  </span>
                </em>
                <em>
                  <span className="underlineWithoutColor">
                    <em>All scanned KYC documents must be in color.</em>
                  </span>
                </em>
              </span>
            </strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <span className="size">
              <strong>
                <span className="font">Proof of Address documents</span>
              </strong>
            </span>
          </p>
          <table width={886}>
            <tbody>
              <tr>
                <td width={321}>
                  <p>
                    <strong>
                      <span className="spanStyle">
                        Accepted:
                        <br />
                        <br />
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp;</span>
                    <strong>
                      <span className="spanStyle">
                        <span className="underlineWithoutColor">Please upload</span>
                        <span className="underlineWithoutColor">&nbsp;only one of the following documents:</span>
                      </span>
                    </strong>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp;</span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      ·&nbsp;Utility bills (electricity, gas, water, waste, etc.){' '}
                      <strong>less than 3&nbsp;months old</strong>;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      ·&nbsp;Document issued by a Bank <strong>less than 3&nbsp;months old</strong>;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      ·&nbsp;Document issued by&nbsp;Municipality/Government Agency/Tax Authorities -{' '}
                      <strong>most</strong> <strong>recent</strong>;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      ·&nbsp;<strong>All</strong> <strong>sides</strong> of a <strong>NOT</strong>&nbsp;expired National
                      ID Card/Passport (
                      <em>
                        <em>
                          <strong>main identity page + address</strong> <strong>page</strong>
                        </em>
                      </em>
                      <em>
                        <em>&nbsp;</em>
                      </em>
                      <em>
                        <em>uploaded </em>
                      </em>
                      <strong>
                        <em>
                          <em>in one file</em>
                        </em>
                      </strong>
                      ) where the <strong>names</strong>&nbsp;and the <strong>detailed</strong> <strong>address</strong>{' '}
                      <em>
                        <em>(including Street name and Street Number) </em>
                      </em>
                      are&nbsp;<strong>shown in the document</strong>;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      ·&nbsp;Other documents issued by the Government where the <strong>names</strong>&nbsp;and the{' '}
                      <strong>detailed</strong> <strong>address</strong> are&nbsp;<strong>shown</strong>&nbsp;(for
                      example: <strong>NOT</strong> expired: Residence permits, Driving licenses, Voter ID cards;
                      National/Municipal Residential Agencies or Registers;
                    </span>
                  </p>
                </td>
                <td width={322}>
                  <p>
                    <strong>
                      <span className="spanStyle">
                        <br />
                        <br />
                        <br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; NOT Accepted:
                      </span>
                    </strong>
                    <strong>
                      <span className="spanStyle">
                        &nbsp; &nbsp;&nbsp;
                        <br />
                        <br />
                      </span>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                      <span className="decorationFontSize"> We do NOT Accept as Proof of</span>
                      <span className="underlineWithoutColor">
                        <span className="decorationFontSize">&nbsp;</span>
                      </span>
                      <span className="decorationFontSize">Address the below &nbsp; </span>
                      <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>{' '}
                      <span className="decorationFontSize">documents:</span>
                    </strong>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Invoices from Telecommunication &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; companies;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Courier/Postal&nbsp;receipts;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Documents issued from:</span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ◦&nbsp;Pension funds;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ◦&nbsp;Insurance agencies;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ◦&nbsp;Securities and Investment Funds;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ◦&nbsp;Companies providing money transfer
                      services &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (Western union,
                      Moneygram, IRemit, etc.);
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ◦&nbsp;Schools, Colleges, Universities;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Receipt/Invoices from stores, &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; including e-shops; Clinics/Hospitals; Pharmacies;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Credit/debit card statements issued from store &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; chains;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Taxes for pets, vehicles;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Rental/Lease agreements;
                    </span>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Driving fines/tickets;</span>
                  </p>
                  <p>
                    <span className="spanStyle">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ·&nbsp;Notary deeds, etc.</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="decorationFontSize">
              <strong>Requirements</strong>:
            </span>
          </p>
          <p>
            <span className="spanStyle">The following details must be clearly visible:</span>
          </p>
          <ul>
            <li>
              <span className="spanStyle">Issue date</span>
            </li>
            <li>
              <span className="spanStyle">Issuing authority’s official letter head/logo</span>
            </li>
            <li>
              <span className="spanStyle">Personal names of the Member</span>
            </li>
            <li>
              <span className="spanStyle">
                Detailed address(Country,&nbsp;City,&nbsp;Zip (Postal) Code, Street name,&nbsp;Street number,&nbsp;)
              </span>
            </li>
          </ul>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <strong>
              <span className="spanStyle">
                <span className="redText">
                  <em>
                    <em>Important</em>
                  </em>
                </span>
                <em>
                  <em>:</em>
                </em>
                <em>
                  <em>&nbsp;</em>
                </em>
                <em>
                  <span className="underlineWithoutColor">
                    <em>
                      Only clearly scanned high-resolution images having all details visible will be accepted. KYC
                      documents should not contain visible pixels or dot patterns.{' '}
                    </em>
                  </span>
                </em>
                <em>
                  <span className="underlineWithoutColor">
                    <em>All scanned KYC documents must be in color.</em>
                  </span>
                </em>
              </span>
            </strong>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <strong>
              <span className="decorationFontSize">KYC documents MUST meet the following criteria:</span>
            </strong>
          </p>
          <ol>
            <li>
              <span className="spanStyle">
                <strong>Handwritten</strong> documents are <strong>not</strong> <strong>accepted</strong>, even if there
                is a signature &amp; stamp
              </span>
            </li>
            <li>
              <span className="spanStyle">
                All submitted KYC documents must be <strong>written with Latin letters</strong>. Otherwise only an{' '}
                <strong>official translation</strong> in English&nbsp;of the document signed and stamped by a certified
                translator uploaded{' '}
                <strong>
                  <span className="underlineWithoutColor">together with</span>
                </strong>
                &nbsp;the copy of the <strong>original</strong>&nbsp;document in&nbsp;
                <strong>
                  <span className="underlineWithoutColor">one file</span>
                </strong>
                &nbsp;must be provided.
              </span>
            </li>
            <li>
              <span className="spanStyle">
                <strong>All information&nbsp;</strong>filled&nbsp;on menu My Profile &gt; Profile must{' '}
                <strong>completely</strong>&nbsp;
                <strong>match</strong> the details displayed in the uploaded KYC documents.
              </span>
            </li>
            <li>
              <span className="spanStyle">
                Please note that we <strong>DON`T&nbsp;</strong>accept identical documents as a proof of identity and
                proof of address(*example* - If{' '}
                <strong>
                  <span className="underlineWithoutColor">Passport</span>
                </strong>
                &nbsp;is uploaded for proof of identity it cannot be used as a proof of address, etc.)
              </span>
            </li>
            <li>
              <span className="spanStyle">
                Photocopied documents for proof of ID are <strong>NOT</strong> accepted <strong>if they`are not</strong>{' '}
                appropriately <span className="underlineWithoutColor">certified/notarized (signed and stamped).</span>
              </span>
            </li>
            <li>
              <span className="spanStyle">
                Black and white images of the document for proof of identity are <strong>NOT</strong> accepted. The
                documents for Proof of Identity must be <strong>coloured</strong> with all details clearly readable and
                visible. If black and white images are uploaded they <strong>MUST</strong> be appropriately{' '}
                <span className="underlineWithoutColor">certified/notarized (signed and stamped)</span>.
              </span>
            </li>
            <li>
              <span className="spanStyle">
                Acceptable documents formats are: <strong>PDF, JPEG (.jpg) and PNG (.png)</strong>
              </span>
            </li>
            <li>
              <span className="spanStyle">
                Max file size of the documents must be <strong>up to 2MB</strong>
              </span>
            </li>
          </ol>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="decorationFontSize">
              <strong>NOTES</strong>:
            </span>
          </p>
          <ol>
            <li>
              <span className="spanStyle">
                Uploaded documents cannot be deleted, they can only bereplaced with new ones.
              </span>
            </li>
            <li>
              <span className="spanStyle">
                Due to security reasons KYC documents cannot be viewed and/or downloaded.
              </span>
            </li>
            <li>
              <span className="spanStyle">
                Members can make <strong>cash</strong> <strong>transfers</strong> between accounts&nbsp;from{' '}
                <strong>different</strong> <strong>countries</strong>
                &nbsp;only if the both accounts&nbsp;(
                <em>
                  <em>the receiver and the sender</em>
                </em>
                ) are in status <strong>Approved</strong>.
              </span>
            </li>
            <li>
              <span className="spanStyle">
                <strong>Withdrawal</strong> <strong>requests</strong> are processed only for&nbsp;IMAs with{' '}
                <strong>approved</strong>
                &nbsp;KYC documents.
              </span>
            </li>
            <li>
              <span className="spanStyle">
                <strong>Changes of the KYC status upon newupload of KYC documents</strong>:
              </span>
              <br />
              <span className="spanStyle">- if the current status is “Approved” it will be changed to “Declined”.</span>
              <br />
              <span className="spanStyle">
                - if the current status is “Pending/Conditionally Approved” it will remain unchanged
              </span>
              <br />
              <span className="spanStyle">
                - if the current status is “Declined” it will be changed to “Pending/Conditionally Approved”
              </span>
            </li>
            <li>
              <span className="spanStyle">
                <strong>Changes of the KYC status</strong> in case of <strong>editing</strong> the filled in personal
                details:
              </span>
              <br />
              <span className="spanStyle">- if the current status is “Approved” it will be changed to “Declined”.</span>
              <br />
              <span className="spanStyle">
                - if the current status is “Pending/Conditionally Approved” it will remain unchanged
              </span>
              <br />
              <span className="spanStyle">- if the current status is “Declined” it will remain unchanged</span>
            </li>
            <li>
              <span className="spanStyle">
                Provision of KYC documents displaying personal details different from the account holder’s is strictly
                prohibited.
              </span>
            </li>
            <li>
              <span className="spanStyle">
                <span className="underlineWithoutColor">
                  <strong>Only for the Chinese Members</strong>:
                </span>
                If the documents are not in English or officially translated in English a{' '}
                <strong>Translation Fee of 25 EUR</strong>
                &nbsp;is collected directly from the Cash Account.
              </span>
            </li>
          </ol>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="spanStyle">
              <span className="redText">
                <strong>
                  <em>
                    <em>Important</em>
                  </em>
                </strong>
              </span>
              :
              <em>
                <em>&nbsp;</em>
              </em>
              <span className="underlineWithoutColor">
                <strong>Only for the Chinese Members</strong>:{' '}
              </span>
              <em>
                <span className="underlineWithoutColor">
                  <em>In case any </em>
                </span>
              </em>
              <strong>
                <em>
                  <span className="underlineWithoutColor">
                    <em>inconsistencies </em>
                  </span>
                </em>
              </strong>
              <em>
                <span className="underlineWithoutColor">
                  <em>are found upon </em>
                </span>
              </em>
              <strong>
                <em>
                  <span className="underlineWithoutColor">
                    <em>review</em>
                  </span>
                </em>
              </strong>
              <em>
                <span className="underlineWithoutColor">
                  <em>&nbsp;of any </em>
                </span>
              </em>
              <strong>
                <em>
                  <span className="underlineWithoutColor">
                    <em>uploaded certified translated document </em>
                  </span>
                </em>
              </strong>
              <em>
                <span className="underlineWithoutColor">
                  <em>
                    we are obliged to establish the undoubted authenticity of the provided document before any
                    subsequent verification
                  </em>
                </span>
              </em>
              <em>
                <span className="underlineWithoutColor">
                  <em>. </em>
                </span>
              </em>
              <em>
                <span className="underlineWithoutColor">
                  <em>For which reason </em>
                </span>
              </em>
              <strong>
                <em>
                  <span className="underlineWithoutColor">
                    <em>a fee of 25 EUR</em>
                  </span>
                </em>
              </strong>
              <em>
                <span className="underlineWithoutColor">
                  <em>&nbsp;will be </em>
                </span>
              </em>
              <em>
                <span className="underlineWithoutColor">
                  <em>collected directly from the Cash Account</em>
                </span>
              </em>
              <em>
                <span className="underlineWithoutColor">
                  <em>.</em>
                </span>
              </em>
            </span>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="size">
              <strong>
                <span className="font">How to use the „Use KYC from Username” option </span>
              </strong>
            </span>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="spanStyle">
              Please have in mind that using{' '}
              <strong>
                <em>
                  <em>Section </em>
                </em>
              </strong>
              <em>
                <em>“</em>
              </em>
              <em>
                <em>
                  <strong>Use KYC from Username</strong>“
                </em>
              </em>
              &nbsp;for uploading&nbsp;KYC Documents can <strong>speed up</strong>
              &nbsp;your account verification, provided that you have <strong>more than 1</strong>&nbsp;registered
              OneLife account and <strong>at least 1</strong>&nbsp;OneLife account which is already in Status:{' '}
              <strong>Approved</strong>.
            </span>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="spanStyle">
              <span className="redText">
                <strong>
                  <em>
                    <em>Important</em>
                  </em>
                </strong>
              </span>
              :{' '}
              <em>
                <span className="underlineWithoutColor">
                  <em>The username you can use must be </em>
                </span>
              </em>
              <strong>
                <em>
                  <span className="underlineWithoutColor">
                    <em>only in Status: Approved</em>
                  </span>
                </em>
              </strong>
              <em>
                <span className="underlineWithoutColor">
                  <em>&nbsp;or </em>
                </span>
              </em>
              <strong>
                <em>
                  <span className="underlineWithoutColor">
                    <em>Pending/Conditionally Approved</em>
                  </span>
                </em>
              </strong>
              <em>
                <span className="underlineWithoutColor">
                  <em>.</em>
                </span>
              </em>
            </span>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <strong>
              <span className="decorationFontSize">Step 1:</span>
            </strong>
          </p>
          <p>
            <span className="spanStyle">
              Please make sure that <strong>all details</strong>&nbsp;in <strong>all corresponding fields</strong> on
              menu My Profile &gt; Profile <strong>match</strong> <strong>completely</strong>.
            </span>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <strong>
              <span className="decorationFontSize">Step 2:</span>
            </strong>
          </p>
          <p>
            <span className="spanStyle">In field</span>
            <strong>
              <span className="spanStyle">
                {' '}
                “
                <em>
                  <em>Use KYC from Username</em>
                </em>
                ”{' '}
              </span>
            </strong>
            <span className="spanStyle">write your OneLife account&nbsp;username</span>{' '}
            <span className="spanStyle">with</span>
            <strong>
              <span className="spanStyle"> already uploaded&nbsp;</span>
            </strong>
            <span className="spanStyle">KYC documents.</span>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <span className="spanStyle">
              <span className="redText">
                <strong>
                  <em>
                    <em>Important</em>
                  </em>
                </strong>
              </span>
              :{' '}
              <em>
                <span className="underlineWithoutColor">
                  <em>The username you enter </em>
                </span>
              </em>
              <strong>
                <em>
                  <span className="underlineWithoutColor">
                    <em>cannot</em>
                  </span>
                </em>
                <em>
                  <span className="underlineWithoutColor">
                    <em>&nbsp;</em>
                  </span>
                </em>
                <em>
                  <span className="underlineWithoutColor">
                    <em>be the same username</em>
                  </span>
                </em>
              </strong>
              <em>
                <span className="underlineWithoutColor">
                  <em>&nbsp;as the account you are currently logged into.</em>
                </span>
              </em>
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span className="decorationFontSize">
              <strong>Step 3</strong>:
            </span>
          </p>
          <p>
            <span className="spanStyle">
              In field “
              <strong>
                <em>
                  <em>Password</em>
                </em>
              </strong>
              ” write the <strong>login</strong>&nbsp;password for the username written in field “
              <strong>
                <em>
                  <em>Use KYC from Username</em>
                </em>
              </strong>
              ”
            </span>
          </p>
          <p>
            <span className="spanStyle">&nbsp;</span>
          </p>
          <p>
            <strong>
              <span className="decorationFontSize">Step 4 (automatic):</span>
            </strong>
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <strong>
                <span className="spanStyle">
                  <span className="underlineWithoutColor">1</span>
                  <span className="underlineWithoutColor">
                    <sup>st</sup>
                  </span>
                  <span className="underlineWithoutColor">case scenario:</span>
                </span>
              </strong>
              <ul>
                <li>
                  <span className="spanStyle">
                    <strong>
                      <span className="underlineWithoutColor">ALL </span>
                    </strong>
                    details match 100%
                  </span>
                </li>
                <li>
                  <span className="spanStyle">
                    Used username is on status <strong>APPROVED</strong>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <strong>
              <span className="spanStyle">&nbsp;→ Curr</span>
              <span className="spanStyle">ent account will be immediately APPROVED</span>
            </strong>
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <span className="spanStyle">
                <strong>
                  <span className="underlineWithoutColor">2</span>
                  <span className="underlineWithoutColor">
                    <sup>nd</sup>
                  </span>
                </strong>
                <span className="underlineWithoutColor">
                  <strong>case scenario</strong>:
                </span>
              </span>
              <ul>
                <li>
                  <span className="spanStyle">
                    <span className="underlineWithoutColor">
                      <strong>NOT ALL</strong>{' '}
                    </span>
                    details match
                  </span>
                </li>
                <li>
                  <span className="spanStyle">
                    Used username is on status <strong>APPROVED</strong>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <span className="spanStyle">
              &nbsp;<strong>&nbsp;→&nbsp;</strong>
              <strong>Current account will remain on PENDING/CONDITIONALLY APPROVED</strong>
            </span>
          </p>
        </div>
      </div>
    </Modal>
  );
});

ProfileKycInformation.propTypes = {
  ref: PropTypes.object,
};

export default ProfileKycInformation;
