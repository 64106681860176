import { commonStyles } from '../styles';

export const collapseContainer = (props) => [
  {
    display: 'flex',
    flexDirection: 'column',

    '& .collapse-content': {
      transformOrigin: 'top',
    },
  },
  ...commonStyles(props),
];

export const collapseHeader = (isOpen) => [
  {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
    width: '100%',

    '& > i': {
      transition: 'transform .5s ease-in-out',
    },
  },
  isOpen && {
    '& > i': {
      transform: 'rotate(180deg)',
    },
  },
];
