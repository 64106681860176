import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { omit, isFunction, isNil } from 'lodash-es';
import {
  Row,
  Col,
  Form,
  showApiError,
  Button,
  Input,
  Dropdown,
  inputValidation,
  DateTimePicker,
  FileInput,
  // showError,
} from 'components/UI';
import { KYCType, dateFormat } from 'enums';
import { useCountries } from 'stores';
// import { processImages } from 'utils';
import { createKyc } from 'services';
import { ReactComponent as DefaultCountryImage } from 'assets/images/default-country-image.svg';
import { kycIdOptions } from './config';
import { countryContainer, countryImage, countryListLabel, expMassage } from './styles';

const KycModalIdForm = ({ onClose, onSubmit, onFail, onSuccess, onUploadDocs, vipStatus }) => {
  const [allValues, setAllValues] = useState(null);
  const countries = useCountries();
  // console.log(allValues);

  const countryListOptions = countries.allWithoutRestricted?.map((el) => ({
    ...el,
    value: el.name,
    label: (
      <div css={countryContainer}>
        {!isNil(el.flag) ? (
          <div>{el.flag}</div>
        ) : (
          <div css={countryImage}>
            <DefaultCountryImage />
          </div>
        )}
        <span css={countryListLabel}>{el.name}</span>
      </div>
    ),
  }));

  // const onDocFilesUpload = async (inputFiles, values, handleChange) => {
  //   const uploadedFiles = Array.from(inputFiles);
  //   const fileExist = values.filter(file =>
  //     uploadedFiles.find(el => el.name === file.name && el.lastModified === file.lastModified),
  //   );

  //   if (fileExist.length) return showError(`Images ${fileExist.map(el => el.name).join(', ')} are already uploaded!`);

  //   if (uploadedFiles.some(el => el.size > 2097152)) return showError('Maximum size per file is 2 MB');

  //   const generatedPictures = await processImages(uploadedFiles, {
  //     parseToFile: true,
  //   });

  //   handleChange({
  //     target: { name: 'files', value: values.concat(generatedPictures) },
  //   });
  // };

  const onDocFilesUpload = async () => {
    // console.log(id);
    // console.log(values);
    // const uploadedFiles = id === 'images' || id === 'files';
    // console.log(uploadedFiles);
    // const fileExist =
    //   uploadedFiles && values?.images.name === values?.files.name ? values?.images.name || values?.files.name : '';
    // console.log(fileExist);
    // const fileExist = values.filter(file =>
    //   uploadedFiles.find(el => el.name === file.name && el.lastModified === file.lastModified),
    // );
    // if (uploadedFiles.some(el => el.size > 2097152)) return showError('Maximum size per file is 2 MB');
    // const generatedPictures = await processImages(uploadedFiles, {
    //   parseToFile: true,
    // });
    // handleChange({
    //   target: { name: 'files', value: values.concat(generatedPictures) },
    // });
  };

  return (
    <Form
      onChange={(id, { values }) => {
        // console.log(values);
        onDocFilesUpload(id, values);
        setAllValues(values);
      }}
      onSubmit={async (values) => {
        isFunction(onSubmit) && onSubmit();
        setAllValues(values);

        const [, err] = await createKyc({
          type: KYCType.idProof,
          ...omit(values, 'files'),
          isVip: vipStatus,
          filesContent: values.files.map((el) => el.picture),
          thumbnailsContent: values.files.map((el) => el.picThumbnail),
        });

        if (err) {
          isFunction(onFail) && onFail();
          return showApiError(err);
        }

        onUploadDocs();
        isFunction(onSuccess) && onSuccess();
      }}
    >
      <Row gap={16} margin="24px 0">
        <Col xs={12} md={6}>
          <Input
            required
            label="Document Number"
            formId="documentNumber"
            validate={inputValidation('required', 'lettersNumbersDash')}
          />
        </Col>
        <Col xs={12} md={6}>
          <DateTimePicker
            required
            formId="dateOfBirth"
            label="Date of Birth"
            timeFormat={false}
            start
            dateFormat={dateFormat}
            inputProps={{ placeholder: `${dateFormat}` }}
            isValidDate={(currentDate) => !currentDate.isSameOrAfter(moment().subtract(18, 'years'))}
            validate={(value) =>
              inputValidation('required')(value) ||
              (moment(value).isAfter(moment().subtract(18, 'years')) && {
                msg: 'Your Date of Birth is not valid',
              })
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <DateTimePicker
            required
            formId="issuedAtDate"
            label="Document Issue Date"
            timeFormat={false}
            start
            dateFormat={dateFormat}
            inputProps={{ placeholder: `${dateFormat}` }}
            isValidDate={(currentDate) => !currentDate.isSameOrAfter(moment())}
            validate={(value) =>
              inputValidation('required')(value) ||
              (!moment(value).isBefore(moment()) && {
                msg: 'Document Issue Date is not valid',
              })
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <Dropdown
            withSearch
            required
            formId="countryOfBirth"
            label="Country of Birth"
            options={countryListOptions}
            mapValue={(val) => val?.value}
            validate={inputValidation('required')}
            displayKey="label"
            renderOption={(val) => (
              <div css={countryContainer}>
                {!isNil(val.flag) ? (
                  <div>{val.flag}</div>
                ) : (
                  <div css={countryImage}>
                    <DefaultCountryImage />
                  </div>
                )}
                <span css={countryListLabel}>{val.name}</span>
              </div>
            )}
          />
        </Col>
        <Col xs={12} md={6}>
          <DateTimePicker
            required
            formId="expirationDate"
            label="Document Expiration Date"
            timeFormat={false}
            start
            dateFormat={dateFormat}
            inputProps={{ placeholder: `${dateFormat}` }}
            isValidDate={(currentDate) => !currentDate.isBefore(moment())}
            validate={(value) =>
              inputValidation('required')(value) ||
              (!moment(value).isBetween(moment().add(30, 'days'), '3000-01-01') && {
                msg: 'Document Expiration Date is not valid',
              })
            }
          />
          <p css={expMassage}>Document that expires in 30 days or less cannot be uploaded.</p>
        </Col>
        <Col xs={12} md={6}>
          <Input
            required
            label="City of Birth"
            formId="cityOfBirth"
            placeholder="City of Birth"
            validate={(value) =>
              inputValidation('required')(value) ||
              inputValidation('lowercase')(value) ||
              inputValidation('uppercase')(value) ||
              ((value.length < 1 || value.length > 100) && {
                msg: 'The city of birth must be at least 1 and at max 100 characters long.',
              })
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <Dropdown
            required
            label="Document Type"
            formId="subType"
            placeholder="Please Choose"
            options={kycIdOptions}
            validate={inputValidation('required')}
          />
        </Col>
      </Row>

      <Row gap={16} margin="24px 0">
        <Col xs={12} md={6}>
          <FileInput
            accept="image/*"
            formId="images"
            label="Documents"
            id="images"
            max={2}
            validate={(value) => {
              // console.log(value);
              const isFileExist = value?.name === allValues?.files?.name;
              return (
                inputValidation('required')(value) ||
                (isFileExist && {
                  msg: `Images ${value?.name}, are already uploaded!`,
                })
              );
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <FileInput
            accept="image/*"
            formId="files"
            label="Documents"
            id="files"
            max={2}
            validate={(value) => {
              // console.log(value);
              const isFileExist = value?.name === allValues?.images?.name;
              return (
                inputValidation('required')(value) ||
                (isFileExist && {
                  msg: `Images ${value?.name}, are already uploaded!`,
                })
              );
            }}
          />
        </Col>
        <Col displayFlex md={12} justify="space-between">
          {({ onSubmit: formSubmit }) => (
            <>
              <Button onClick={formSubmit}> Upload Selected Files</Button>
              <Button type="secondary" onClick={onClose}>
                Cancel
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
};

KycModalIdForm.propTypes = {
  onClose: PropTypes.func,
  onUploadDocs: PropTypes.func,
  vipStatus: PropTypes.bool,
  onSubmit: PropTypes.func,
  onFail: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default KycModalIdForm;
