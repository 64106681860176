import { useState, useEffect, useImperativeHandle } from 'react';
import { isFunction } from 'lodash-es';
import validateInput from '../inputValidation';

const delimiter = ' ';

export const usePhoneInput = (
  { value: initValue, required, validate, isTouched: isTouchedInit, componentRef, onError, onChange },
  countryListOptions,
) => {
  const dropdownInitValue =
    countryListOptions?.find((el) => el?.phone === initValue?.split(delimiter)[0])?.phone ?? null;
  const inputInitValue = initValue?.indexOf(delimiter) === -1 ? initValue : initValue?.split(delimiter)[1] ?? '';

  const [inputValue, setInputValue] = useState(inputInitValue ?? '');
  const [dropdownValue, setDropdown] = useState(dropdownInitValue);
  const [error, setError] = useState();
  const [isTouched, setIsTouched] = useState(isTouchedInit);

  useEffect(() => {
    dropdownInitValue?.phone !== dropdownValue && setDropdown(dropdownInitValue);
    setInputValue(inputInitValue);
  }, [dropdownInitValue, inputInitValue]);

  useEffect(() => {
    isTouched && checkForError(dropdownValue, inputValue);
  }, [isTouched]);

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useImperativeHandle(componentRef, () => ({
    checkForError,
    setError: handleError,
    inputValue,
    dropdownValue,
    value: `${dropdownValue}${delimiter}${inputValue}`,
    changeIsTouched: setIsTouched,
  }));

  const handleBlur = () => (!isTouched ? setIsTouched(true) : checkForError(dropdownValue, inputValue));

  const handleError = (newError) => {
    isTouched && setError(newError);
    isFunction(onError) && onError(newError);
  };

  const handleInputChange = (val) => {
    setInputValue(val);
    checkForError(dropdownValue, val);
    isFunction(onChange) && dropdownValue && val?.length && onChange(`${dropdownValue}${delimiter}${val}`);
  };

  const handleDropdownChange = (val) => {
    setDropdown(val);
    checkForError(val, inputValue);
    isFunction(onChange) && val && inputValue?.length && onChange(`${val}${delimiter}${inputValue}`);
  };

  const checkForError = (newDropdownValue, newInputValue) => {
    let newError = null;

    if (isFunction(validate) && !newError) newError = validate({ input: newInputValue, phone: newDropdownValue });
    else if (required)
      newError = validateInput('required')(newInputValue) || validateInput('required')(newDropdownValue);
    handleError(newError);
  };

  return {
    inputValue,
    dropdownValue,
    handleBlur,
    handleInputChange,
    handleDropdownChange,
    error,
    isTouched,
  };
};
