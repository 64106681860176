export const buttonContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: 64,
  marginTop: 32,
};

export const container = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '32px 128px',
  width: '100%',
};

export const inviteContainer = {
  padding: '32px 128px',

  '& > h3': {
    marginBottom: 32,
  },
};
