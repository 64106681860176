export const walletType = {
  COIN: 'coin',
  DSCP: 'dscp',
  // REMOVE TAG HIDE ALL COINSAFE FUNCTIONALITY
  // COINSAFE: 'coinsafe',
  CASHSAFE: 'cashsafe',
  CASH: 'cash',
  TRADING: 'trading',
  TOKEN: 'token',
  BV: 'businessVolume',
  GAS: 'gas',
  RESERVECASH: 'reservecash',
};

export const currencyType = {
  EURO: '€',
  EURO_LETTERS: 'EUR',
  TKN: 'TKN',
  COIN: 'ONE',
  BVP: 'BVP',
  GAS: 'GAS',
  DSCP: 'DSCP',
};
