import luckyWheelBackgroundLogo from 'assets/images/lucky-wheel-background.png';

export const redeemContainer = (theme) => ({
  'div[role=presentation]:first-of-type': {
    padding: 0,
    height: '100%',
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'auto',
  },

  '.title': {
    textAlign: 'center',
    padding: 16,
    fontSize: 18,
    fontWeight: 400,
  },

  '.image': {
    width: 30,
    height: 30,
    marginRight: 8,
  },

  '.infoBoxItemTextContainerAmount': {
    fontSize: 22,
    color: theme.secondaryDark,
  },

  '.infoBoxItemTextContainerTitle': {
    fontSize: 16,
  },

  '.bodyImageContainer': {
    backgroundColor: theme.whiteGrayWhite,
    padding: '24px 32px',

    '.headerImage': {
      maxWidth: 200,
      maxHeight: 200,
    },

    '.packageDetailsName': {
      fontSize: 16,
      fontWeight: 600,
      color: theme.gray400,
    },

    '.packageDetailsPrice': {
      fontSize: 24,
      fontWeight: 500,
    },

    '.packageDetailsDescription': {
      fontSize: 14,
      color: theme.gray500,

      '& > b': {
        marginRight: 8,
        fontWeight: 600,
      },
    },

    '.packageDetailsImmediateContainer': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& > p': {
        textAlign: 'center',
        fontWeight: 500,
      },
    },
  },

  '.bodyContent': {
    padding: 32,
  },
});

export const packageDetailsInfoBoxContainer = (theme) => ({
  border: `1px solid ${theme.whiteGray}`,
  borderRadius: 3,
  padding: 24,
  display: 'flex',
  flexDirection: 'column',

  '.infoBoxItem': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.gray50}`,
    padding: '12px 12px 12px 0',

    '&:last-child': {
      border: 'none',
    },

    '.infoBoxItemContainer': {
      display: 'flex',
      alignItems: 'center',

      '.boxIcon': {
        width: 46,
        height: 46,
        borderRadius: 46,
        backgroundColor: theme.whiteGrayWhite,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,

        '& > svg': {
          width: 24,
          height: 24,
          color: theme.secondaryDark,
        },
      },

      '.infoBoxItemTextContainerSubtitle': {
        fontSize: 13,
        letterSpacing: -0.16,
        color: theme.gray300,
      },
    },
  },
});

export const bonusBoxContainer = (theme) => ({
  border: `1px solid ${theme.whiteGray}`,
  borderRadius: 3,
  padding: 24,
  display: 'flex',
  flexDirection: 'column',

  '.bonusItemContainer': {
    padding: '8px 8px 8px 0',
    borderBottom: `1px solid ${theme.gray50}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:last-child': {
      border: 'none',
    },

    '&:nth-child(even)': {
      backgroundColor: theme.whiteGrayWhite,
    },

    '.bonusRowContainer': {
      display: 'flex',
      alignItems: 'center',
    },

    '.infoBoxItemTextContainerLevel': {
      fontSize: 13,
      fontWeight: 600,
      opacity: 0.7,
    },

    '.infoBoxItemTextContainerValue': {
      fontSize: 13,
      fontWeight: 600,
      opacity: 0.7,
      color: theme.black,
    },
  },
});

export const actionContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 32px 32px',
};

export const packageCardContainer = (theme) => ({
  position: 'relative',
  cursor: 'pointer',

  '.packageCardTop': {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 3,
    border: `1px solid ${theme.gray100}`,
    backgroundColor: theme.whiteGrayWhite,
    padding: '16px 16px 16px 32px',
    minHeight: 165,
    color: theme.gray600,
  },
});

export const luckyWheelContainer = {
  width: '100%',
  minWidth: 696,
  padding: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${luckyWheelBackgroundLogo})`,

  '.luckyWheelContent': {
    position: 'relative',
    width: 600,
    minHeight: 640,

    '.luckyWheelGoldPlate': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 600,
      zIndex: 100,
    },

    '.luckyWheelPointer': {
      position: 'absolute',
      top: 12,
      left: 284,
      height: 90,
      zIndex: 300,
    },

    '.luckyWheelSectors': {
      position: 'absolute',
      top: 32,
      left: 0,
      width: 600,
      transition: 'transform 10s cubic-bezier(0.18, 0, 0.3, 1)',
      zIndex: 200,
    },
  },
};

export const packageFilterContainer = {
  marginBottom: 24,
  flexFlow: 'row wrap',

  '.searchFilter': {
    maxWidth: 560,
    width: 400,
  },

  '.dropdown': {
    minWidth: 170,
  },
};

export const useMiningModalContainer = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '.title': {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 400,
  },

  '.useMiningModalBody': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '.icon': {
      width: 220,
      height: 'auto',
      margin: '32px 0',
    },

    '.bodyText': {
      fontSize: 13,
      letterSpacing: 0.2,
      color: theme.gray500,
      fontWeight: 500,
      marginBottom: 32,
    },

    '.bodyActions': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
});
