export const imageContainer = (basis, right, marginBottom) => ({
  borderRadius: '50%',
  flexBasis: basis,
  flexShrink: 0,
  marginRight: right ?? 0,
  marginBottom: marginBottom ?? 0,
});

export const container = (theme) => ({
  position: 'absolute',
  top: 60,
  right: 10,
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  backgroundColor: theme.white,
  borderRadius: 6,
  color: theme.primary,
  width: 300,
  boxShadow: theme.boxShadow,

  '& > *': {
    marginBottom: 12,
  },
  '& > h5': {
    marginBottom: 0,
  },

  '& .button': {
    width: '100%',
  },

  '.editLink': {
    color: theme.infoDark,
    padding: 0,
  },

  '.planContainer': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingBottom: 16,
    borderBottom: `0.5px solid ${theme.gray100}`,
  },

  '.grayColor': {
    color: theme.gray,
  },

  '.package': {
    color: theme.black,
  },
});

export const profile = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `0.5px solid ${theme.gray100}`,
  width: '100%',

  '.name': {
    color: theme.black,
    fontSize: 16,
    fontWeight: 600,
  },
});
