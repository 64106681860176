import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { fromPairs } from 'lodash-es';
import { Row, Col, showApiError, Image, NoResults, BackButton } from 'components';
import {
  LoadingSubscriptionDetails,
  OneLifeSubscriptionPage,
  OneForexSubscriptionPage,
  OneVoyageSubscriptionPage,
} from 'components/screens/Subscriptions';
import { orderItemTypes, vendorNames } from 'enums';
import { getPaymentMethodsByIds, getSubscriptionById } from 'services';
import { vendorsInformation } from './config';
import { subscriptionsPageContainer, backButtonStyles } from './styles';

const SubscriptionDetailsPage = (props) => {
  const {
    //  location,
    history,
  } = props;
  const [subscription, setSubscription] = useState({
    id: 2,
    systemName: 'OneVoyage',
    visible: true,
    active: true,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.1',
    pictureUrl: null,
    picThumbnailUrl: null,
    color: '#6379c9',
    subscriptionPlans: [
      {
        id: 4,
        name: 'Premium',
        price: 600,
        currency: null,
        businessVolume: 600,
        duration: 6,
        active: true,
        hasActiveSubscriptions: false,
        vendor: 'OneVoyage',
      },
      {
        id: 5,
        name: 'Basic',
        price: 50,
        currency: null,
        businessVolume: 25,
        duration: 1,
        active: true,
        hasActiveSubscriptions: false,
        vendor: 'OneVoyage',
      },
      {
        id: 6,
        name: 'Gold unlimited',
        price: 1200,
        currency: null,
        businessVolume: 300,
        duration: 12,
        active: true,
        hasActiveSubscriptions: false,
        vendor: 'OneVoyage',
      },
    ],
  });
  const [paymentMethods, setPaymentMethods] = useState(undefined);
  const { id } = useParams();
  const subscriptionVendor = subscription?.vendor ?? subscription.subscriptionPlans?.find((el) => el.vendor).vendor;
  // console.log(subscriptionVendor);
  const pageProps = { subscriptions: [subscription], paymentMethods };

  useEffect(() => {
    fetchSubscriptionById();
    fetchAllPaymentMethods();
  }, []);

  const fetchSubscriptionById = async () => {
    const [res, err] = getSubscriptionById(id);
    err
      ? showApiError(err)
      : setSubscription(
          {
            id: 19,
            systemName: 'OneApp-1672908857172',
            visible: true,
            active: true,
            description: 'Test subscription catalogue item with methods',
            pictureUrl: null,
            picThumbnailUrl: null,
            color: '#6379c9',
            subscriptionPlans: [
              {
                id: 26,
                name: 'Test methods',
                price: 1,
                currency: 'EUR',
                businessVolume: 1,
                duration: 1,
                active: true,
                hasActiveSubscriptions: true,
                vendor: 'OneApp',
              },
            ],
          } ?? res,
        );
  };

  const fetchAllPaymentMethods = async () => {
    const [data, err] = await getPaymentMethodsByIds([id], orderItemTypes.Subscription);
    if (err) return showApiError(err);

    const allSubscriptionsPaymentMethods = fromPairs(
      data?.content?.map((el) => [el.catalogueItemId, el.shopPayMethods.filter((item) => item.visible && item.active)]),
    );

    setPaymentMethods(allSubscriptionsPaymentMethods);
  };

  const mapPageByVendor = (vendor) => {
    switch (vendor) {
      case vendorNames.OneLife:
        return <OneLifeSubscriptionPage {...pageProps} />;
      case vendorNames.OneForex:
        return <OneForexSubscriptionPage {...pageProps} />;
      case vendorNames.OneVoyage:
        return <OneVoyageSubscriptionPage {...pageProps} />;
      default:
        return <NoResults message="There are no subscriptions available" />;
    }
  };

  return (
    <div css={subscriptionsPageContainer(vendorsInformation[subscriptionVendor].color)}>
      <BackButton onBack={() => history.goBack()} css={backButtonStyles} />
      <Row gap={32} justify="center" className="header">
        <Image
          src={vendorsInformation[subscriptionVendor].logo}
          alt={`${vendorsInformation[subscriptionVendor].name} Logo`}
          className="image"
        />
      </Row>
      <Row gap={32}>
        <Col justify="center" align="center" className="vendorSection">
          <h2 className="vendorTitle">{vendorsInformation[subscriptionVendor]?.title}</h2>
          <p className="vendorDescription">
            {subscription?.description ?? vendorsInformation[subscriptionVendor]?.description}
          </p>
        </Col>
      </Row>
      {subscriptionVendor ? mapPageByVendor(subscriptionVendor) : <LoadingSubscriptionDetails />}
    </div>
  );
};

SubscriptionDetailsPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default SubscriptionDetailsPage;
