import { isNil } from 'lodash-es';
import { Skeleton } from 'components';
import { ReactComponent as BvIcn } from 'assets/images/icn-briefcase.svg';
import { ReactComponent as TokensIcn } from 'assets/images/icn-token.svg';
import { ReactComponent as SplitIcn } from 'assets/images/icn-split.svg';
import { ReactComponent as DifficultyIcn } from 'assets/images/icn-difficulty.svg';
import { ReactComponent as AllowedIcn } from 'assets/images/icn-checklist-checked.svg';
import { ReactComponent as NotAllowedIcn } from 'assets/images/icn-not-allowed.svg';
import { ReactComponent as BurnSolid } from 'assets/images/burn-solid.svg';

export const packageInfoDataFirstCol = (packageByGiftCode, config) => [
  {
    image: <BvIcn />,
    title: 'Business Volume',
    subtitle: 'What`s the Business volume',
    amount: packageByGiftCode?.businessVolume,
  },
  {
    image: <TokensIcn />,
    title: 'Promotional Tokens',
    subtitle: 'How many Promotional Tokens you get?',
    amount: packageByGiftCode?.tokens,
  },
  {
    image: <TokensIcn />,
    title: 'Academy Tokens',
    subtitle: 'How many Academy Tokens you get?',
    amount: packageByGiftCode?.academyTokens ?? 0,
  },
  {
    image: <SplitIcn />,
    title: 'Splits',
    subtitle: 'The amount of splits for your Promotional Tokens',
    amount: packageByGiftCode?.split,
  },
  {
    image: <SplitIcn />,
    title: 'Max Splits',
    subtitle: 'The amount of maximum splits for your Promotional Tokens',
    amount: packageByGiftCode?.maxSplits ?? 0,
  },
  {
    image: <SplitIcn />,
    title: 'Promo Splits',
    subtitle: 'The amount of promo splits for your Promotional Tokens',
    amount: packageByGiftCode?.promoSplits,
  },
  {
    image: <DifficultyIcn />,
    title: 'Difficulty rate',
    subtitle: 'Preferential difficulty rate',
    amount:
      !isNil(config) && !isNil(packageByGiftCode?.difficulty) ? (
        config - packageByGiftCode?.difficulty
      ) : (
        <Skeleton width={30} height={20} />
      ),
  },
  {
    image: <DifficultyIcn />,
    title: 'Exchange rate',
    subtitle: 'Preferential exchange rate',
    amount:
      !isNil(config) && !isNil(packageByGiftCode?.exchangeReduction) ? (
        config - packageByGiftCode?.exchangeReduction
      ) : (
        <Skeleton width={30} height={20} />
      ),
  },
  {
    image: <BurnSolid />,
    title: 'Gas',
    subtitle: 'What`s the Gas volume',
    amount: packageByGiftCode?.gasAmount,
  },
];

export const packageInfoDataSecondCol = (packageByGiftCode, hasMatchingBonus) => [
  {
    image: packageByGiftCode?.hasNetworkBonus ? <AllowedIcn /> : <NotAllowedIcn />,
    title: 'Network',
    level: null,
    value: packageByGiftCode?.hasNetworkBonus ? 'Yes' : 'No',
  },
  {
    image: hasMatchingBonus ? <AllowedIcn /> : <NotAllowedIcn />,
    title: 'Matching',
    level: null,
    value: hasMatchingBonus ? 'Yes' : 'No',
  },
  {
    image: null,
    title: null,
    level: '1st Level',
    value: packageByGiftCode?.hasMatch1Bonus ? '10%' : 'N/A',
  },
  {
    image: null,
    title: null,
    level: '2nd Level',
    value: packageByGiftCode?.hasMatch2Bonus ? '10%' : 'N/A',
  },
  {
    image: null,
    title: null,
    level: '3rd Level',
    value: packageByGiftCode?.hasMatch3Bonus ? '20%' : 'N/A',
  },
  {
    image: null,
    title: null,
    level: '4th Level',
    value: packageByGiftCode?.hasMatch4Bonus ? '25%' : 'N/A',
  },
  {
    image: null,
    title: 'Bonus Cap',
    level: null,
    amount: packageByGiftCode?.bonusCap,
  },
];

export const filterOptions = [
  { label: 'Sort by: Value', value: 'price' },
  { label: 'Sort by: Date', value: 'createdOn' },
  { label: 'Sort by: Name', value: 'name' },
];
