import { useEffect, useState, useRef } from 'react';
import { sortBy } from 'lodash-es';
import hash from 'object-hash';
import { Row, Col, Button, showApiError, showSuccess, Input } from 'components/UI';
import { AccountBox } from 'components/screens/Accounts';
import { useUserStore, setCurrentAccount } from 'stores';
import { switchAccount, getAccountsList, getAllWallets } from 'services';
import { accountsContainer } from './styles';

const contains = (account, inputValue) => account.nickName?.toLowerCase().includes(inputValue?.toLowerCase());

const Accounts = () => {
  const [wallets, setWallets] = useState([]);
  const user = useUserStore();
  const [filteredAccounts, setFilteredAccounts] = useState(user?.accounts ?? null);
  const [accountIds, setAccountIds] = useState(filteredAccounts?.map((el) => el.id));
  const filterValueRef = useRef();
  const accountsOptionsRef = useRef({
    total: 0,
    pageIndex: 0,
    pageSize: 50,
    items: [],
  });
  const currentAccountId = user?.currentAccount?.id;

  useEffect(() => {
    fetchAccountsList({ pageIndex: 0, total: 0 });
  }, []);

  useEffect(() => {
    accountIds && fetchAllWallets();
  }, [hash({ accountIds })]);

  const fetchAccountsList = async (options) => {
    accountsOptionsRef.current = {
      ...accountsOptionsRef.current,
      pageIndex: options.pageIndex,
    };

    const [res, err] = await getAccountsList(accountsOptionsRef.current);

    if (err) return showApiError(err);

    accountsOptionsRef.current.total = res?.total;
    accountsOptionsRef.current.items = [...(accountsOptionsRef.current?.items ?? []), ...(res?.items ?? [])];
    setFilteredAccounts((prev) =>
      sortBy(options.reset ? res?.items : [...(prev ?? []), ...(res?.items ?? [])], ['id']),
    );
    setAccountIds((prev) =>
      sortBy(options.reset ? res?.items : [...(prev ?? []), ...(res?.items ?? [])], ['id'])?.map((el) => el.id),
    );
  };

  const fetchAllWallets = async (accountId, counter) => {
    let attemptNumber = counter || 1;

    const [data, err] = await getAllWallets(accountIds);

    if (err) return showApiError(err);
    setWallets((prev) => [data, ...prev]);

    if (accountId && attemptNumber < 10 && !data?.find((el) => el?.accountId === accountId)) {
      setTimeout(() => {
        fetchAllWallets(accountId, ++attemptNumber);
      }, 1000);
    }
  };

  const updateSelection = async (account) => {
    const [, err] = await switchAccount(account?.id);
    if (err) return showApiError(err);

    showSuccess('You have successfully switch account.');
    setCurrentAccount(account);
  };

  const handleFilterChange = (value) => {
    filterValueRef.current = value;
    setFilteredAccounts(accountsOptionsRef.current?.items?.filter((el) => contains(el, value)) ?? []);
    setAccountIds(accountsOptionsRef.current?.items?.filter((el) => contains(el, value))?.id);
  };

  return (
    <div css={accountsContainer}>
      <h3 className="title">Accounts ({filteredAccounts?.length})</h3>

      <Input placeholder="Search Accounts" handleChange={handleFilterChange} />

      <hr className="horizontal-line" />
      <Row justify="center" gap={16}>
        {filteredAccounts?.map((account) => {
          const walletsPerAccount = wallets.filter((el) => el?.accountId === account?.id);
          return (
            <Col xs={12} md={6} xl={4} key={account.id}>
              <AccountBox
                account={account}
                wallets={walletsPerAccount}
                isCurrent={account.id === currentAccountId}
                onClick={() => updateSelection(account)}
              />
            </Col>
          );
        })}
      </Row>

      {(accountsOptionsRef.current?.pageIndex + 1) * accountsOptionsRef.current?.pageSize <
        accountsOptionsRef.current?.total && (
        <div className="accountActions">
          <Button
            onClick={() =>
              fetchAccountsList({
                pageIndex: accountsOptionsRef.current?.pageIndex + 1,
              })
            }>
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

export default Accounts;
