import PropTypes from 'prop-types';
import { Row, Col, Image } from 'components/UI';
import { SubscriptionItem, PaymentMethodsCard } from 'components/screens/Subscriptions';
import { ReactComponent as CornerImage } from 'assets/images/purple-waves.svg';
import GooglePlayBadge from 'assets/images/google-play-badge.png';
import AppleStoreBadge from 'assets/images/app-store-badge.png';
import OneLifePhoneImage from 'assets/images/one-life-phone-image.png';

import { oneLifeContainer } from './styles';

const OneLifeSubscriptionPage = (props) => {
  const { subscriptions = [], paymentMethods } = props;

  const getDurationLabel = (duration) => (duration === 1 ? '/month' : `/${duration} months`);

  return (
    <div css={oneLifeContainer}>
      {subscriptions?.map((catItem) => (
        <Row gap={32} className="rowContainer" key={`${catItem.systemName}${catItem.id}`}>
          {catItem.subscriptionPlans?.map((plan, index) => (
            <Col xs={12} sm={4} md={4} lg={4} key={`${plan.vendor}-${plan.name}-${index}`} className="columnContainer">
              <SubscriptionItem item={plan} getDurationLabel={getDurationLabel} />
            </Col>
          ))}

          <Col xs={12} sm={4} md={4} lg={5}>
            <div className="subscriptionData">
              <PaymentMethodsCard paymentMethods={paymentMethods} catalogueItemId={catItem.id} />
            </div>
          </Col>

          {catItem.subscriptionPlans.length < 2 && (
            <div className="subscriptionData">
              <div className="phoneImageContainer">
                <Image src={OneLifePhoneImage} alt="my-life-phone" className="image" />
              </div>
            </div>
          )}

          <Col xs={12} sm={4} md={4} lg={3} justify="flex-end" className="playStoreContainer">
            <CornerImage className="cornerImage" />

            <div className="storeAppsContainer">
              <a href="/shop/all-subscriptions" key="google-play">
                <Image src={GooglePlayBadge} alt="google-play" className="image" />
              </a>
              <a href="/shop/all-subscriptions" key="apple-store">
                <Image src={AppleStoreBadge} alt="apple-store" className="image" />
              </a>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

OneLifeSubscriptionPage.propTypes = {
  subscriptions: PropTypes.array,
  paymentMethods: PropTypes.object,
};

export default OneLifeSubscriptionPage;
