import { useRef, useEffect } from 'react';
import depsEqual from 'fast-deep-equal/react';

export const useDeepEffect = (callback, deps) => {
  const ref = useRef();

  if (!ref.current || !depsEqual(deps, ref.current)) ref.current = deps;

  useEffect(callback, ref.current);
};
