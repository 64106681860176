import { useHistory } from 'react-router-dom';
import { Image, Button, useUser, GuestFooter } from 'components';
import { ReactComponent as CongratulationIcon } from 'assets/images/congratulations.svg';
import { successContainer } from './styles';

const SuccessRegistration = () => {
  const history = useHistory();
  const { isAuthenticated, onRegistrationProgress } = useUser();

  const onBackToProfile = () => {
    onRegistrationProgress(null);
    history.push('/overview');
  };

  return (
    <div css={successContainer}>
      <div className="content-container">
        <Image
          src="https://onelife.blob.core.windows.net/images/oneEcosystemLogoWhiteText.svg"
          alt="logo"
          height={50}
          width="auto"
          className="logo"
        />
        <CongratulationIcon className="success-icon" />
        <h3>
          {isAuthenticated
            ? 'Thank you. The person has been emailed and can start using the profile'
            : 'Congratulations!'}
        </h3>
        <p className="text">
          {isAuthenticated
            ? 'Curabitur blandit tempus porttitor. Maecenas faucibus mollis interdum. Nulla vitae elit libero, a pharetra augue. Nulla vitae elit libero.'
            : 'Curabitur blandit tempus porttitor. Maecenas faucibus mollis interdum. Nulla vitae elit libero, a pharetra augue. Nulla vitae elit libero.'}
        </p>
        {!isAuthenticated && <Button onClick={() => history.push('/login')}>Login here</Button>}
        {isAuthenticated && <Button onClick={onBackToProfile}>Back to Your Profile</Button>}
      </div>
      {!isAuthenticated && <GuestFooter className="footer" />}
    </div>
  );
};

export default SuccessRegistration;
