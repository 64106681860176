import { useStore, createStore } from 'components/UI';
import { createAccount, switchAccount, getCurrentAccountWallets } from 'services/profile';

const accountsStore = createStore();

export const initUserAccounts = (data) => accountsStore.setState(data ?? { current: {}, items: [] });

export const addAccount = async (newAccount) => {
  const [res, err] = await createAccount(newAccount);

  !err && accountsStore.setState((prev) => ({ ...prev, items: prev.items.concat(res) }));
  return err;
};

export const setCurrentAccount = async (accountId) => {
  const [, err] = await switchAccount(accountId);

  !err &&
    accountsStore.setState((prev) => prev.map((el) => ({ ...el, current: el.find((item) => item.id === accountId) })));
  refreshCurrentAccountWallets();
  return err;
};

export const fulfillAccounts = (accounts) => accountsStore.setState((prev) => ({ ...prev, items: accounts }));

export const refreshCurrentAccountWallets = async () => {
  const [res, err] = await getCurrentAccountWallets();

  !err && accountsStore.setState((prev) => ({ ...prev, current: { ...prev.current, wallets: res } }));
  return err;
};

export const useUserAccounts = (...args) => useStore(accountsStore, ...args);
