import PropTypes from 'prop-types';
import { Image, ColorBatch } from 'components/UI';
import { useUserStore } from 'stores';
import avatar from 'assets/images/default-avatar.png';
import { profileStatusColorMap } from './config';
import { userContainer, imageContainer, city } from './styles';

const UserProfile = (props) => {
  const { countryFlag, userData } = props;
  const user = useUserStore();

  return (
    <div css={userContainer}>
      <Image
        src={user?.picThumbnailUrl ?? user?.pictureUrl ?? avatar}
        css={imageContainer(160)}
        width={160}
        height={160}
      />

      <ColorBatch type={profileStatusColorMap[user?.status]?.color} margin="0 0 12px 0">
        {profileStatusColorMap[user?.status]?.text}
      </ColorBatch>

      <h3 className="name">
        {/* {`${user?.firstName} ${user?.lastName}`} */}
        Elica Kehaiova
      </h3>
      <div css={city}>
        <p>{countryFlag}</p> &nbsp;
        <span>{`${userData?.city}, ${userData?.country}`}</span>
      </div>
      <p className="first-login">
        Member since: <span>2020-05-05, 10:21:13</span>
      </p>
    </div>
  );
};

UserProfile.propTypes = {
  countryFlag: PropTypes.string,
  userData: PropTypes.object,
};

export default UserProfile;
