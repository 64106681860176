import PropTypes from 'prop-types';
import { Row, Col } from 'components/UI';
import { SubscriptionItem, PaymentMethodsCard } from 'components/screens/Subscriptions';
import { vendorBenefits, VOYAGE_BENEFIT_TYPES } from 'enums';
import { ReactComponent as CheckIcn } from 'assets/images/checked-purple.svg';
import { getVoyageSubscriptionsBenefits } from './config';
import { oneVoyageContainer } from './styles';

const OneVoyageSubscriptionPage = (props) => {
  const { subscriptions = [], paymentMethods } = props;

  const durationBenefitLabel = (duration) => (duration === 1 ? 'Monthly duration' : `${duration} months`);

  return (
    <div css={oneVoyageContainer}>
      {subscriptions.map((catItem) => (
        <div className="rowContainer" key={`${catItem.systemName}${catItem.id}`}>
          <Row className="firstContainer">
            <Col xs={12} sm={6} lg={3} className="benefitsSmall borderRight">
              <div className="data">
                <h4 className="title">Benefits</h4>
              </div>
            </Col>
            {catItem.subscriptionPlans.map((plan, index) => (
              <Col xs={12} sm={6} lg={3} key={`${plan.vendor}-${plan.name}-${index}`} className="shadowBox">
                <SubscriptionItem
                  item={plan}
                  planIndex={index}
                  discountLabel={plan.name === 'Basic' ? '50% discount (first 3 months)' : ''}
                  className="item"
                >
                  <PaymentMethodsCard paymentMethods={paymentMethods} catalogueItemId={catItem.id} />
                  {getVoyageSubscriptionsBenefits(plan.name).length > 0 && (
                    <div className="benefitsXs">
                      <span className="title">Benefits</span>
                      {getVoyageSubscriptionsBenefits(plan.name).map((key) => (
                        <div key={`xs-benefit-${key}`}>
                          <span className="data"> {vendorBenefits[key]}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </SubscriptionItem>
              </Col>
            ))}
          </Row>

          {Object.keys(vendorBenefits).map((key) => (
            <Row key={`benefit-${key}`} className="benefitsSmall">
              <Col xs={12} sm={6} lg={3} className="borderedColumn">
                <div className="data">
                  <p>{vendorBenefits[key]}</p>
                </div>
              </Col>
              {catItem.subscriptionPlans.map((plan, index) => (
                <Col xs={12} sm={6} lg={3} key={`${key}-${index}`} className="borderedColumn">
                  <div className="data text">
                    {getVoyageSubscriptionsBenefits(plan.name).includes(key) ? (
                      key === 'AIRPORT_LOUNGE_ACCESS' ? (
                        VOYAGE_BENEFIT_TYPES[plan.duration]?.AIRPORT_LOUNGE_ACCESS
                      ) : (
                        <CheckIcn />
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          ))}

          <Row className="benefitsSmall">
            <Col xs={12} sm={6} lg={3} className="borderedColumn">
              <div className="data">
                <p>Subscription</p>
              </div>
            </Col>
            {catItem.subscriptionPlans.map((plan, index) => (
              <Col xs={12} sm={6} lg={3} key={`bv-${index}`} className="borderedColumn">
                <div className="data text">{durationBenefitLabel(plan.duration)} subscription</div>
              </Col>
            ))}
          </Row>

          <Row className="benefitsSmall">
            <Col xs={12} sm={6} lg={3} className="borderedColumn">
              <div className="data">
                <p>BVs</p>
              </div>
            </Col>
            {catItem.subscriptionPlans.map((plan, index) => (
              <Col xs={12} sm={6} lg={3} key={`bv-${index}`} className="borderedColumn">
                <div className="data text">+ {plan.businessVolume} BV</div>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  );
};

OneVoyageSubscriptionPage.propTypes = {
  subscriptions: PropTypes.array,
  paymentMethods: PropTypes.object,
};

export default OneVoyageSubscriptionPage;
