import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { isString, kebabCase, isFunction } from 'lodash-es';
import { concatStrings } from '../utils';
import { tabContainer } from './styles';

const Tab = (props) => {
  const { label, active, onClick, uuid, hasRoutes, url } = props;

  const handleClick = () => onClick(label);

  return hasRoutes ? (
    <NavLink
      role="tab"
      css={tabContainer(props)}
      to={url}
      className="tabs-list-item"
      {...(isFunction(onClick) && { onClick: handleClick })}
      {...(isString(uuid) && { 'data-id': `tab-${kebabCase(uuid)}` })}
    >
      {label}
    </NavLink>
  ) : (
    <div
      role="tab"
      css={tabContainer(props)}
      className={concatStrings('tabs-list-item', active && 'active')}
      {...(isFunction(onClick) && { onClick: handleClick })}
      {...(isString(uuid) && { 'data-id': `tab-${kebabCase(uuid)}` })}
    >
      {label}
    </div>
  );
};

Tab.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  uuid: PropTypes.string,
  hasRoutes: PropTypes.bool,
  url: PropTypes.string,
  pills: PropTypes.bool,
  vertical: PropTypes.bool,
};

export default Tab;
