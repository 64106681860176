export const noResultsContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '.noResultsIcon': {
    width: 120,
    height: 120,
    marginBottom: 32,
  },

  '.emptyMessage': {
    fontSize: 16,
    color: theme.gray300,
  },
});
