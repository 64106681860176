import packageBgLogo from 'assets/images/package-bg.svg';

export const shopPageContainer = {
  padding: '32px 48px',

  '.redeemForm': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: 300,
  },
};

export const packageDetailsInfoBoxContainer = (theme) => ({
  border: `1px solid ${theme.whiteGray}`,
  borderRadius: 3,
  padding: '8px 16px',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.white,
  marginBottom: 32,

  '.infoBoxItem': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.gray50}`,
    padding: '12px 12px 12px 0',

    '&:last-child': {
      border: 'none',
    },

    '.infoBoxItemContainer': {
      display: 'flex',
      alignItems: 'center',

      '.boxIcon': {
        width: 46,
        height: 46,
        borderRadius: 46,
        backgroundColor: theme.whiteGrayWhite,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,

        '& > svg': {
          width: 24,
          height: 24,
          color: theme.secondaryDark,
        },
      },

      '.infoBoxItemTextContainerSubtitle': {
        fontSize: 13,
        letterSpacing: -0.16,
        color: theme.gray300,
      },

      '.infoBoxItemTextContainerTitle': {
        fontSize: 16,
      },
    },
  },

  '.description': {
    fontSize: 14,
    lineHeight: 1.7,
    color: theme.gray500,
    marginBottom: 24,
  },
});

export const moduleDetailsContainer = (theme) => ({
  backgroundImage: `url(${packageBgLogo})`,
  backgroundPosition: 'top center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  padding: '24px 100px',

  '.moduleDetailsHeaderWrap': {
    marginBottom: 8,

    '.title': {
      color: theme.white,
      fontSize: 32,
      fontWeight: 500,
      lineHeight: 1.5,
    },
  },

  '.bonusTitle': {
    marginBottom: 32,
    marginLeft: -32,

    '.morePackageWrap': {
      display: 'flex',
      alignItems: 'center',
      marginTop: 64,
      marginBottom: 32,

      '.button': {
        color: theme.secondaryDark,
      },
    },
  },

  '.statusWrap': {
    backgroundColor: theme.whiteGrayWhite,
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 6,

    '.image': {
      width: 30,
      height: 30,
      marginBottom: 8,
    },

    '& > h5': {
      marginBottom: 8,
    },

    '.bonusCapAmount': {
      color: theme.secondaryDark,
    },
  },
});

export const bonusBoxContainer = (theme) => ({
  border: `1px solid ${theme.whiteGray}`,
  borderRadius: 3,
  padding: 24,
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.white,

  '.bonusItemContainer': {
    padding: 16,
    borderBottom: `1px solid ${theme.gray50}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:last-child': {
      border: 'none',
    },

    '&:nth-child(even)': {
      backgroundColor: theme.whiteGrayWhite,
    },

    '.bonusRowContainer': {
      display: 'flex',
      alignItems: 'center',
    },

    '.infoBoxItemTextContainerLevel': {
      fontSize: 13,
      fontWeight: 600,
      opacity: 0.7,
    },

    '.infoBoxItemTextContainerValue': {
      fontSize: 13,
      fontWeight: 600,
      opacity: 0.7,
      color: theme.black,
    },
  },
});

export const backButtonStyles = (theme) => ({
  color: theme.white,
  backgroundColor: theme.gray300,
  display: 'flex',
  top: 65,
  left: 'calc(100% - 100vw + 220px)',
  width: 100,
});

export const bonusContainer = {
  marginTop: 64,
};
