/* eslint-disable no-loop-func */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sortBy, isNil } from 'lodash-es';
import hash from 'object-hash';
import { Row, Col, showApiError, Image, Button, BackButton } from 'components';
import { Module, LoadingModule } from 'components/screens/Modules';
import { getPackageById, getAllPackages, getConfigParam } from 'services';
import { ReactComponent as AllowedIcn } from 'assets/images/icn-checklist-checked.svg';
import { ReactComponent as NotAllowedIcn } from 'assets/images/icn-not-allowed.svg';
import starterLogo from 'assets/images/starter.png';
import { packageInfoDataCol, packageInfoDataSecondCol } from './config';
import {
  packageDetailsInfoBoxContainer,
  moduleDetailsContainer,
  bonusBoxContainer,
  backButtonStyles,
  bonusContainer,
} from './styles';

const ModuleDetails = (props) => {
  const { location, match, history } = props;
  const [packageData, setPackageData] = useState(null);
  const [packages, setPackages] = useState(null);
  const [config, setConfig] = useState(null);
  const shopUrl = 'https://portal.oneecosystem.eu/shop';

  let hasMatchingBonus;

  useEffect(() => {
    fetchPackage();
  }, [hash({ state: location.state })]);

  useEffect(() => {
    packageData && fetchAllPackages();
  }, [hash({ packageData })]);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [resConfig, errorConfig] = await getConfigParam('ExchangeRate', 'number');

    if (errorConfig) return showApiError(errorConfig);
    setConfig(resConfig);
  };

  const fetchAllPackages = async () => {
    const [data, err] = await getAllPackages();
    if (err) return showApiError(err);

    const moreArray = [];
    let i = 0;
    let priceUp = packageData?.price;
    const sortedPackages = sortBy(data?.catalogueItems, 'price');
    do {
      const item = sortedPackages.find((el) => el.price > priceUp);
      if (item) {
        priceUp = item.price;
        item && moreArray.push(item);
      }
      i++;
    } while (i < 3);

    setPackages(moreArray);
  };

  const fetchPackage = async () => {
    if (location?.state) setPackageData(location?.state);
    else {
      const packageId = match?.params?.id;
      const [data, err] = await getPackageById(packageId);

      if (err) return showApiError(err);

      const { active, visible } = data;

      if (active && visible) setPackageData(data);
      else history.goBack();
    }
  };

  if (packageData) {
    const { hasMatch1Bonus, hasMatch2Bonus, hasMatch3Bonus, hasMatch4Bonus } = packageData;

    hasMatchingBonus = hasMatch1Bonus || hasMatch2Bonus || hasMatch3Bonus || hasMatch4Bonus;
  }

  return (
    <div css={moduleDetailsContainer}>
      <BackButton onBack={() => history.goBack()} css={backButtonStyles} />
      <div className="moduleDetailsHeaderWrap">
        <Row gap={32}>
          <Col xs={12} sm={3} lg={2}>
            <Image src={packageData?.picThumbnailUrl ?? packageData?.pictureUrl ?? starterLogo} alt="image" />
          </Col>
          <Col xs={12} sm={9} lg={10}>
            <h1 className="title">{packageData?.name}</h1>
            <h1 className="title">€ {packageData?.price}</h1>
          </Col>
        </Row>
      </div>

      <Row gap={32} css={packageDetailsInfoBoxContainer}>
        <Col xs={12} sm={9} md={9}>
          <div>
            <p className="description">{packageData?.description}</p>

            {packageInfoDataCol(packageData, config).map((el, ind) => (
              <div className="infoBoxItem" key={ind}>
                <div className="infoBoxItemContainer">
                  <div className="boxIcon">{el.image}</div>
                  <div>
                    <h4 className="infoBoxItemTextContainerTitle">{el.title}</h4>
                    <p className="infoBoxItemTextContainerSubtitle">{el.subtitle}</p>
                  </div>
                </div>

                <h3 className="infoBoxItemTextContainerAmount">{el.amount}</h3>
              </div>
            ))}
          </div>
        </Col>
        <Col xs={12} sm={3} md={3}>
          <div className="statusWrap">
            <div className="image">{packageData?.immediateMining ? <AllowedIcn /> : <NotAllowedIcn />}</div>
            <p>
              Automining
              <br /> is {!packageData?.immediateMining && 'not '}Allowed
            </p>
          </div>
        </Col>
      </Row>

      <div css={bonusContainer}>
        <Row gap={32} css={bonusBoxContainer}>
          <Col xs={12} sm={9} md={9} padding="0">
            <h4>Bonus Applicable for</h4>
          </Col>
          <Col xs={12} sm={9} md={9} padding="0">
            <div>
              {packageInfoDataSecondCol(packageData, hasMatchingBonus).map((el, ind) => (
                <div className="bonusItemContainer" key={ind}>
                  <div className="bonusRowContainer">
                    <div className="image">{el.image}</div>
                    {isNil(el.level) ? (
                      <h5 className="infoBoxItemTextContainerTitle">{el.title}</h5>
                    ) : (
                      <h4 className="infoBoxItemTextContainerLevel">{el.level}</h4>
                    )}
                  </div>
                  <div>
                    {!isNil(el.value) ? (
                      <p className="infoBoxItemTextContainerValue">{el.value}</p>
                    ) : (
                      <h3 className="infoBoxItemTextContainerAmount">{el.amount}</h3>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <div className="statusWrap">
              <h5>Bonus Cap</h5>
              <h3 className="bonusCapAmount">{packageData?.bonusCap}</h3>
            </div>
          </Col>
        </Row>
      </div>

      <div className="bonusTitle">
        <div className="morePackageWrap">
          <h2>More Educational Modules</h2>
          <Button linkTo={shopUrl} type="link" small className="button">
            View All
          </Button>
        </div>
        <Row gap={16}>
          {packages ? (
            packages.slice(0, 3).map((el, i) => (
              <Col xl={3} lg={4} sm={6} xs={12} key={`${el.catalogueItemId}-${i}`}>
                <Module item={el} />
              </Col>
            ))
          ) : (
            <LoadingModule items={4} />
          )}
        </Row>
      </div>
    </div>
  );
};

ModuleDetails.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default ModuleDetails;
