import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { paperContainer, paperContentContainer, paperHeaderContainer } from './styles';

const Paper = (props) => {
  const { children, header, className } = props;

  return (
    <div css={paperContainer(props)} {...(className && { className })}>
      {header &&
        (isFunction(header) ? (
          header({ css: paperHeaderContainer(props) })
        ) : (
          <div css={paperHeaderContainer(props)}>
            <h5>{header}</h5>
          </div>
        ))}
      <div className="paper-content" css={paperContentContainer(props)}>
        {children}
      </div>
    </div>
  );
};

Paper.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any,
  className: PropTypes.string,
};

export default Paper;
