import { useRef, useState, useEffect } from 'react';
import { isArray } from 'lodash-es';
import { useIntersectionObserver } from '../../hooks';
import { DEFAULT_DISPLAY_KEY, DEFAULT_UNIQUE_KEY } from './utils';

export default ({
  isOpen,
  options: initOptions,
  displayKey = DEFAULT_DISPLAY_KEY,
  uniqueKey = DEFAULT_UNIQUE_KEY,
  optionsListRef,
  searchKeys,
}) => {
  const searchInputNodeRef = useRef(null);
  const searchResultsRef = useRef([]);
  const optionsScrollContainerRef = useRef(null);
  const optionsLoadingNodeRef = useRef(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [scrollItems, setScrollItems] = useState([]);
  const hasSearch = searchInputNodeRef.current?.value?.length;
  const scrollSize = 20;
  const needInfiniteScroll = (hasSearch ? searchResultsRef.current?.length : initOptions?.length) > scrollSize;
  const allOptions = hasSearch ? filteredResults : needInfiniteScroll ? scrollItems : initOptions;
  const renderedOptionsLength = (hasSearch ? searchResultsRef.current : initOptions)?.length;
  const hasNextPage = needInfiniteScroll && !!allOptions?.length && renderedOptionsLength > allOptions?.length;

  useEffect(() => {
    isOpen && searchInputNodeRef.current?.focus();
  }, [searchInputNodeRef.current]);

  useEffect(() => {
    needInfiniteScroll && setScrollItems(initOptions.slice(0, scrollSize));
  }, [initOptions?.length]);

  useIntersectionObserver(
    optionsLoadingNodeRef,
    optionsScrollContainerRef,
    (entry) => entry?.isIntersecting && hasNextPage && fetchMoreResults(),
  );

  const compareValues = (value, input) => JSON.stringify(value)?.search(new RegExp(input.trim(), 'ig')) !== -1;

  const filterValues = (input) => (el) => {
    if (el.groupLabel) return el.groupLabel;
    const searchByKeys = isArray(searchKeys) ? searchKeys : [uniqueKey, displayKey];
    return searchByKeys.some((exp) => compareValues(el[exp], input));
  };

  const filterResults = (input) => {
    // eslint-disable-next-line no-param-reassign
    if (optionsListRef) optionsListRef.scrollTop = 0;
    const results = initOptions
      .filter(filterValues(input))
      .filter((el, i, arr) => !el?.groupLabel || (arr[i + 1] && !arr[i + 1]?.groupLabel));

    searchResultsRef.current = results;
    return results.length ? setFilteredResults(results.slice(0, scrollSize)) : setFilteredResults(null);
  };

  const fetchMoreResults = () =>
    !hasSearch
      ? setScrollItems((prev) => prev.concat(initOptions.slice(prev?.length, prev?.length + scrollSize)))
      : setFilteredResults((prev) =>
          prev.concat(searchResultsRef.current?.slice(prev?.length, prev?.length + scrollSize)),
        );

  return {
    allOptions,
    filterResults,
    searchInputNodeRef,
    optionsScrollContainerRef,
    optionsLoadingNodeRef,
    fetchMoreResults,
    hasNextPage,
  };
};
