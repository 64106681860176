import { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckButton, Paper, Tooltip, showApiError, showSuccess } from 'components/UI';
import { treePlacement } from 'enums';
import { changeAccountPlacement } from 'services';
import { ReactComponent as QuestionIcn } from 'assets/images/icn-question-gray.svg';
import { memberPlacements } from './config';
import { paperContainer, spaceContainer } from './styles';

const ProfileMembersPlacement = (props) => {
  const { userData } = props;
  const [placement, setPlacement] = useState(treePlacement[userData?.currentAccount?.treePlacement?.toLowerCase()]);

  const handlePlacementChange = async (newValue) => {
    const [res, err] = await changeAccountPlacement({
      id: userData?.currentAccount?.id,
      treePlacement: newValue,
    });

    if (err) return showApiError(err);
    setPlacement(res?.treePlacement.toLowerCase());
    showSuccess('You have successfully change tree placement.');
  };

  return (
    <div css={paperContainer}>
      <Paper>
        <div css={spaceContainer}>
          <h4>Placement of New Members</h4>
          <Tooltip
            content="Select placement for your new accounts"
            placement="right"
            backgroundColor="gray100"
            color="black"
          >
            <QuestionIcn className="icon" />
          </Tooltip>
        </div>

        {memberPlacements.map((el) => (
          <div key={el.value} className="checkButtons">
            <CheckButton radio value={el.value === placement} onChange={() => handlePlacementChange(el.value)} />
            <p className="label">{el.label}</p>
          </div>
        ))}
      </Paper>
    </div>
  );
};

ProfileMembersPlacement.propTypes = {
  userData: PropTypes.object,
};

export default ProfileMembersPlacement;
