import PropTypes from 'prop-types';
import { Button } from 'components/UI';
import { ReactComponent as ApprovedIcn } from 'assets/images/icn-alert-success.svg';
import { modalWarningWrapper } from './styles';

const ProfileUploadDocsWarning = ({ onAccept, onDeny }) => (
  <div css={modalWarningWrapper}>
    <div className="warningContent">
      <div className="warningTitle">
        <ApprovedIcn className="image" />
        <h3>
          Your profile is <b className="boldTextSuccess">ACTIVE</b>
        </h3>
      </div>
      <p className="warningMessage">
        If you upload new document your profile status will become <b className="boldTextError">NOT VERIFIED</b>. Are
        you sure you want to continue?
      </p>
    </div>
    <div className="warningButtonsContainer">
      <Button clicked={onAccept}>Agree</Button>
      <Button type="secondary" clicked={onDeny}>
        Cancel
      </Button>
    </div>
  </div>
);

ProfileUploadDocsWarning.propTypes = {
  onAccept: PropTypes.func,
  onDeny: PropTypes.func,
};

export default ProfileUploadDocsWarning;
